import { useRef, useState, useEffect } from "react";
import { format } from "date-fns";
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import Loader from "./loader";

import { rootPath, appSessKey } from "util";

const PAGE_SIZE = 10;

function TableReport({data_key, table_rows}){
    const isMounted = useRef(false);
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage]= useState({"page": 0, "key":""});
    const [viewData, setViewData] = useState<any>({ "incomplete":true, "complete":true });
    const [displayData, setDisplayData] = useState<any>({ "incomplete":[], "complete":[] });
    const [tableCount, setTableCount] = useState(0);
    const [tableData, setTableData] = useState<any>([]);

    const updatePage = (dir) => {
        try {
            if(dir < 0) {
                setActivePage((d) =>{ return { ...d, "page": (d.page > 0 ? d.page-1 : 0)}});
            }
            else {
                if((PAGE_SIZE * activePage.page + 1) <= tableCount){
                    setActivePage((d)=> { return {...d, "page": d.page + 1}; });
                }
            }
        }
        catch(ex){
            console.log(`Error Updating Page: ${ex}`);
        }
    }

    const getTableData = () => {
        try {
            const sessKey = appSessKey;
            const sessionToken = localStorage.getItem(sessKey);

            setLoading(true);
            fetch(`${rootPath}/v1/user_auth/form-data/${data_key}`, {
                method: "GET", headers: { 
                    "Accept": "application/json", 
                    "Authorization": `${sessionToken}`,
                    "Content-Type":"application/json"
                }
            })
            .then((response) => response.json())
            .then((res)=> {
                if(res.results) {
                    setTableData(res.results);
                    setTableCount(res.totalCount ?? 1);
                }
                else {
                    console.log(`Error Getting Table Data [EG01]: ${res.error}`);
                }
            }).catch((err) =>{
                console.log(`Error Getting Table Data [EG02]: ${err}`);
            })
            .finally(()=>{ setLoading(false); });
        }
        catch(ex){
            console.log(`[Error] Getting Table Data: ${ex}`);
        }
    }

    const downloadTableData = () => {
        try {
            const sessKey = appSessKey;
            const sessionToken = localStorage.getItem(sessKey);

            setLoading(true);
            fetch(`${rootPath}/v1/user_auth/form-data/${data_key}/download`, {
                method: "GET", headers: { 
                    "Accept": "application/json", 
                    "Authorization": `${sessionToken}`,
                    "Content-Type":"application/json"
                }
            })
            .then((response) => response.json())
            .then((res)=> {
                if(res?.error) {
                    console.log(`Error Downloading Table Data [EG01]: ${res.error}`);
                    toast.error("Sorry, There was an issue with the download [Please Contact Site Admin]", { position: "top-right",
                        autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                        draggable: true, progress: undefined, theme: "light", });
                }
                else {
                    const blob = new Blob([res], { type: 'text.csv', });
                    saveAs(blob, `hsa_${data_key}_${(new Date()).getTime()}.csv`);
                }
            }).catch((err) =>{
                console.log(`Error Downloading Table Data [EG02]: ${err}`);
            })
            .finally(()=>{ setLoading(false); });
        }
        catch(ex){
            console.log(`[Error] Downloading Table Data: ${ex}`);
        }
    }

    const updateTableData = (row_id, status) => {
        try {
            const sessKey = appSessKey;
            const sessionToken = localStorage.getItem(sessKey);

            let newStatus = (status === "INCOMPLETE" ? "COMPLETE" : "INCOMPLETE");
            let postData = JSON.stringify({ row_id: row_id, update_fields: { status: newStatus} });

            setLoading(true);
            fetch(`${rootPath}/v1/user_auth/form-data/${data_key}`, {
                method: "POST", body: postData,
                headers: { 
                    "Accept": "application/json", 
                    "Authorization":`${sessionToken}`,
                    "Content-Type":"application/json"
                }
            })
            .then((response) => response.json())
            .then((res)=> {
                if(res.results) {
                    toast.success(`Request Set To ${(newStatus === "INCOMPLETE" ? "Unread" : "Read")}`, { position: "top-right",
                        autoClose: 5000, hideProgressBar: false, closeOnClick: true,
                        draggable: true, progress: undefined, theme: "light", });
                    getTableData();
                }
                else {
                    toast.error("Sorry, There was an issue marking row as read [Please Contact Site Admin]", { position: "top-right",
                        autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                        draggable: true, progress: undefined, theme: "light", });
                    console.log(`Error Updating Table Data [EG01]: ${res.error}`);
                }
            }).catch((err) =>{
                console.log(`Error Updating Table Data [EG02]: ${err}`);
            })
            .finally(()=>{ setLoading(false); });
        }
        catch(ex){
            console.log(`[Error] Updating Table Data: ${ex}`);
            toast.error("Sorry, There was an issue marking row as read [Please Contact Site Admin]", { position: "top-right",
                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                draggable: true, progress: undefined, theme: "light", });
        }
    }

    const cleanData = (data: any, name: string) => {
        try {
            switch(name){
                case "associated_activites":
                    return <>
                         <div className="invoice-list">
                            {data && data.map((item,i)=>
                                <div className="invoice-item" key={i}>
                                    <span>{item.label}</span>
                                </div>
                            )}
                        </div>
                    </>;
                case "invoices":
                    return <>
                        <div className="invoice-list">
                            {data && data.map((item,i)=>
                                <div className="invoice-item" key={i}>
                                    <span>{item.qty}</span>
                                    <span className="lrg">{item.item}</span>
                                    <span>${item.price}</span>
                                </div>
                            )}
                        </div>
                    </>;
                case "receipts":
                    return <>
                        <div className="invoice-list">
                            {data && data.map((item,i)=>
                                <div className="invoice-item" key={i}>
                                    <span>{item}</span>
                                </div>
                            )}
                        </div>
                    </>;
                default:
                    return <>{data}</>;
            }
        }
        catch(ex){
            console.log(`[Error] Cleaning Data: ${ex}`);
            return <></>;
        }
    }

    const formatDate = (date) =>{
        let ret = "N/A";
        try {
            if(date && !isNaN((new Date(date)).getTime())){
                ret = format(new Date(date),'dd MMM yyyy h:mm aaaa');
            }
        }
        catch(ex){
            console.log(`[Error] formatting data: ${ex}`);
        }

        return ret;
    }

    useEffect(()=>{
        try {
            if(tableData){
                let tmpIncomplete = tableData.filter((item)=> { return item.status === "INCOMPLETE"; }),
                tmpComplete = tableData.filter((item)=> { return item.status === "COMPLETE"; });
                setDisplayData({ "incomplete":tmpIncomplete, "complete":tmpComplete });
            }
        }
        catch(ex){
            console.log(`[Error] Sorting Data: ${ex}`);
        }
    },[tableData]);

    useEffect(()=>{ 
        if(isMounted.current){
            getTableData(); 
        }
        else {
            isMounted.current = true;
        }
    },[activePage]);

    useEffect(()=> {
        setActivePage({"page": 0, "key": data_key}); 
        setViewData({ "incomplete":true, "complete":true });
    },[data_key])

    return (
        <div className='table-container'>
            <div className="full-table-header">
                <div className="table-count">
                    <div className="table-nav">
                        <span className="icon material-symbols-outlined" onClick={()=> updatePage(-1)}>chevron_left</span>
                        <span className="icon material-symbols-outlined" onClick={()=> updatePage(1)}>chevron_right</span>
                    </div>
                    <span>{tableData.length} of {tableCount}</span>
                </div>

                <div className="table-actions">
                    <div className="table-link" onClick={downloadTableData}>
                        <span className="icon material-symbols-outlined">download</span>
                        <span>Download CSV</span>
                    </div>
                </div>
            </div>
            {/* Unread */}
            <div className="sub-table-container unread">
                <div className="sub-table-header">
                    <div className="title" onClick={()=> { setViewData((d)=> { return { ...d, "incomplete": !d.incomplete}}) }}>
                        <span className="icon material-symbols-outlined">{viewData.incomplete ? "expand_less" : "expand_more"}</span>
                        <span>Unread</span>
                    </div>
                </div>
                {(viewData.incomplete && displayData.incomplete.length > 0) &&
                    <div className="sub-table-data-container">
                        <table className="report-table">
                            <thead>
                                <tr className="table-header">
                                    <th/>
                                    <th>Submission Date</th>
                                    {table_rows.map((row,i) => 
                                        <th key={i}>{row.displayName}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {displayData.incomplete.map((data,h)=>
                                    <tr key={h}>
                                        <td>
                                            <div className={`row-status ${data.status === "COMPLETE" ? 'sel' : ""}`} onClick={()=> updateTableData(data._id, data.status)}/>
                                        </td>
                                        <td>{formatDate(data?.submission_date)}</td>
                                        {table_rows.map((row,i) => 
                                            <td key={i}>{cleanData(data[row.name], row.name)}</td>
                                        )}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                }
            </div>

            {/* Read */}
            <div className="sub-table-container read">
                <div className="sub-table-header">
                    <div className="title" onClick={()=> { setViewData((d)=> { return { ...d, "complete": !d.complete}}) }}>
                        <span className="icon material-symbols-outlined">{viewData.complete ? "expand_less" : "expand_more"}</span>
                        <span>Read</span>
                    </div>
                </div>
                {(viewData.complete && displayData.complete.length > 0) &&
                    <div className="sub-table-data-container">
                        <table className="report-table">
                            <thead>
                                <tr className="table-header">
                                    <th/>
                                    <th>Submission Date</th>
                                    {table_rows.map((row,i) => 
                                        <th key={i}>{row.displayName}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {displayData.complete.map((data,h)=>
                                    <tr key={h}>
                                        <td>
                                            <div className={`row-status ${data.status === "COMPLETE" ? 'sel' : ""}`} onClick={()=> updateTableData(data._id, data.status)}/>
                                        </td>
                                        <td>{formatDate(data?.submission_date)}</td>
                                        {table_rows.map((row,i) => 
                                            <td key={i}>{cleanData(data[row.name], row.name)}</td>
                                        )}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
            {loading && <Loader />}
        </div>
    );
}

export default TableReport;