import { useState } from 'react';
import { toast } from 'react-toastify';
import { rootPath } from 'util';

import Loader from './loader';
import hsa_logo from "../assets/hsa-logo2.png";

function EventFeedback({ eventInfo, setShowFeedback }){
    const scaleList = [1,2,3,4,5,6,7,8,9,10], feedbackMax = 500;
    const [feedbackData, setFeedbackData] = useState({ rating: 0, feedback:"" });
    const [loading, setLoading] = useState(false);

    const updateFeedbackData = (e) => {
        try {
            let name = e.target.name,
                value = e.target.value;

            if(name != "feedback" || value.length <= feedbackMax){
                setFeedbackData((d) => { return {...d, [name]: value }; });
            }
        }
        catch(ex){
            console.log(`Error Updating Feedback: ${ex}`);
        }
    }

    const submitFeedback = () => {
        try {
            if(!(feedbackData?.rating > 0 && feedbackData?.feedback?.length > 0)){
                toast.warn("Please select a event rating & add some feedback notes before submission", { position: "top-right",
                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                    draggable: true, progress: undefined, theme: "light", });
            }
            else {
                let postData = JSON.stringify({ 
                    form_type: "event_feedback",
                    form_data: { ...feedbackData, event_name:eventInfo?.name, event_title:eventInfo?.title, event_date: eventInfo?.datetime 
                } });
                setLoading(true);
                fetch(`${rootPath}/v1/api/submit-form`, {
                    method: "POST", body: postData,
                    headers: { "Accept": "application/json", "Content-Type":"application/json"}
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(res.status) {
                        toast.success("Feedback Submitted Successfully", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                            draggable: true, progress: undefined, theme: "light", });
                        
                        setFeedbackData({ rating: 0, feedback:"" });
                        setShowFeedback(false);
                     }
                    else {
                        console.log(`Error With Submitting Feedback [DF00]: ${res.error}`);
                        toast.error("Sorry, There was an issue submitting your feedback [Please Contact Site Admin]", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                            draggable: true, progress: undefined, theme: "light", });
                    }
                }).catch((err) =>{
                    console.log(`Error With Submitting Feedback [DF01]: ${err}`);
                }).finally(()=> { setLoading(false); });
            }
        }
        catch(ex){
            console.log(`Error Submitting Feedback ${ex}`);
        }
    }

    return (
        <div className='gallery-modal-body feedback-display'>
             <div className='selected-img'>
                 <img src={eventInfo?.default_image ? eventInfo?.default_image : hsa_logo} alt={`${eventInfo?.title} cover photo`} />                 
             </div>
             <div className='event-feedback-container'> 
                <h1>Event Feedback</h1>
                <div className='event-title'>{eventInfo?.title}</div>
                
                <div className='feedback-input'>
                    <div className='input-title'>On a scale of {scaleList[0]} to {scaleList[(scaleList.length-1)]}, how would you rate this event?</div>
                    <div className='rating-container'>
                        <div className='rating-scale-container'>
                            {scaleList.map((scale,i) =>
                                <div className={`scale-item ${feedbackData.rating === scale ? "selected": ""}`} key={i} onClick={()=> updateFeedbackData({"target":{ "name": "rating", "value": scale }}) }>{scale}</div>
                            )}
                        </div>
                        <div className='rating-legend'>
                            <span>Poor</span>
                            <span>Outstanding</span>
                        </div>
                    </div>
                </div>

                <div className='feedback-input'>
                    <textarea name="feedback" value={feedbackData.feedback} onChange={updateFeedbackData} rows={7} placeholder='Tell us more about your experience' />
                    <span className='text-max'>
                        {feedbackMax - feedbackData.feedback.length}/{feedbackMax} characters left
                    </span>
                </div>

                <div className="btn-container">
                    <div className="btn-text" onClick={()=> setShowFeedback(false)}>
                        <span className="material-symbols-outlined">arrow_left_alt</span>
                        <span className='btn-text'>Back To Event</span>
                    </div>

                    <div className="btn" onClick={submitFeedback}>
                        <span className='btn-text'>Submit Feedback</span>
                        <span className="material-symbols-outlined">send</span>
                    </div>
                </div>  
             </div>

             {loading && <Loader />}
        </div>
    );
}

export default EventFeedback;