import { useContext, useState, useEffect } from "react";
import userContext from "context/userContext";
import { UserContextType } from 'datatypes/types';

import TableReport from "components/tableReport";


function FormManagement(){
    const { user } = useContext(userContext.UserContext) as UserContextType;
    const [selectedPage, setSelectedPage] = useState("");

    const pageDict = {
        "volunteer_request":{
            "title":"Volunteer Requests", "table_rows":[
                {"displayName":"Name", "name":"name", "type":"input"},
                {"displayName":"Email", "name":"email", "type":"email"},
                {"displayName":"Child's Name", "name":"child_name", "type":"input"},
                {"displayName":"Child's Homeroom", "name":"child_homeroom", "type":"input"},        
                {"displayName":"Volunteer Activity", "name":"volunteer_activity", "type":"select"},
                {"displayName":"Comments", "name":"comments", "type":"textarea"}
            ]
        },
        "volunteer_hours_request":{
            "title":"Logged Volunteer Hours", "table_rows":[
                {"displayName":"Name", "name":"name", "type":"input"},
                {"displayName":"Email", "name":"email", "type":"email"},
                {"displayName":"Child's Name", "name":"child_name", "type":"input"},
                {"displayName":"Child's Homeroom", "name":"child_homeroom", "type":"input"},        
                {"displayName":"Volunteer Activity", "name":"volunteer_activity", "type":"select"},
                {"displayName":"Activity Date", "name":"activity_date", "type":"date"},
                {"displayName":"Number of Hours", "name":"hours", "type":"number"}
            ]
        },
        "reimbursement_request":{
            "title":"Reimbursement Requests", "table_rows":[
                {"displayName":"Name", "name":"name", "type":"input"},
                {"displayName":"Email", "name":"email",  "type":"email"},
                {"displayName":"Volunteer Activity", "name":"volunteer_activity",  "type":"select"},
                {"displayName":"Notes", "name":"notes", "type":"textarea"},
                {"displayName":"Payto Name", "name":"payto_name", "type":"input"},
                {"displayName":"Payto Address", "name":"payto_address", "type":"input"},
                {"displayName":"Phone Number", "name":"phone", "type":"input"},
                {"displayName":"Pay Arrangement", "name":"pay_arrangement", "type":"select"},
                {"displayName":"Associated Committees/Activites", "name":"associated_activites", "type":"multi-select"},
                {"displayName":"Description", "name":"description", "type":"textarea"},
                {"displayName":"List Invoices", "name":"invoices", "type":"invoice-table"},
                {"displayName":"Receipts", "name":"receipts", "type":"file-upload"},
            ]
        },
        "event_feedback":{
            "title":"Event Feedback", "table_rows":[
                {"displayName":"Event Name","name":"event_name", "type":"input"}, 
                {"displayName":"Event Title","name":"event_title", "type":"input"},
                {"displayName":"Event Date","name":"event_date", "type":"date"}, 
                {"displayName":"Rating (1-10)","name":"rating", "type":"input"},
                {"displayName":"Feedback","name":"feedback", "type":"input"},
            ]
        }
    };

    useEffect(()=>{
        document.title = "Admin Page";
        let page_key = Object.keys(pageDict); 
        setSelectedPage(page_key[0]);
    },[]);

    return (
            <div className="admin-page admin">
                    <h1>Welcome <span>{user?.name}</span>,</h1>
                    <h2>to the St. Elizabeth H.S.A. Parent Portal</h2>

                    <div className="dashboard-section">
                        <div className="dashboard-container">
                            <div className="dashboard-header">
                                <div className="nav-icon">
                                    <span className="material-symbols-outlined">dashboard</span>
                                </div>
                                <div className="nav-container">
                                    <div className={`dashboard-nav ${selectedPage === "volunteer_request" ? "sel": ""}`} onClick={()=> setSelectedPage("volunteer_request")}>Volunteer Requests</div>
                                    <div className={`dashboard-nav ${selectedPage === "reimbursement_request" ? "sel": ""}`} onClick={()=> setSelectedPage("reimbursement_request")}>Reimbursements</div>                                                
                                    <div className={`dashboard-nav ${selectedPage === "volunteer_hours_request" ? "sel": ""}`} onClick={()=> setSelectedPage("volunteer_hours_request")}>Logged Volunteer Hour</div>
                                    <div className={`dashboard-nav ${selectedPage === "event_feedback" ? "sel": ""}`} onClick={()=> setSelectedPage("event_feedback")}>Event Feedback</div>   
                                </div>
                            </div>

                            <div className="content-container">
                                {selectedPage.length > 0 &&
                                    <TableReport data_key={selectedPage} table_rows={pageDict[selectedPage].table_rows} />
                                }
                            </div>
                        </div>
                    </div>                    
                </div>
    );
}

export default FormManagement;