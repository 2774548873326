import { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import 'react-calendar/dist/Calendar.css';
import { format, subMonths, addMonths, parseISO, isSameDay } from 'date-fns';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import RojhayCalendar from 'components/customCalendar';
import EventModal from 'components/eventModal';
import Loader from 'components/loader';

import { EventV2, StoreContextType, EventModalDisplay } from 'datatypes/types';
import { rootPath, validateFormField } from 'util';
import cover_photo from '../assets/events.jpg';

import storeContext from 'context/storeContext';

function Events(){
    const params = useParams(), EVENT_PG_SZ = 5;
    const [showEvent, setShowEvent] = useState<EventModalDisplay>({ display: false, selectedId: null});

    const [events, setEvents] = useState<Array<EventV2>>([]);
    const [displayEvents, setDisplayEvents] = useState<Array<EventV2>>([]);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [eventPage, setEventPage] = useState({ size: 0, current: 0, max:0 });
    const [loadingEvents, setLoadingEvents] = useState(false);
    const [animationParent] = useAutoAnimate({ duration: 400 })
    
    const [colorMap, setColorMap] = useState({});

    const { grades, checkGrades } = useContext(storeContext.StoreContext) as StoreContextType;

    const buildColorMap = (colorList) => {
        try {
            let tmpMap = {};
            for(let i=0; i < colorList.length; i++){
                tmpMap[colorList[i].slug] = `#${colorList[i].colorCode}`;
            }

            setColorMap(tmpMap);
        }
        catch(ex){
            console.log(`Error: Building Color Map:  ${ex}`);
        }
    }

    const getColorMap = (gId) => {
        //let gradeId = Array.isArray(id) && id.length > 0 ? id[0] : id;
        let grade_color = "#fff";
        if(Array.isArray(gId) && gId.length > 1){
            grade_color = "#00295f";
        }
        else if(Array.isArray(gId) && gId.length === 1){
            grade_color = colorMap[gId[0]];
        }
        return grade_color;
    }

    const pageEventList = (pageNum, pageSize) => {
        try {
            if(events.length > 0 && pageNum > 0) {
                let start = ((pageNum-1) * pageSize),
                    end = pageNum * pageSize, 
                    max = Math.ceil(events.length / pageSize);

                let tmpList = events.slice(start,end);

                if(tmpList.length > 0) {
                    setDisplayEvents(tmpList);
                    setEventPage({ size: pageSize, current: pageNum, max: max });
                }
            }
        }
        catch(ex){
            console.log(`Error Setting Event Page List: ${ex}`);
        }
    }

    const formatDate = (date, type) =>{
        let ret = "";
        try {
            if(validateFormField(date, "date", true)){
                let d = new Date(date);
                switch(type) {
                    case "date_m_d":
                        ret = format(d,'MMM dd');
                        break;
                    case "date":
                        ret = format(d,'MMM dd, yyyy');
                        break;
                    case "time":
                        ret = format(d,'h:mm aaaa');
                        break;
                }
            }
        }
        catch(ex){
            console.log(`[Error] formatting data: ${ex}`);
        }

        return ret;
    }

    const compareDates = (d1: any, d2:any) => {
        try {
            let date1 = parseISO(d1),
                date2 = parseISO(d2);

            return isSameDay(date1, date2);
        }
        catch(ex){
            console.log(`Comparing Dates: ${ex}`);
            return false;
        }
    }

    const getMonthEventsList = () =>{
        try {
            let startDate = format(currentMonth, 'yyyy-MM-01'),
                endDate = format(addMonths(currentMonth,1), 'yyyy-MM-01');

            setLoadingEvents(true);
            fetch(`${rootPath}/v2/api/event?per_page=50&start_dt=${startDate}&end_dt=${endDate}`, {
                method: "GET", headers: { "Accept": "application/json", "Content-Type":"application/json"}
            })
            .then((response) => response.json())
            .then((res)=> {
                if(res.results) {
                    setEvents(res.results);
                }
                else {
                    console.log(`Error Getting Event List [E02]: ${res.error}`);
                }
            }).catch((err) =>{
                console.log(`Error Getting Event List [E03]: ${err}`);
            }).finally(()=>{ setLoadingEvents(false); });
        }
        catch(ex){
            console.log(`Error Getting Event Sub List [M04] ${ex}`);
        }
    }

    const setDisplayMonth = (val) => {
        setCurrentMonth((d) => { return (val < 0 ? subMonths(d,1) : addMonths(d,1)); })
    }

    const formateNavDate = (val) => {
        let ret = '';
        try {
            let d = (val < 0 ? subMonths(currentMonth,1) : addMonths(currentMonth,1));
            ret = format(d, 'MMM. yyyy');
        }
        catch(ex){
            console.log(`Error Formatting Date: ${ex}`);
        }
        return ret;
    }

    const isPastEvent = (date) => {
        let ret = false;
        try {
            if(validateFormField(date, "date", true)){
                let d = new Date(date), curDate = new Date();
                ret = (curDate.getTime() > d.getTime());
            }
        }
        catch(ex){
            console.log(`[Error] Checking Event Status: ${ex}`);
        }
        return ret;
    }

    useEffect(()=> {
        if(events.length > 0){ pageEventList(1, EVENT_PG_SZ); }
        else { 
            setEventPage({ size: 0, current: 0, max:0 });
            setDisplayEvents([]);    
        }
    },[events])

    useEffect(()=> { 
        if(grades.length > 0){ buildColorMap(grades); }
    },[grades]);

    useEffect(()=>{ 
        if(params?.event_item){
            setShowEvent({ display: true, selectedId:params?.event_item})
        }
    },[params]);

    useEffect(()=> { getMonthEventsList(); }, [currentMonth]);
    
    useEffect(()=> { 
        checkGrades(); 
        document.title = "HSA Events Calendar";
    },[]);

    return (
        <div className={`internal-page events ${showEvent.display ? 'no-animate':''}`}>
            <div className='internal-landing'>
                <div className="cover-image">
                    <img src={cover_photo} alt="event photo" />
                </div>
                <h1 className='small-title'>HSA Events Calendar</h1>
            </div>
            
            <div className='events-container'>
                <div className='events-link-title'>
                    <span>Our events calendar is here to show our school communities active events school wide & by grade level.</span>
                    <span><b>Please use the navigation buttons on the calendar to see events for each month.</b></span>
                    <span className='spacer'>Only HSA events are listed on this calendar. To access the complete St. Elizabeth School calendar, please visit <a href="https://stelizabethschoolmd.org/calendar" target="_blank">St. Elizabeth's School Calendar</a></span> 
                </div>
                <div className='events-calendar-container'>
                    <div className='calendar-container'>
                        <RojhayCalendar events={events} colorMap={colorMap} currentMonth={currentMonth} setCurrentMonth={setCurrentMonth} setShowEvent={setShowEvent} isV2={true}/>

                        <div className='color-legend'>
                            <div className='legend-item'>
                                <div className='item-color' style={{ backgroundColor: '#fff' }}/>
                                <span>School wide</span>
                            </div>
                            <div className='legend-item'>
                                <div className='item-color' style={{ backgroundColor: '#00295f' }}/>
                                <span>Multiple Grades</span>
                            </div>
                            {grades.map((grade,i)=>
                                <div className='legend-item' key={i}>
                                    <div className='item-color' style={{ backgroundColor: (grade.colorCode ? `#${grade.colorCode}`: '') }}/>
                                    <span>{grade.title}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='events-list-container'>
                        <div className={`event-item-list ${events.length === 0 ? "empty" : ""}`} ref={animationParent}>
                            {loadingEvents && <Loader />}
                            {!loadingEvents && 
                                <>
                                    {events.length === 0 &&
                                        <div className='empty-events'>
                                            <span>Sorry we currently don't have any events for this month.</span>
                                            <span>Please check back later or check out another month.</span>
                                        </div>
                                    }
                                    {displayEvents.map((event,i) =>
                                        <div className={`event-item ${isPastEvent(event.startDt) ? 'past' : ''}`} key={event.id}>
                                            <div className='item-img' style={{ borderColor: getColorMap(event.grade_list) }}>
                                                {(event?.images && event?.images?.length > 0) && <img src={event.images[0]} alt={`${event.title} default image`}/>}
                                            </div>
                                            <div className='item-info'>
                                                <p className='info-title'>{event.title}</p>
                                                <div className='info-date-container'>
                                                    {compareDates(event?.startDt, event?.endDt) ?  
                                                        <>
                                                            <span className="material-symbols-outlined">event</span>
                                                            <span className='date-content'>{formatDate(event.startDt, 'date')}</span>
                                                            <span className="material-symbols-outlined">schedule</span>
                                                            <span className='date-content'>{formatDate(event.startDt, 'time')} - {formatDate(event?.endDt,"time")}</span>
                                                        </>:
                                                        <>
                                                            <span className="material-symbols-outlined">event</span>
                                                            <span className='date-content'>{formatDate(event.startDt, 'date_m_d')} - {formatDate(event?.endDt,"date")}</span>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <span className="select-event material-symbols-outlined" onClick={()=> setShowEvent({ display: true, selectedId:event.id})}>expand_circle_right</span>
                                        </div>
                                    )}                        
                                </>}
                        </div>
                        <div className='event-list-ctrl'>
                            <div className='month-nav-container'>
                                <div className='month-nav-btn prev' onClick={()=> setDisplayMonth(-1)}>
                                    <span className="material-symbols-outlined">arrow_back</span>
                                    <span>{formateNavDate(-1)}</span>
                                </div>

                                <div className='month-nav-btn next' onClick={()=> setDisplayMonth(1)}>
                                    <span>{formateNavDate(1)}</span>
                                    <span className="material-symbols-outlined">arrow_forward</span>
                                </div>
                            </div>
                            <div className='page-info'>
                                <>
                                    <div className='page-item min' style={{ opacity: (eventPage.current > 1 ? 1 : 0)}}>
                                        <span className='page-num'>1</span>
                                    </div>
                                    <div className='page-divider' style={{ opacity: (eventPage.current > 1 ? 1 : 0)}}>
                                        <span /><span /><span />
                                    </div>
                                </>
                                

                                <div className='page-item current'>
                                    <span className={`material-symbols-outlined ${eventPage.current <= 1 ? "disabled" : ""}`} onClick={()=> pageEventList((eventPage.current - 1), EVENT_PG_SZ)}>chevron_left</span>
                                    <span className='page-num'>{eventPage.current}</span>
                                    <span className={`material-symbols-outlined ${eventPage.current >= eventPage.max ? "disabled" : ""}`} onClick={()=> pageEventList((eventPage.current + 1), EVENT_PG_SZ)}>chevron_right</span>
                                </div>

                            
                                <>
                                    <div className='page-divider' style={{ opacity: (eventPage.current < eventPage.max ? 1 : 0)}}>
                                        <span /><span /><span />
                                    </div>
                                    <div className='page-item max' style={{ opacity: (eventPage.current < eventPage.max ? 1 : 0)}}>
                                        <span className='page-num'>{eventPage.max}</span>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <EventModal show={showEvent.display} setShow={setShowEvent} eventId={showEvent.selectedId}/>
        </div>
    );
}

export default Events;