import { useState, useEffect } from 'react';
import { 
    format, startOfWeek, endOfWeek, addDays, addMonths,
    subMonths, startOfMonth, endOfMonth, isSameMonth, isSameDay,
    differenceInCalendarDays
} from "date-fns";

function RojhayCalendar({ events, colorMap={}, currentMonth=(new Date()), setCurrentMonth, setShowEvent, isV2=false }){
    const [ selectedDate, setSelectedDate ] = useState(new Date());
    const [ eventDayMap, setEventDayMap ] = useState({});

    const prevMonth = () => {
        setCurrentMonth((d) => { return subMonths(d,1); })
    }
    const nextMonth = () => { 
        setCurrentMonth((d) => { return addMonths(d,1); })
    }
    const onDateClick = (day) => { }

    const renderHeader = () => {
        try {
            const dateFormat = "MMMM yyyy";

            return(
                <div className="header row flex-middle">
                    <div className="col col-start" onClick={prevMonth}>
                        <div className="icon material-symbols-outlined">chevron_left</div>
                    </div>
                    <div className="col col-center">
                        <span>{format(currentMonth, dateFormat)}</span>
                    </div>
                    <div className="col col-end" onClick={nextMonth}>
                        <div className="icon material-symbols-outlined">chevron_right</div>
                    </div>
                </div>
            );
        }
        catch(ex){
            console.log(`Error Rendering Calendar Header: ${ex}`);
            return (<div />);
        }
    }

    const renderDays = () => {
        try {
            const dateFormat = "EEEE";
            let days: Array<any> = [],
                startDate = startOfWeek(currentMonth);

            for (let i = 0; i < 7; i++) {
                days.push(
                  <div className="col col-center" key={i}>
                    {format(addDays(startDate, i), dateFormat)}
                  </div>
                );
            }

            return(<div className="days row">{days}</div>);
        }
        catch(ex){
            console.log(`Error Rendering Calendar Days: ${ex}`);
            return (<div />);
        }
    }

    const renderCells = () => {
        try {
            const monthStart = startOfMonth(currentMonth);
            const monthEnd = endOfMonth(monthStart);
            const startDate = startOfWeek(monthStart), 
                endDate = endOfWeek(monthEnd),
                dateFormat = "d";

            let rows: Array<any> = [],  days: Array<any> = [], 
                day = startDate, formattedDate = "", rowIdx = 0;

            while (day <= endDate) {
                for (let i = 0; i < 7; i++) { 
                    formattedDate = format(day, dateFormat);
                    const dateKey = format(day, "yyyy-MM-dd");
                    const cloneDay = day, 
                        eventList = (dateKey in eventDayMap ? eventDayMap[dateKey] : []);
                    days.push(
                        <div className={`col cell ${!isSameMonth(day, monthStart) ? "disabled" : isSameDay(day, selectedDate) ? "selected" : "" }`}
                            key={i} onClick={() => onDateClick(cloneDay)}
                        >
                            <span className="number">{formattedDate}</span>
                            <span className="bg">{formattedDate}</span>
                            <div className='event-list-container'>
                                {eventList.map((evt, j) => 
                                    <div className='event-pt' key={j} style={{ backgroundColor: evt.color }} title={evt.title} onClick={()=> setShowEvent({ display: true, selectedId: (isV2 ? evt?.id : evt.name)})}/>
                                )}
                            </div>
                        </div>
                    );
                    day = addDays(day, 1);
                }
                rows.push(<div className="row" key={rowIdx}>{days}</div>);
                days = []; rowIdx++;
            }

            return(<div className="body">{rows}</div>);
        }
        catch(ex){
            console.log(`Error Rendering Calendar Cells: ${ex}`);
            return (<div />);
        }
    }

    const getVersionDate = (evt, start=true) =>{
        if(start){
            return (isV2 ? evt?.startDt : evt?.datetime);
        }
        else {
            return (isV2 ? evt?.endDt : evt?.datetime);
        }
    }
    
    useEffect(()=> {
        try {
            let tmpMap = {};
            for(let i=0; i < events.length; i++){
                let gId = events[i].grade_list;
                let d = new Date(getVersionDate(events[i])),
                    d2 = new Date(getVersionDate(events[i], false)),
                    gradeId = Array.isArray(gId) && gId.length > 0 ? gId[0] : gId;
                
                let grade_color = "#fff";
                if(Array.isArray(gId) && gId.length > 1){
                    grade_color = "#00295f";
                }
                else if(Array.isArray(gId) && gId.length === 1){
                    grade_color = colorMap[gradeId];
                }

                // Push For Each Day
                let dayDelta = differenceInCalendarDays(d2, d);
                    dayDelta = (dayDelta <= 1 ? 1 : (dayDelta + 1));

                for(let j=0; j < dayDelta; j++){
                    let tmpDate = format(addDays(d,j), "yyyy-MM-dd");
                    if(tmpDate in tmpMap) {
                        tmpMap[tmpDate].push({ id:events[i]?.id, "title": events[i]?.title, "name": events[i]?.name, color: grade_color })
                    }
                    else {
                        tmpMap[tmpDate] = [{ id:events[i]?.id, "title": events[i]?.title, "name": events[i]?.name, color: grade_color }];
                    }
                }
            }

            setEventDayMap(tmpMap);
        }
        catch(ex){
            console.log(`Setting Event Day Map: ${ex}`);
        }
    }, [events, colorMap])

    return (
        <div className='rojhay_calendar'>
            {renderHeader()}
            {renderDays()}
            {renderCells()}
        </div>
    );
}

export default RojhayCalendar;