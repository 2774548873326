import { useState, useEffect, useRef } from 'react';
import { validateForm, validateFormField, rootPath, loadFullGallery } from 'util';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';

import { toast } from 'react-toastify';
import { format, parseISO } from "date-fns";
import { AlbumImg } from 'datatypes/types';

import Loader from './loader';
import SimpleKeyValueSelect from './simpleKeyValueSelect';
import BaseGalleryUpload from './baseGalleryUpload';

type SubmitForm = {
    title: string; startDt: string; endDt: string; 
    location: string; description: string; event_links: any[];
    grade_list: any[]; images: AlbumImg[]; image_count?: number;
}

const formFields = [
    {"displayName":"Event Title", "name":"title", "size":"10", "required":true, "type":"input", "initial":""},
    {"displayName":"Start Date & Time", "name":"startDt", "size":"5", "required":true, "type":"date", "initial":""},
    {"displayName":"End Date & Time", "name":"endDt", "size":"5", "required":true, "type":"date", "initial":""}, 
    {"displayName":"Location", "name":"location", "size":"10", "required":false, "type":"input", "initial":""},
    {"displayName":"Event Description", "name":"description", "size":"10", "required":false, "type":"textarea", "initial":""},
    {"displayName":"Event Links", "name":"event_links", "size":"10", "required":false, "type":"link_pill", "initial":[]},   
    {"displayName":"Grades", "name":"grade_list", "size":"10", "required":false, "type":"multi-select", "overflow":true, "initial":[]},
    {"displayName":"Event Images", "name":"images", "size":"10", "required":false, "type":"image_upload", "initial":[], "noSubmit":true}
];

function EventForm({ eventId, gradeList, successCb }){
    const [formData, setFormData] = useState<SubmitForm>();
    const [formFieldValidation, setFormFieldValidation] = useState({});
    const [formValidation, setFormValidation] = useState(false);
    const [formImages, setFormImages] = useState<Array<AlbumImg>>([]);
    const [loading, setLoading] = useState(false);

    const [imgDelta, setImgDelta] = useState(0);
    const [completedIAs, setCompletedIAs] = useState<Array<Boolean>>([]);

    const hasPageRendered = useRef({ init: false, event_data: false});

    const setLocalData = (e) =>{
        let name = e.target.name,
        value = e.target.value;

        // Set Form Data
        setFormData((d) => { return { ...d, [name]: value } as SubmitForm; });

        // Set Form Field Validation
        let fieldIdx = formFields.map(e => e.name).indexOf(name);
        if(fieldIdx >= 0){
            checkFieldValidation(formFields[fieldIdx], value);
        }
    }

    const setMultiSelect = (list, name) => {
        setLocalData({ target: { name: name, value: list }});
    }

    const checkFieldValidation = (field, fieldData=null) => {
        try {
            if(formData){
                fieldData = fieldData == null ? formData[field.name] : fieldData;

                if(Object.keys(formFieldValidation)?.length > 0){
                    setFormFieldValidation((d) =>{
                        return { ...d, [field.name]: validateFormField(fieldData, field.type, field.required, field.options)}
                    });
                }
            }
        }
        catch(ex){
            console.log(`Error Validating Field: ${ex}`);
        }
    }

    const formatGradeList = () => {
        let tmpList = gradeList.map((grade) => { return { code: grade.slug, name: grade.title }});
        return tmpList;
    }

    const buildFormField = (form_data) => {
        try {
            if(!formData){ return <></>; }
            
            switch(form_data?.type){
                case "input":
                    return <input type="text" name={`${form_data.name}`} value={formData[form_data.name]} onChange={setLocalData} onBlur={()=> checkFieldValidation(form_data)} />;
                case "multi-select":
                    return <MultiSelect value={formData[form_data.name]} onChange={(e: MultiSelectChangeEvent) => setMultiSelect(e.value, form_data.name)} options={formatGradeList()} optionLabel="name" 
                        placeholder={`Select ${form_data.name}`} className="w-full md:w-20rem" maxSelectedLabels={3} />    
                case "textarea":
                    return <textarea name={`${form_data.name}`} value={formData[form_data.name]} onChange={setLocalData} onBlur={()=> checkFieldValidation(form_data)} />;
                case "date":
                    return <input type="datetime-local" name={`${form_data.name}`} value={formData[form_data.name]} onChange={setLocalData} onBlur={()=> checkFieldValidation(form_data)} />;
                case "link_pill":
                    return <SimpleKeyValueSelect items={formData[form_data.name]} setItems={(list)=> { setMultiSelect(list, form_data.name)}}/>    
                case "image_upload":
                    return <BaseGalleryUpload item_id={eventId} type="event" items={formImages} setItems={setFormImages}/>;    
                default:
                    return <></>;
            }
        }
        catch(ex){
            console.log(`Error Building Form Field: ${ex}`);
            return <></>;
        }
    }

    const resetForm = () => {
        try {
            let initFormData= {} as SubmitForm, initValidation = {};
            formFields.forEach((item) => { initFormData[item.name] = item.initial; initValidation[item.name] = true; });
            setFormData(initFormData);
            setFormFieldValidation({ ...initValidation });
        }
        catch(ex){
            console.log(`Error resetting form: ${ex}`);
        }
    }

    const buildFormData = (data, formImages, formData) => {
        let ret = {} as SubmitForm, initValidation = {};
        try {
            if("id" in data){ ret['id'] = data.id; }

            // Add Form Images
            data["images"] = formImages;

            for(let i=0; i < formFields.length; i++){
                let item = formFields[i];

                if(formData == true && item?.noSubmit == true){ continue; }

                if(item.name in data) {
                    if(item.name == "event_links" && formData){
                        ret[item.name] = data.event_links.filter((item) => { return validateFormField(item, "link_pill", true); });
                    }
                    else if(item.name == "grade_list"){
                        let formated_grade_list = formatGradeList();

                        ret[item.name] = (formData ? 
                            data.grade_list.map((item)=> { return item?.code; }) :
                            formated_grade_list.filter((grade)=> { 
                                return data.grade_list.indexOf(grade?.code) >= 0;
                            })
                        );
                    }
                    else if(item.name == "startDt" || item.name == "endDt"){
                        let tmpDt = parseISO(data[item.name]);
                        ret[item.name] = format(tmpDt, 'yyyy-MM-dd\'T\'HH:mm');                        
                    }
                    else {
                        ret[item.name] = data[item.name];
                    }
                }
                else {
                    ret[item.name] = item.initial;
                }

                initValidation[item.name] = true;
            }
            
            setFormFieldValidation({ ...initValidation });
        }
        catch(ex){
            console.log(`Building Form Data: ${ex}`);
            ret['error'] = "NA";
        }

        return ret;
    }

    const confirmForm = () => {
        try {
            if(!formData) { return false; }

            if(!("startDt" in formData) || !("endDt" in formData)){
                toast.error("Missing Date Information For Event", { position: "top-right",
                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                    draggable: true, progress: undefined, theme: "light", });
                return false;
            }

            let tmpStDt = new Date(`${formData.startDt}`),
                tmpEndDt = new Date(`${formData.endDt}`);             
            
            if(tmpStDt.getTime() > tmpEndDt.getTime()){
                toast.error("Please ensure start date falls before end date", { position: "top-right",
                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                    draggable: true, progress: undefined, theme: "light", });
                return false;
            }

            return true;
        }
        catch(ex){
            console.log(`Confirming Form: ${ex}`);
            return false;
        }
    }

    const insertImgs = (imgList: AlbumImg[], albumId: String) => {
        try {
            setLoading(true);
                
            for(let i=0; i < imgList.length; i++){
                let postData = JSON.stringify({...imgList[i], item_id: albumId});

                fetch(`${rootPath}/v2/api/photo`, {
                    method: "POST", body: postData,
                    headers: { "Accept": "application/json", "Content-Type":"application/json"}
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(!res.results) {
                        console.log(`Error With Inserting Image [DF00]: ${res.error}`);
                        toast.error("Sorry, There was an issue uploading one of your images [Please Contact Site Admin]", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                            draggable: true, progress: undefined, theme: "light", });
                    }
                }).catch((err) =>{
                    console.log(`Error With Submitting Image [DF01]: ${err}`);
                    toast.error("Sorry, There was an issue uploading one of your images [Please Contact Site Admin]", { position: "top-right",
                        autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                        draggable: true, progress: undefined, theme: "light", });
                }).finally(()=> { 
                    setCompletedIAs((d) =>{
                        let tmp = [...d]; tmp.push(true);
                        return tmp;
                    });
                });
            }  
                    
        }
        catch(ex){
            console.log(`Inserting Img: ${ex}`);
        }
    }

    const removeImgs = (imgList: AlbumImg[]) => {
        try {
            setLoading(true);
                
            for(let i=0; i < imgList.length; i++){
                fetch(`${rootPath}/v2/api/photo/${imgList[i]._id}`, {
                    method: "DELETE",
                    headers: { "Accept": "application/json", "Content-Type":"application/json"}
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(!res.results) {
                        console.log(`Error With Deleting Image [DF00]: ${res.error}`);
                        toast.error("Sorry, There was an issue deleting one of your images [Please Contact Site Admin]", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                            draggable: true, progress: undefined, theme: "light", });
                    }
                }).catch((err) =>{
                    console.log(`Error With Deleting Image [DF01]: ${err}`);
                    toast.error("Sorry, There was an issue deleting one of your images [Please Contact Site Admin]", { position: "top-right",
                        autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                        draggable: true, progress: undefined, theme: "light", });
                }).finally(()=> { 
                    setCompletedIAs((d) =>{
                        let tmp = [...d]; tmp.push(true);
                        return tmp;
                    });
                });
            }          
        }
        catch(ex){
            console.log(`Inserting Img: ${ex}`);
        }
    }

    const submitImgs = (albumId) => {
        try {
            if(formData && albumId){
                let imgList: AlbumImg[] = formData.images ?? [];

                // Insert Images
                let newImgs = imgList.filter((img) => { 
                    return (!img?._id || img?._id?.length <= 0);
                });                
                
                // Remove Images
                let deleteImgs = imgList.filter((img) => { 
                    return img?.delete === true;
                });

                let deltaSz = newImgs.length + deleteImgs.length;

                setCompletedIAs([]);
                setImgDelta(deltaSz);

                if(deltaSz <= 0){ successCb(); }

                if(newImgs?.length > 0){ insertImgs(newImgs, albumId); }                
                if(deleteImgs?.length > 0){ removeImgs(deleteImgs); }           
            }
            else {
                successCb();
            }            
        }
        catch(ex){
            console.log(`Submitting Images: ${ex}`);
        }
    }

    const submitForm = () => {
        if(formValidation && confirmForm()){
            let processedForm = buildFormData(formData, formImages, true);

            if(!("error" in processedForm)) {
                let path_type = ("id" in processedForm && processedForm?.id ? 'update' : 'new');
                let postData = JSON.stringify(processedForm);

                setLoading(true);
                fetch(`${rootPath}/v2/api/event/${path_type}`, {
                    method: "POST", body: postData,
                    headers: { "Accept": "application/json", "Content-Type":"application/json"}
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(res.results) {
                        toast.success("Successfully Created Event", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                            draggable: true, progress: undefined, theme: "light" });
                        
                        submitImgs(res.results);
                    }
                    else {
                        console.log(`Error With Submitting Form [DF00]: ${res.error}`);
                        toast.error("Sorry, There was an issue submitting your form [Please Contact Site Admin]", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                            draggable: true, progress: undefined, theme: "light", });
                    }
                }).catch((err) =>{
                    console.log(`Error With Submitting Form [DF01]: ${err}`);
                }).finally(()=> { setLoading(false); });
            }
        }
    }

    const getEvent = () =>{
        try {
            if(eventId){
                setLoading(true);
                fetch(`${rootPath}/v2/api/event/${eventId}`, {
                    method: "GET", headers: { "Accept": "application/json", "Content-Type":"application/json"}
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(res.results) {
                        let id_form_data = buildFormData(res.results, formImages, false);
                        let tmp_image_count = ("image_count" in res.results ? res.results.image_count : 0);

                        setFormData((d) => {
                            let tmp = { ...id_form_data, "image_count": tmp_image_count};
                            if(d && "images" in d) { tmp["images"] = d.images };

                            return tmp;
                        });
                    }
                    else {
                        console.log(`Error Getting Event List [E02]: ${res.error}`);
                    }
                }).catch((err) =>{
                    console.log(`Error Getting Event List [E03]: ${err}`);
                }).finally(()=>{ setLoading(false); });
            }
            else {
                resetForm();
            }
        }
        catch(ex){
            console.log(`Error Getting Event Sub List [M04] ${ex}`);
        }
    }

    const deleteEvent = () => {
        try {
            if(confirm('Are you sure you want to delete this event?')){
                setLoading(true);
                fetch(`${rootPath}/v2/api/event/delete/${eventId}`, {
                    method: "DELETE",
                    headers: { "Accept": "application/json", "Content-Type":"application/json"}
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(res.results) {
                        toast.success("Successfully Deleted Event", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                            draggable: true, progress: undefined, theme: "light", });

                        successCb();
                    }
                    else {
                        console.log(`Error With Deleting Event [DF00]: ${res.error}`);
                        toast.error("Sorry, There was an issue deleting this event [Please Contact Site Admin]", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                            draggable: true, progress: undefined, theme: "light", });
                    }
                }).catch((err) =>{
                    console.log(`Error With Deleting Event [DF01]: ${err}`);
                }).finally(()=> { setLoading(false); });
            }
        }
        catch(ex){
            console.log(`Error resetting form: ${ex}`);
        }   
    }

    useEffect(()=>{ 
        getEvent();
        hasPageRendered.current.event_data = true;
    },[]);

    useEffect(()=> {
        if(formData){
            setFormValidation(validateForm(formFields, formData).length === 0);

            if(hasPageRendered.current.event_data){
                loadFullGallery(eventId, "event", formData?.image_count ?? 0, setFormImages);
                hasPageRendered.current.event_data = false;
            }
        }
    },[formData]);

    useEffect(()=>{
        if(imgDelta > 0 && completedIAs.length >= imgDelta){
            setLoading(false);
            successCb();
        }
    },[completedIAs]);

    return (
        <div className="hsa-form-container dynamic">
            {Object.keys(formFieldValidation)?.length > 0 &&
                <>
                    <div className="fields-container">
                        {formFields.map((field, i)=>
                            <div className={`field-container sz-${field.size} ${(formFieldValidation[field.name] ? "" : "invalid")}`} key={i}>
                                <div className='field-label'>{field.displayName} <span>{field.required ? "*" :""}</span></div>
                                <div className={`field-input-container ${field.overflow === true ? 'multi-select' : ''}`}>{buildFormField(field)}</div>
                            </div>
                        )}
                    </div>

                    <div className='btn-container sticky-container'>
                        {eventId ? 
                            <div className='form-btn cancel' onClick={deleteEvent}>Delete</div> :
                            <div className='form-btn cancel' onClick={resetForm}>Clear</div>
                        }
                        
                        <div className={`form-btn ${formValidation ? "" : "disabled"}`} onClick={submitForm}>Submit</div>
                    </div>
                </>
            }
            {loading && <Loader />}
        </div>
    );
}

export default EventForm;