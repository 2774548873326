import { useState, useEffect } from 'react';
import { validateForm, validateFormField, rootPath } from 'util';
import { toast } from 'react-toastify';

import Select from 'react-select';
import InvoiceTable from './invoiceTable';
import UploadFiles from './uploadFiles';
import Loader from './loader';

function ReimbursementForm(){
    const [activityOptions, setActivityOptions] = useState([]);
    
    const formFields = [
        { sectionTitle:"Point Of Contact", fields:[
            {"displayName":"Name", "name":"name", "size":"3", "required":true, "type":"input"},
            {"displayName":"Email", "name":"email", "size":"4", "required":true, "type":"email"},
            {"displayName":"Volunteer Activity", "name":"volunteer_activity", "size":"3", "required":true, "type":"select", "options":["Reimburesment", "Pay Invoice", "Other (Add Details In Notes)"]},
            {"displayName":"Notes", "name":"notes", "size":"10", "required":false, "type":"textarea"}
        ]},
        { sectionTitle:"Payment Information", fields:[
            {"displayName":"Payto Name", "name":"payto_name", "size":"4", "required":true, "type":"input"},
            {"displayName":"Payto Address", "name":"payto_address", "size":"6", "required":true, "type":"input"},
            {"displayName":"Phone Number", "name":"phone", "size":"5", "required":true, "type":"input"},
            {"displayName":"Pay Arrangement", "name":"pay_arrangement", "size":"5", "required":true, "type":"select", "options":["Mail Directly", "Arrange Pickup"]},
        ]},
        { sectionTitle:"Reimbursement Information", fields:[
            {"displayName":"Associated Committees/Activites", "name":"associated_activites", "size":"10", "required":false, "type":"multi-select", "options":[]},
            {"displayName":"Description", "name":"description", "size":"10", "required":true, "type":"textarea"},
            /* Custom Components*/
            {"displayName":"List Invoices", "name":"invoices", "size":"10", "required":false, "type":"invoice-table"},
            {"displayName":"Reimbursement Total", "name":"reimbursement_total", "size":"4", "required":true, "type":"number"},
            {"displayName":"Receipts", "name":"receipts", "size":"10", "required":false, "type":"file-upload"},
        ]},
    ];

    const [formData, setFormData] = useState({
        "name":"", "email":"", "volunteer_activity":"", "notes":"",
        "payto_name":"","payto_address":"","phone":"","pay_arrangement":"",
        "associated_activites":[],"description":"","invoices":[],"receipts":[]
    });
    const [formFieldValidation, setFormFieldValidation] = useState({
        "name":true, "email":true, "volunteer_activity":true, "notes":true,
        "payto_name":true,"payto_address":true,"phone":true,"pay_arrangement":true,
        "associated_activites":true,"description":true,"invoices":true,"receipts":true
    });
    const [formValidation, setFormValidation] = useState(false);
    const [invoiceList, setInvoiceList] = useState<any>([]);
    const [invoiceTotal, setInvoiceTotal] = useState(0);
    const [files, setFiles] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    const getActivites = () =>{
        try {    
            setLoading(true);
            fetch(`${rootPath}/v1/api/datasource/volunteer-activities`, {
                method: "GET", headers: { "Accept": "application/json", "Content-Type":"application/json"}
            })
            .then((response) => response.json())
            .then((res)=> {
                if(res.results && res.results.length > 0) {
                    let activityLabel = res.results.map((item) => {
                        return { label: item.name, value: item.value };
                    });
                    setActivityOptions(activityLabel);
                }
                else {
                    console.log(`Error Getting Volunteer Activites [EG01]: ${res.error}`);
                }
            }).catch((err) =>{
                console.log(`Error Getting Volunteer Activites [EG02]: ${err}`);
            })
            .finally(()=>{ setLoading(false); });
            
        }
        catch(ex){
            console.log(`Error Getting Event Sub List [EG03] ${ex}`);
        }
    }

    const setLocalData = (e, formField) =>{
        let name = e.target.name,
        value = e.target.value;

        // Set Form Data
        setFormData((d) => { return { ...d, [name]: value }; });

        // Set Form Field Validation
        checkFieldValidation(formField, value);
    }

    const setMultiSelectLocalData = (val, formField) =>{
        let name = formField.name, value = val;

        // Set Form Data
        setFormData((d) => { return { ...d, [name]: value }; });

        // Set Form Field Validation
        checkFieldValidation(formField, value);
    }

    const checkFieldValidation = (field, fieldData=null) => {
        try {
            fieldData = fieldData == null ? formData[field.name] : fieldData;

            if(Object.keys(formFieldValidation)?.length > 0){
                setFormFieldValidation((d) =>{
                    return { ...d, [field.name]: validateFormField(fieldData, field.type, field.required, field.options)}
                });
            }
        }
        catch(ex){
            console.log(`Error Validating Field: ${ex}`);
        }
    }

    const buildFormField = (form_data) => {
        try {
            switch(form_data?.type){
                case "input":
                    return <input type="text" name={`${form_data.name}`} value={formData[form_data.name]} onChange={(e) => setLocalData(e, form_data)} onBlur={()=> checkFieldValidation(form_data)} />;
                case "email":
                    return <input type="text" name={`${form_data.name}`} value={formData[form_data.name]} onChange={(e) => setLocalData(e, form_data)} onBlur={()=> checkFieldValidation(form_data)} />;
                case "select":
                    return <select name={`${form_data.name}`} value={formData[form_data.name]} onChange={(e) => setLocalData(e, form_data)} onBlur={()=> checkFieldValidation(form_data)}>
                        <option hidden></option>
                        {form_data.options.map((item,i0) => <option key={i0} value={item}>{item}</option> )}
                    </select>;
                case "multi-select":
                    return <Select className="basic-multi-select" defaultValue={formData[form_data.name]} name={`${form_data.name}`} onChange={(e) => setMultiSelectLocalData(e, form_data)} options={activityOptions} isSearchable={false} isMulti />;
                case "textarea":
                    return <textarea name={`${form_data.name}`} value={formData[form_data.name]} onChange={(e) => setLocalData(e, form_data)} onBlur={()=> checkFieldValidation(form_data)} />;
                case "date":
                    return <input type="date" name={`${form_data.name}`} value={formData[form_data.name]} onChange={(e) => setLocalData(e, form_data)} onBlur={()=> checkFieldValidation(form_data)} />;
                case "number":
                    return <input type="number" name={`${form_data.name}`} value={formData[form_data.name]} onChange={(e) => setLocalData(e, form_data)} onBlur={()=> checkFieldValidation(form_data)} />;                            
                case "invoice-table":
                    return <InvoiceTable invoiceList={invoiceList} setInvoiceList={setInvoiceList} invoiceTotal={invoiceTotal} setInvoiceTotal={setInvoiceTotal}/>;
                case "file-upload":
                    return <UploadFiles files={files} setFiles={setFiles}/>;    
                default:
                    return <></>;
            }
        }
        catch(ex){
            console.log(`Error Building Form Field: ${ex}`);
            return <></>;
        }
    }

    const resetForm = () => {
        try {
            let initFormData: any = {}, initValidation: any = {};
            let tmpFormKeys = Object.keys(formData);
            tmpFormKeys.forEach((item) => { initFormData[item] = ""; initValidation[item] = true; });

            setFormData({ ...initFormData });
            setFormFieldValidation({ ...initValidation });
            setInvoiceList([]);
            setFiles([]);
        }
        catch(ex){
            console.log(`Error resetting form: ${ex}`);
        }
    }

    const submitForm = () => {
        if(formValidation){
            const postData = new FormData();
            const submitData = { ...formData, "invoices":[...invoiceList], "reimburesment_total":invoiceTotal,
                    "receipts":[...Array.from(files).map((f:any) => f.name)]
                }

            postData.append('form_type',"reimbursement_request");
            postData.append('form_data',JSON.stringify(submitData));
            for(let i=0; i < Array.from(files).length; i++){
                postData.append('files',files[i]);
            }

            setLoading(true);
            fetch(`${rootPath}/v1/api/submit-form`, {
                method: "POST", body: postData,
            })
            .then((response) => response.json())
            .then((res)=> {
                if(res.status) {
                    toast.success("Form Submitted Successfully", { position: "top-right",
                        autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                        draggable: true, progress: undefined, theme: "light", });
                }
                else {
                    console.log(`Error With Submitting Form [DF00]: ${res.error}`);
                    toast.error("Sorry, There was an issue submitting your form [Please Contact Site Admin]", { position: "top-right",
                        autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                        draggable: true, progress: undefined, theme: "light", });
                }
            }).catch((err) =>{
                console.log(`Error With Submitting Form [DF01]: ${err}`);
            }).finally(()=> { setLoading(false); });
        }
    }

    useEffect(()=>{ resetForm(); getActivites(); },[]);
    useEffect(()=> {
        let tmpFieldFields: Array<any> = [];
        formFields.forEach((item) => { tmpFieldFields = tmpFieldFields.concat(item.fields); });
        let submitData = { ...formData, "invoices":[...invoiceList], 
            "receipts":[...Array.from(files).map((f:any) => f.name)], "reimburesment_total":invoiceTotal
        };

        setFormValidation(validateForm(tmpFieldFields, submitData).length === 0);
    },[formData]);

    return (
        <div className="hsa-form-container reimbursement">
            <h1>Reimbursement Form</h1>

            {formFields.map((section,i) => 
                <div className='form-section' key={i}>
                    <h2>{section.sectionTitle}</h2>

                    <div className="fields-container">
                        {section.fields.map((field, j)=>
                            <div className={`field-container sz-${field.size} ${(formFieldValidation[field.name] ? "" : "invalid")}`} key={`${i}.${j}`}>
                                <div className='field-label'>{field.displayName} <span>{field.required ? "*" :""}</span></div>
                                <div className={`field-input-container ${field.type}`}>{buildFormField(field)}</div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            <div className='btn-container'>
                <div className='form-btn cancel' onClick={resetForm}>Clear</div>
                <div className={`form-btn ${formValidation ? "" : "disabled"}`} onClick={submitForm}>Submit</div>
            </div>
            {loading && <Loader />}
        </div>
    );
}

export default ReimbursementForm;