import { createContext, useState } from 'react';
import { StoreContextType, Formations } from 'datatypes/types';
import { rootPath } from 'util';

const StoreContext = createContext<StoreContextType>({ 
    grades: [], checkGrades: function() {}, getGradeImg: function (id:string) { return null;},
    committees: [], checkCommittees: function() {}, homeImages: [], checkHomeImages: function() {},
    loader:{}
});

function Provider({ children }: { children: any }) {
    const [grades, setGrades] = useState<Array<Formations>>([]);
    const [committees, setCommittees] = useState<Array<Formations>>([]);
    const [homeImages, setHomeImages] = useState<Array<string>>([]);

    // Loaders
    const [loader, setLoader] = useState({ grades: false, committees: false, homeImages: false });

    const setLoaderValue = (name, value) =>{
        try {
            setLoader((d)=>{
                let tmp = {...d};
                tmp[name] = value;

                return tmp;
            })
        }
        catch(ex){
            console.log(`Setting Loader Values: ${ex}`);
        }
    }

    const getGradeImg = (grade_slug: string) => {
        let ret = null;
        try {
            let sel_grade = grades.filter((g)=> {
                return g.slug == grade_slug;
            });

            ret = (sel_grade.length > 0 ? sel_grade[0]?.image?.src : null);
        }
        catch(ex){
            console.log(`Getting Grade Img: ${ex}`);
        }

        return ret;
    }

    const checkGrades = () => {
        try {
            if(grades.length <= 0){
                setLoaderValue("grades", true);
                fetch(`${rootPath}/v2/api/formation/grade?include_fields=title,slug,icon,colorCode,image`, {
                    method: "GET", headers: { "Accept": "application/json", "Content-Type":"application/json"}
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(res.results) {
                        setGrades(res.results);
                    }
                    else {
                        console.log(`Error Getting Store Grade List [E02]: ${res.error}`);
                    }
                }).catch((err) =>{
                    console.log(`Error Getting Store Grade List [E03]: ${err}`);
                }).finally(()=>{ setLoaderValue("grades", false); });
            }
        }
        catch(ex){
            console.log(`Checking Store Grade List: ${ex}`);
        }
    }

    const checkCommittees = () => {
        try {
            if(committees.length <= 0){
                setLoaderValue("committees", true);
                fetch(`${rootPath}/v2/api/formation/committee?include_fields=title,slug,leadership,links`, {
                    method: "GET", headers: { "Accept": "application/json", "Content-Type":"application/json"}
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(res.results) {
                        setCommittees(res.results);
                    }
                    else {
                        console.log(`Error Getting Store Committee List [E02]: ${res.error}`);
                    }
                }).catch((err) =>{
                    console.log(`Error Getting Store Committee List [E03]: ${err}`);
                }).finally(()=>{
                    setLoaderValue("committees", false);
                });
            }
        }
        catch(ex){
            console.log(`Checking Store Committee List: ${ex}`);
        }
    }

    const checkHomeImages = () => {
        try {
            if(homeImages.length <= 0){
                setLoaderValue("homeImages", true);
                fetch(`${rootPath}/v1/api/page/home`, {
                    method: "GET", headers: { "Accept": "application/json", "Content-Type":"application/json"}
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(res.results) {
                        setHomeImages(res.results);
                    }
                    else {
                        console.log(`Error Getting Store Home Image List [E02]: ${res.error}`);
                    }
                }).catch((err) =>{
                    console.log(`Error Getting Store Home Image List [E03]: ${err}`);
                }).finally(()=>{
                    setLoaderValue("homeImages", false);
                });
            }
        }
        catch(ex){
            console.log(`Checking Store Home Image List: ${ex}`);
        }
    }

    return <StoreContext.Provider value={{ grades, checkGrades, getGradeImg, committees, checkCommittees, homeImages, checkHomeImages, loader }}>{children}</StoreContext.Provider>;
}

export default { StoreContext, Provider };