import { useContext, useState, useRef, useEffect } from 'react';
import { format, isSameDay, parseISO } from "date-fns";
import tinycolor from "tinycolor2";

import userContext from "context/userContext";
import storeContext from 'context/storeContext';

import { AlbumImg, UserContextType, EventV2, StoreContextType } from 'datatypes/types';
import { validateFormField, URL_REGEX, loadFullGallery } from 'util';

import hsa_logo from "../assets/hsa-logo2.png";

function EventGallery({ eventInfo, setShowFeedback }: { eventInfo: EventV2 | null | undefined, setShowFeedback: (state: any)=> void}){
    const { user } = useContext(userContext.UserContext) as UserContextType;
    const { grades } = useContext(storeContext.StoreContext) as StoreContextType;

    const imageCarouselRef = useRef<HTMLDivElement>(null);
    const resetImageGallery = useRef(false);

    const [ctrlStatus, setCtrlStatus] = useState({ "prev": true, "next": false});
    const [selectedImg, setSelectedImg] = useState<AlbumImg | null>();
    const [imgList, setImgList] = useState<Array<any>>([]);

    const [colorMap, setColorMap] = useState({});
    
    const buildColorMap = () => {
        try {
            let tmpMap = {};
            if(grades) {
                for(let i=0; i < grades.length; i++){
                    tmpMap[grades[i].slug || ""] = {title: grades[i].title, colorCode: `${grades[i].colorCode}`};
                }
            }

            setColorMap(tmpMap);
        }
        catch(ex){
            console.log(`Error: Building Color Map:  ${ex}`);
        }
    }

    const isColorDark = (color) =>{
        try {
            if(!color) { return false; }
            color = (color.indexOf('#') === 0 ? color : `#${color}`);

            let tmpColor = tinycolor(color);
            return tmpColor.isDark();
        }
        catch(ex){
            console.log(`Error Checking Color Lightness: ${ex}`);
        }

        return false;
    }

    const buildGradePill = (grade_key) => {
        try {
            if(grade_key in colorMap){
                let color_item = colorMap[grade_key];

                return <div className='grade-pill'>
                    <div className='pill-btn' style={{ backgroundColor: `#${color_item?.colorCode}` }} />
                    <span>{color_item.title}</span>
                </div>;
            }
            else {
                return <></>;
            }
        }
        catch(ex){
            console.log(`Building Grade Pill: ${ex}`);
            return <></>;
        }
    }

    const formatDate = (date, type) =>{
        let ret = "";
        try {
            if(validateFormField(date, "date", true)){
                let d = parseISO(date);
                switch(type) {
                    case "date":
                        ret = format(d,'MMM dd, yyyy');
                        break;
                    case "time":
                        ret = format(d,'h:mm aaaa');
                        break;
                }
            }
        }
        catch(ex){
            console.log(`[Error] formatting data: ${ex}`);
        }

        return ret;
    }

    const compareDates = (d1: any, d2:any) => {
        try {
            let date1 = parseISO(d1),
                date2 = parseISO(d2);

            return isSameDay(date1, date2);
        }
        catch(ex){
            console.log(`Comparing Dates: ${ex}`);
            return false;
        }
    }

    const controlSlider = (ref,dir) => {
        try {
            let offsetWidth = ref.current.offsetWidth,
                scrollWidth = ref.current.scrollWidth;
            if(scrollWidth > offsetWidth){
                let scrollSz = (2 * 136),
                    newScrollLeft = ref.current.scrollLeft;
                
                if(dir === "prev"){
                    newScrollLeft = ref.current.scrollLeft - scrollSz;
                    newScrollLeft = (newScrollLeft < 0 ? 0 : newScrollLeft);
                }
                else if(dir === "next"){
                    newScrollLeft = ref.current.scrollLeft + scrollSz;
                }

                ref.current.scrollLeft = newScrollLeft;
                setCtrlStatus({ "prev": disableCtrl(ref, newScrollLeft, "prev"), "next":disableCtrl(ref, newScrollLeft, "next") });
            }
        }
        catch(ex){
            console.log(`Error sliding carousel: ${ex}`)
        }
    }

    const disableCtrl = (ref, newScrollLeft, dir) => {
        try {
            if(dir === "prev"){
                return newScrollLeft <= 0;
            }
            else if(dir === "next"){
                return (ref.current.offsetWidth + newScrollLeft) >= ref.current.scrollWidth
            }
        }
        catch(ex){
            console.log(`Disabling Controlling Slider: ${ex}`);
        }

        return false;
    }

    const getEmptyImgList = () => {
        let ret: Array<Number> = [];
        try {
            let replaceNum = 5 - (imgList.length);
            if(replaceNum > 0){
                for(let i=0; i < replaceNum; i++){
                    ret.push(1);
                }
            }
        }
        catch(ex){
            console.log(`Error Getting Empty Img List: ${ex}`);
        }

        return ret;
    }

    const isPrevEvent = () => {
        try {
            if(eventInfo?.endDt){
                let d = new Date(eventInfo.endDt);
                return (d < new Date());
            }
        }
        catch(ex){
            console.log(`Error checking is Previous event: ${ex}`);
        }
        return false;
    }

    const renderText = (text) => {
        let ret = null;

        try {
            if(text && text.length > 0){
                ret = text.split(/\s|\n/)
                    .map((part,i) => {
                        if(URL_REGEX.test(part)){
                            let url = (part.indexOf('http://') >= 0 || part.indexOf('https://') >= 0 ? part : `https://${part}`);

                            return <a href={url} target="_blank" className='text-link' key={i}>{part}</a>;
                        }
                        else {
                            return `${part} `;
                        }
                    });
            }
        }
        catch(ex){
            console.log(`Error Rendering Text: ${ex}`);
        }
        return ret;
    }

    useEffect(()=>{ 
        if(imgList.length > 0) { setSelectedImg(imgList[0]); }
        else { setSelectedImg(null); }        
    },[imgList]);

    useEffect(()=>{ 
        resetImageGallery.current = false;
        if(eventInfo?.id){
            if(imgList.length > 0){
                setImgList([]);
            }
            else if(!resetImageGallery.current) {
                loadFullGallery(eventInfo.id, "event", eventInfo?.image_count, setImgList, false);
                resetImageGallery.current = true;
            }
            buildColorMap();
        }
    },[eventInfo]);

    useEffect(()=>{
        if((imgList.length == 0) && eventInfo?.id && !resetImageGallery.current){
            loadFullGallery(eventInfo.id, "event", eventInfo?.image_count, setImgList, false);
            resetImageGallery.current = true;
        }
    }, [imgList]);

    return (    
        <div className='gallery-modal-body gallery-display'>
            <div className='selected-img'>
                <img src={(selectedImg?.src ? selectedImg?.src : hsa_logo)} className={`${selectedImg?.title ? '' : 'empty'}`} alt={`${eventInfo?.title} photo gallery`} />
            </div>
            <div className='event-info-container'>
                <div className='image-carousel-container'>
                    <div className='img-list-container' ref={imageCarouselRef}>
                        {imgList.map((img,i) => (
                            <div className='img-item-container' key={i} onClick={()=> setSelectedImg(img)}>
                                <img src={img?.src} alt={`${eventInfo?.title} carousel image`}/>
                            </div>
                        ))}
                        {getEmptyImgList().map((_item, i) =>  <div className={`empty-item-container ${user?.is_admin ? 'admin-view' : ''}`} key={i}/>)}
                    </div>
                    {imgList?.length > 0 ?
                        <>
                            <span className={`material-symbols-outlined img-list-ctrl prev ${(ctrlStatus.prev || imgList.length <= 1) ? "disable":""}`} onClick={()=> controlSlider(imageCarouselRef, "prev")}>chevron_left</span>
                            <span className={`material-symbols-outlined img-list-ctrl next ${(ctrlStatus.next || imgList.length <= 1) ? "disable":""}`} onClick={()=> controlSlider(imageCarouselRef, "next")}>chevron_right</span>
                        </>
                        :<></>
                    }
                </div>
                <div className='event-info'>
                    <h3>{eventInfo?.title}</h3>
                    <h4>{eventInfo?.location}</h4>
                    
                    <div className='grade-pill-container'>
                        {(eventInfo?.grade_list ? eventInfo.grade_list : []).map((g, i)=>
                            <div className='pill-outer-container' key={i}>
                                {buildGradePill(g)}
                            </div>
                        )}
                    </div>

                    <div className='info-date'>
                        {compareDates(eventInfo?.startDt, eventInfo?.endDt) ?
                            <>
                                <span className="material-symbols-outlined">event</span>
                                <span className='date-content'>{formatDate(eventInfo?.startDt,"date")}</span>
                                        
                                <span className="material-symbols-outlined">schedule</span>
                                <span className='date-content'>{formatDate(eventInfo?.startDt,"time")} - {formatDate(eventInfo?.endDt,"time")}</span>
                            </> :
                            <>
                                <span className="material-symbols-outlined">event</span>
                                <span className='date-content'>{formatDate(eventInfo?.startDt,"date")} - {formatDate(eventInfo?.endDt,"date")}</span>
                            </>
                        }
                    </div>
                    <div className='info-description'>{renderText(eventInfo?.description)}</div>
                </div>                        
                {isPrevEvent() &&
                    <div className="btn-container">
                        <div className="btn" onClick={()=> setShowFeedback(true)}>
                            <span className='btn-text'>Feedback</span>
                            <span className="material-symbols-outlined">rate_review</span>
                        </div>
                    </div>  
                }                          
            </div>
        </div>  
    );
}

export default EventGallery;