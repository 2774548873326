import { useContext, useRef, useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';

import { EventModalDisplay, Formations, StoreContextType } from 'datatypes/types';

import background from '../assets/homeschool.jpg';
import cover_photo from '../assets/parents2.jpg';

import storeContext from 'context/storeContext';
import CommitteeModal from 'components/committeeModal';

const sortByList = [
    { title:"Name A-Z", code:"alphabetical"},
    { title:"Open Chair Positions", code:"open-leadership"},
    { title:"Active Signup Links", code:"active-links"}
];

function Committees(){
    document.title = 'Committee Page';
    const committeeSectionRef = useRef<HTMLDivElement>(null);

    const { committees, checkCommittees } = useContext(storeContext.StoreContext) as StoreContextType;
    const [showEvent, setShowEvent] = useState<EventModalDisplay>({ display: false, selectedId: null});
    const [displayCommittees, setDisplayCommittees] = useState<Array<Formations>>([]);
    const [sortBy, setSortBy] = useState(sortByList[0]);
    const [query, setQuery] = useState("");

    const pillGenerator = (committee: Formations) => {
        let ret:any[] = [];
        try {
            let leaderCount = (!committee?.leadership ? 0 :
                committee?.leadership.filter((c)=>{ 
                    return c.position.toLowerCase() != 'pending' && c.name.toLowerCase() != 'pending';
                }).length
            )

            if(leaderCount < 2){
                ret.push({ title:"Open Chair", type:"c1"});
            }

            if(committee?.links && committee?.links.length > 0){
                ret.push({ title:"Sign ups avaliable", type:"c2"});
            }
        }
        catch(ex){
            console.log(`Generating Pills: ${ex}`);
        }
        return ret;
    }

    const filterLeadership = (leaders?: any[]) =>{
        let ret: any[] = [];
        try {
            if(leaders){
                ret = leaders.filter((l)=>{
                    return (l.position.toLowerCase() != 'pending' && l.name.toLowerCase() != 'pending');
                });
            }
        }
        catch(ex){
            console.log(`Filtering Leaders: ${ex}`);
        }
        return ret;
    }

    useEffect(()=>{
        if(committees.length){
            let tmpList: Formations[] = [];
            switch(sortBy?.code){
                case "alphabetical":
                    tmpList = committees.sort((a,b)=> {
                        const nameA = a.title.toUpperCase(), 
                            nameB = b.title.toUpperCase(); 

                        if (nameA < nameB) { return -1; }
                        if (nameA > nameB) { return 1; }
                        
                        return 0;
                    });
                    break;
                case "open-leadership":
                    tmpList = committees.filter((com)=> {
                        let pendingPosIdx = com.leadership?.map((c)=> c.position.toLowerCase()).indexOf('pending');
                        let pendingNmIdx = com.leadership?.map((c)=> c.name.toLowerCase()).indexOf('pending');
                        let pendingIdx = ((pendingPosIdx && pendingPosIdx >= 0) || (pendingNmIdx && pendingNmIdx >= 0));

                        return com.leadership?.length == 0 || pendingIdx;
                    });
                    break;
                case "active-links":
                    tmpList = committees.filter((com)=> {
                        return (com.links && com.links.length > 0);
                    });
                    break;
                default:
                    tmpList =[];
                    break;
            }

            // filter list by search query
            if(query?.length > 0){
                tmpList = tmpList.filter((l)=> { return l?.title?.toLowerCase().includes(query.toLowerCase()); });
            }

            setDisplayCommittees(tmpList);
        }
    },[committees, sortBy, query]);

    useEffect(()=> { checkCommittees(); }, []);

    return (
        <div className="internal-page parents_grades">
            <div className='internal-landing'>
                <div className="cover-image">
                    <img src={cover_photo} alt="committee event photo" />
                </div>
                <h1>Committees</h1>
            </div>

            <section className='committees-section'>
                <h2>Our Committes</h2>
                <p>
                    Our St. Elizabeth Home and School Association (HSA) Committees help to carry out and lead inititives within our school community.
                    Each committee is parent run and facilitated.  If you are interested in joining or working with one of our committees, please feel free to reach out to the committee chairs.
                </p>                
            </section>

            <section className='committees-section' ref={committeeSectionRef}>
                <div className='search-container'>
                    <div className='search-item-container'>
                        <span className="material-symbols-outlined">search</span>
                        <input type="text" disabled={committees?.length <= 0} name="query" value={query} placeholder="Search For Committees" onChange={(e)=> { setQuery(e?.target?.value)}} />
                    </div>

                    <div className='sortby-container'>
                        <span className='title'>Filter By:</span>
                        <div className='dropdown-container'>
                            <Dropdown value={sortBy} onChange={(e) => setSortBy(e.value)} options={sortByList} optionLabel="title" 
                                className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} />
                        </div>
                    </div>
                </div>

                <div className='committee-list-container'>
                    {displayCommittees?.length <= 0 && 
                        <div className='no-data'>Sorry there are no committees for this search, try updating your search & filter.</div>
                    }
                    {displayCommittees.map((committee,i)=>
                        <div className='committee-item' key={i} onClick={()=> setShowEvent({ display: true, selectedId:committee.slug})}>
                            <div className='item-title'>{committee.title}</div>
                            <div className='item-pills'>
                                {pillGenerator(committee).map((pill,j) =>
                                    <div className={`pill ${pill.type}`} key={j}>{pill.title}</div>
                                )}
                            </div>

                            <div className='item-leaders'>
                                {filterLeadership(committee.leadership).map((leader, k)=>
                                    <div className='leader' key={k}>
                                        <span>{leader.position}:</span> {leader.name}
                                    </div>
                                )}
                            </div>

                            <div className='icon-link'>
                                <span className="material-symbols-outlined">open_in_new</span>
                            </div>
                        </div>
                    )}
                </div>

                <div className='backdrop'><img src={background} /></div>
            </section>

            <CommitteeModal show={showEvent.display} setShow={setShowEvent} committeeId={showEvent.selectedId}/>
        </div>
    );
}

export default Committees;