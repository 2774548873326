import { useContext, useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import tinycolor from "tinycolor2";

import { StoreContextType } from 'datatypes/types';

import cover_photo from '../assets/grades.jpg';

import storeContext from 'context/storeContext';

function Grades(){
    document.title = 'Grades Page';
    const gradeScrollRef = useRef<HTMLDivElement>(null);
    const gradeSectionRef = useRef<HTMLDivElement>(null);

    const [ctrlStatus, setCtrlStatus] = useState({ "prev": true, "next": false});

    const { grades, checkGrades } = useContext(storeContext.StoreContext) as StoreContextType;

    const isColorDark = (color) =>{
        try {
            if(!color) { return false; }
            color = (color.indexOf('#') === 0 ? color : `#${color}`);

            let tmpColor = tinycolor(color);
            return tmpColor.isDark();
        }
        catch(ex){
            console.log(`Error Checking Color Lightness: ${ex}`);
        }

        return false;
    }

    const controlSlider = (ref, dir) => {
        try {
            let offsetWidth = ref.current.offsetWidth,
                scrollWidth = ref.current.scrollWidth;

            if(scrollWidth > offsetWidth){
                let scrollSz = (offsetWidth <= 770 ? 220 : ((scrollWidth - offsetWidth) / 2)),
                    newScrollLeft = ref.current.scrollLeft;

                if(dir === "prev"){
                    newScrollLeft = ref.current.scrollLeft - scrollSz;
                }
                else if(dir === "next"){
                    newScrollLeft = ref.current.scrollLeft + scrollSz;
                }

                ref.current.scrollLeft = newScrollLeft;
                setCtrlStatus({ "prev": disableCtrl(ref, newScrollLeft, "prev"), "next":disableCtrl(ref, newScrollLeft, "next") });
            }
        }
        catch(ex){
            console.log(`Error Controlling Slider: ${ex}`);
        }
    }

    const disableCtrl = (ref, newScrollLeft, dir) => {
        try {
            if(dir === "prev"){
                return newScrollLeft <= 0;
            }
            else if(dir === "next"){
                return (ref.current.offsetWidth + newScrollLeft) >= ref.current.scrollWidth
            }
        }
        catch(ex){
            console.log(`Disabling Controlling Slider: ${ex}`);
        }

        return false;
    }

    useEffect(()=> { 
        checkGrades(); 
    }, []);

    return (
        <div className="internal-page parents_grades">
            <div className='internal-landing'>
                <div className="cover-image">
                    <img src={cover_photo} alt="parents event photo" />
                </div>
                <h1>Grades</h1>
            </div>
            
            <section className='grade-section' ref={gradeSectionRef}>
                <div className='class-list-ctrl'>
                    <h2>Our Grade Levels</h2>
                    <div className={`ctrl-arrow back ${ctrlStatus.prev ? "disable":""}`} onClick={()=> controlSlider(gradeScrollRef, "prev")}>
                        <span className="material-symbols-outlined">chevron_left</span>
                    </div>
                    
                    <div className={`ctrl-arrow forward ${ctrlStatus.next ? "disable":""}`} onClick={()=> controlSlider(gradeScrollRef, "next")}>
                        <span className="material-symbols-outlined">chevron_right</span>
                    </div>
                </div>
                <div className='list-positioner' ref={gradeScrollRef}>
                    <div className='class-list-container'>
                        <div className='box-out'>
                            {grades.map((grade,i) =>
                                <Link to={`/grades/${grade.slug}`} className={`book books-${i} ${(isColorDark(grade?.colorCode) ? 'dark' : "")}`} style={{ backgroundColor: `#${grade.colorCode}`}} key={i}>
                                    <div className='bend-line' />
                                    <div className='book-cover'>
                                        <div className={`book-title`}>
                                            <div className='title'>{grade.title}</div>
                                        </div>

                                        <div className='cover-image'>
                                            {grade?.image?.src ?
                                                <img src={grade.image.src} alt={`${grade.title} parent link cover`}/>
                                                : <></>
                                            }
                                        </div>
                                    </div>
                                </Link>
                            )}
                            {grades?.length == 0 && [0,1,2,3,4].map((empty,i) => <div className='empty-book empty-animate' key={i} />)}
                        </div>
                        <div className='box-inner'>
                            {grades.map((grade,i) =>
                                <div className={`book books-${i} book-blur`} style={{ backgroundColor: `#${grade.colorCode}`}} key={i} />
                            )}

                            {grades?.length == 0 && [0,1,2,3,4].map((empty,i) => <div className='book book-blur empty-book' key={i} />)}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Grades;