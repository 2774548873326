function SubFormKeyValue({ fields, items, setItems }){
    const setLocalItem = (idx, e) => {
        let name = e.target.name,
            value = e.target.value;
        
        let tmpList = [...items];
        tmpList[idx][name] = value;
        setItems(tmpList);
    }

    const addItem = () => {
        let tmpList = [...items], initialItem = {};
        fields.forEach((item)=>{ initialItem[item.name] = item?.initial; });

        tmpList.push(initialItem);

        setItems(tmpList);
    }

    const removeItem = (idx) => {
        let tmpList = [...items];
        tmpList.splice(idx,1);
        
        setItems(tmpList);
    }

    return (
        <div className="multiselect">
            <div className="multi-btn-container">
                <div className='add-btn' onClick={addItem}>
                    <span className="material-symbols-outlined">add</span>
                    <span>Add</span>
                </div>
            </div>
            <div className='hsa-form-container'>
                {(items) && items.map((item,i) => 
                    <div className="fields-container" key={i}>
                        {fields.map((field, j) =>
                            <div className={`field-container sz-${field.size}`} key={j}>
                                <div className='field-label'>{field.displayName} <span>{field.required ? "*" :""}</span></div>
                                <div className={`field-input-container`}>
                                    <input type="text" name={field.name} value={item[field.name]} placeholder={field.displayName} onChange={(e)=> { setLocalItem(i, e)}} />
                                </div>
                            </div>
                        )}

                        <div className={`field-container sz-1`}>
                            <div className="field-icon"><span onClick={()=> { removeItem(i) }} className="material-symbols-outlined">backspace</span></div>
                        </div>
                    </div>
                )}                
            </div>
        </div>
    );
}

export default SubFormKeyValue;