import { useState, useEffect, useRef } from 'react';
import 'rodal/lib/rodal.css';
import Rodal from 'rodal';
import { toast } from 'react-toastify';

import { rootPath, loadFullGallery } from 'util';
import { AlbumImg, Formations } from 'datatypes/types';
import Loader from './loader';

import background from '../assets/homeschool.jpg';

function CommitteeModal({ show, setShow, committeeId }){
    const modalWidth = (window.outerWidth < 500 ? (window.outerWidth * .9) : 450), 
        modalHeight = ((window.innerHeight - 100) * 0.95);
        
    const [committeeInfo, setCommitteeInfo] = useState<Formations | null>();
    const [gallery, setGallery] = useState<Array<AlbumImg>>([]);
    const [loading, setLoading] = useState(false);
    const [selectImg, setSelectImg] = useState("");

    const hasPageRendered = useRef({ "type_slug": false, "form_data": false });

    const getCommittee = () =>{
        try {   
            if(committeeId){
                setLoading(true);
                fetch(`${rootPath}/v2/api/formation/committee/${committeeId}`, {
                    method: "GET", headers: { "Accept": "application/json", "Content-Type":"application/json"}
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(res.results) {
                        setCommitteeInfo(res.results);
                    }
                    else {
                        console.log(`Error Getting Committee [EG01]: ${res.error}`);
                        setCommitteeInfo(null);
                        toast.error("Sorry, We are unable to retrieve this committee", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                            draggable: true, progress: undefined, theme: "light", });
                    }
                }).catch((err) =>{
                    console.log(`Error Getting Committee [EG02]: ${err}`);
                })
                .finally(()=>{ setLoading(false); });
            }
        }
        catch(ex){
            console.log(`Error Getting Committee Sub List [EG03] ${ex}`);
        }
    }

    const getInitial = () => {
        let ret = "-";
        try {
            if(committeeInfo?.title){
                ret = committeeInfo?.title[0];
            }
        }
        catch(ex){
            console.log(`Getting Initial: ${ex}`);
        }

        return ret;
    }

    const filterLeadership = (leaders?: any[], pending=false) =>{
        let ret: any[] = [];
        try {
            if(leaders){
                ret = leaders.filter((l)=>{
                    return (pending ? 
                            (!(l.position.toLowerCase() != 'pending' && l.name.toLowerCase() != 'pending')) :
                            (l.position.toLowerCase() != 'pending' && l.name.toLowerCase() != 'pending')
                        );
                });
            }
        }
        catch(ex){
            console.log(`Filtering Leaders: ${ex}`);
        }
        return ret;
    }

    useEffect(()=>{ 
        getCommittee(); setGallery([]);        
    },[committeeId]);

    useEffect(()=> {
        if(committeeInfo){
           loadFullGallery(committeeInfo?._id, "committee", committeeInfo?.image_count, setGallery);
           hasPageRendered.current.form_data = false;
        }
    },[committeeInfo]);

    useEffect(()=> { 
        document.body.classList.toggle('noscroll', (show == true));        
    }, [show]);

    return (
        <>
            <Rodal visible={show} onClose={()=> setShow(false)} animation={"slideUp"} width={modalWidth} height={modalHeight} customStyles={{ marginTop: '110px'}}>
                {(!loading && !committeeInfo) ? 
                    <div className='empty-event'><span>No Committee Information</span></div>:
                    <div className='committee-modal-body'>
                        <div className='committee-header'>
                            <div className='header-background'><img src={background} /></div>
                            <div className='committee-initial'>{getInitial()}</div>
                        </div>
                        <div className='committee-body'>
                            <h1>{committeeInfo?.title}</h1>

                            <div className='link-list'>
                                {committeeInfo?.links?.map((link,i)=>
                                    <a href={link.value} target="_blank" className="link-item" key={i}>{link.key}</a>
                                )}
                            </div>
                            
                            <div className='chair-list'>
                                {filterLeadership(committeeInfo?.leadership).map((leader,i)=>
                                    <div className='leader-item' key={i}>
                                        <div className='title'>{leader?.position}</div>
                                        <div className='name'>{leader?.name}</div>
                                        <div className='email'>{leader?.email}</div>
                                    </div>
                                )}
                                {filterLeadership(committeeInfo?.leadership, true).map((leader,i)=>
                                    <div className='leader-item pending' key={i}>
                                        <div className='pending'>PENDING</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        
                        <div className='gallery-header'>
                            {selectImg?.length > 0 ?
                                <div className='select-back-ctrl' onClick={()=> { setSelectImg("")}}>
                                    <span className="material-symbols-outlined">arrow_back</span>
                                    <span>View All</span>
                                </div>: <></>
                            }
                            <span className='title'>Image Gallery</span>
                        </div>
                        {selectImg?.length > 0 ?
                            <div className='committee-sel-image'>
                                <img src={selectImg} />
                            </div> :
                            <div className='committee-images'>
                                {gallery.length <= 0 ? 
                                    <div className='empty-gallery'>Currently No Images For This Committee</div> :                                
                                    <>
                                        {gallery.map((img, l)=>
                                            <div className='gallery-img-container' key={l} onClick={()=>{ setSelectImg(img.src) }}>
                                                <img src={img.src} />
                                            </div>
                                        )}
                                    </>
                                }
                            </div>
                        }
                        
                    </div>
                }
                {loading && <Loader />}
            </Rodal>
        </>
    );
}

export default CommitteeModal;