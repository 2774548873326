import { Link } from 'react-router-dom';
import background from '../assets/contact.jpg';

function ContactUs(){
    document.title = "Contact Us";
    return (
        <div className="internal-page contactus">
            <div className="contact-container">
                <div className='background_img'><img src={background} /></div>
                <p className='contact-header'>Feel free to <span>Contact Us</span> with any questions, comments, or concerns.</p>
                <div className='email-info'>
                    <div className='base-contact stack'>
                        <p>For simple inqueries email: </p>
                        <a href="mailto:stesvolunteercoordinator@gmail.com" target='_blank'>stesvolunteercoordinator@gmail.com</a>
                    </div>

                    <div className='base-contact'>
                        <p>All others reach out to</p>
                        <Link to="/aboutus#hsaboard">Our Board</Link>
                    </div> 
                </div>

                {/*<div className='content-container'>
                   <div className='base-contact'>
                        <p>For simple inqueries email: </p>
                        <a href="mailto:stesvolunteercoordinator@gmail.com" target='_blank'>stesvolunteercoordinator@gmail.com</a>
                    </div>

                    <div className='base-contact'>
                        <p>All others reach out to</p>
                        <Link to="/aboutus#hsaboard">Our Board</Link>
                    </div> 
                </div>*/}
            </div>
        </div>
    );
}

export default ContactUs;