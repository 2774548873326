import { createContext, useState } from 'react';
import { UserContextType, User } from 'datatypes/types';


const UserContext = createContext<UserContextType>({ user: null, setUser: function() { }});

function Provider({ children }: { children: any }) {
    const [user, setUser] = useState<User|null>(null);

    return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
}

export default { UserContext, Provider };