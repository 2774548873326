function Loader(){
    return (
        <div className="loading-container">
            <span className="loading-text ">Loading</span>
            <div className="lds-roller">
                <div/><div/><div/><div/>
                <div/><div/><div/><div/>
            </div>
        </div>
    );
}

export default Loader;