import { useRef } from 'react';

function UploadFiles({files, setFiles}){
    const fileUploader = useRef<HTMLInputElement>(null);

    return (
        <div className='upload-container'>
            <div className='title-row'>
                <div className='title'>Upload Files</div>

                <div className='select-btn' onClick={() => fileUploader.current?.click()}>
                    <span>Select File(s)</span>
                    <span className="icon material-symbols-outlined">upload_file</span>
                </div>
                <input type="file" multiple onChange={(e)=> setFiles(e.target.files)} ref={fileUploader} hidden />
            </div>

            <div className='file-list-container'>
                {Array.from(files).map((file:any, i) =>
                    <div className='file-item' key={i}>
                        <span className="icon material-symbols-outlined">description</span>
                        <span>{file.name}</span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default UploadFiles;