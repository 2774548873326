function RainingIcons({ version="default", colorClass=""}){
    const iconList =
    { 
        "default": [
            { "name":"edit", "left":"25%", "size":50, "delay": "2s" },
            { "name":"school", "left":"2%", "size":25, "delay": "10s" },
            { "name":"history_edu", "left":"40%", "size":80, "delay": "8s" },
            { "name":"church", "left":"10%", "size":30, "delay": "5s" },
            { "name":"cast_for_education", "left":"35%", "size":50, "delay": "1s" },
            { "name":"photo_library", "left":"8%", "size":40, "delay": "7s" },
            { "name":"auto_stories", "left":"35%", "size":70, "delay": "5s" },
            { "name":"local_library", "left":"3%", "size":25, "delay": "3s" },
            { "name":"family_star", "left":"15%", "size":50, "delay": "8s" },
            { "name":"edit", "left":"31%", "size":30, "delay": "4s" },

            { "name":"cast_for_education", "left":"35%", "size":80, "delay": "11s" },
            { "name":"history_edu", "left":"40%", "size":55, "delay": "15s" },
            { "name":"auto_stories", "left":"18%", "size":90, "delay": "13s" },
            { "name":"family_star", "left":"15%", "size":30, "delay": "16s" },
            { "name":"festival", "left":"50%", "size":90, "delay": "10s" },
        ],
        "events":[
            { "name":"festival", "left":"25%", "size":50, "delay": "2s" },
            { "name":"stadium", "left":"2%", "size":55, "delay": "6s" },
            { "name":"calendar_month", "left":"35%", "size":60, "delay": "5s" },
            { "name":"theater_comedy", "left":"90%", "size":35, "delay": "2s" },
            { "name":"palette", "left":"75%", "size":40, "delay": "3s" },
            { "name":"diversity_2", "left":"45%", "size":30, "delay": "2s" },
            { "name":"local_pizza", "left":"15%", "size":30, "delay": "5s" },
            { "name":"book_5", "left":"50%", "size":55, "delay": "0s" },

            { "name":"festival", "left":"25%", "size":50, "delay": "12s" },
            { "name":"stadium", "left":"2%", "size":25, "delay": "18s" },
            { "name":"calendar_month", "left":"35%", "size":60, "delay": "17s" },
            { "name":"theater_comedy", "left":"90%", "size":35, "delay": "14s" },
            { "name":"palette", "left":"75%", "size":40, "delay": "13s" },
            { "name":"diversity_2", "left":"55%", "size":30, "delay": "12s" },
            { "name":"local_pizza", "left":"15%", "size":30, "delay": "15s" },
            { "name":"book_5", "left":"50%", "size":55, "delay": "16s" },
        ]
    };
    
    return (
        <div className={`back-area ${colorClass}`}>
            {iconList[version].map((item,i) =>
                <span className="raining-icons material-symbols-outlined" style={{ left: item.left, fontSize: item.size, animationDelay: item.delay }} key={i} >{item.name}</span>
            )}
        </div>
    );
}

export default RainingIcons;