import "./styles/app.less";
import SiteRoutes from "Routes";

// Contexts
import ContextCompose from "context";
import UserContext from "context/userContext";
import StoreContext from "context/storeContext";

function App(){
    return(
        <ContextCompose items={[
            [UserContext],
            [StoreContext]
          ]}>
          <SiteRoutes />
        </ContextCompose>
    )
}

export default App;