import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { adminPaths } from 'Routes';

import 'rodal/lib/rodal.css';
import Rodal from 'rodal';

import userContext from "context/userContext";
import { AdminMenuType, UserContextType} from 'datatypes/types';

function AdminMenuBtn(){
    const [show, setShow] = useState(false);
    const { user } = useContext(userContext.UserContext) as UserContextType;
    
    const menu_list: AdminMenuType[] = [
        { 
            title:"Home", icon: "home", fullPath:"/", scopes:[],
            path: "/", element: ()=> { return <></>;}, menuDisplay: true
        },
        ...adminPaths,
    ];

    const scopeList = (list: AdminMenuType[]):AdminMenuType[] => {
        let ret: AdminMenuType[] = [];
        try {
            let userScopes = user?.scopes ? user.scopes : [];

            ret = list.filter((item) => {
                let intersection = userScopes.filter(x => item.scopes.includes(x));
                return item.menuDisplay == true && (item.scopes?.length == 0 || intersection.length > 0);
            });
        }
        catch(ex){
            console.log(`Scoping List: ${ex}`);
        }

        return ret;
    }

    return (
        <>
           <div className="admin-menu-btn-container" onClick={()=> { setShow((t)=> { let tmp = t; return !tmp; })}}>
                <span className="material-symbols-outlined">widgets</span>
           </div>

           <Rodal visible={show} onClose={()=> setShow(false)} width={400} height={400} showCloseButton={false} >
                <div className='admin-menu-container'>
                    {scopeList(menu_list).map((item,i) =>
                        <Link to={`${item.fullPath}`} className='admin-menu-item' key={i} onClick={()=> { setShow(false) }}>
                            <div className='menu-icon'>
                                <span className="material-symbols-outlined">{item?.icon}</span>
                            </div>
                            <span className='menu-title'>{item?.title}</span>
                        </Link>
                    )}
                </div>
           </Rodal>
        </>
    );
}

export default AdminMenuBtn;