import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DynamicForm from 'components/dynamicForm';
import ReimbursementForm from 'components/reimbursementForm';
import { rootPath } from 'util';

import background from '../assets/forms3.jpg';

function VolunteerForm(){
    document.title = 'Volunteer Form';
    const isMounted = useRef(false);
    const [searchParams] = useSearchParams();
    const [formType, setFormType] = useState("");

    const [dynamicFormInfo, setDynamicFormInfo] = useState({
        "volunteer_request": { 
            title:"Volunteer Activity Request",
            form_fields: [
                {"displayName":"Name", "name":"name", "size":"4", "required":true, "type":"input"},
                {"displayName":"Email", "name":"email", "size":"6", "required":true, "type":"email"},
                {"displayName":"Child's Name", "name":"child_name", "size":"3", "required":false, "type":"input"},
                {"displayName":"Child's Homeroom", "name":"child_homeroom", "size":"3", "required":false, "type":"input"},        
                {"displayName":"Volunteer Activity", "name":"volunteer_activity", "size":"4", "required":true, "type":"select", "options":[]},
                {"displayName":"Comments", "name":"comments", "size":"10", "required":false, "type":"textarea"}
            ]
        },
        "volunteer_hours_request":{
            title:"Log Volunteer Hours",
            form_fields:[
                {"displayName":"Name", "name":"name", "size":"4", "required":true, "type":"input"},
                {"displayName":"Email", "name":"email", "size":"6", "required":true, "type":"email"},
                {"displayName":"Child's Name", "name":"child_name", "size":"5", "required":false, "type":"input"},
                {"displayName":"Child's Homeroom", "name":"child_homeroom", "size":"5", "required":false, "type":"input"},        
                {"displayName":"Volunteer Activity", "name":"volunteer_activity", "size":"4", "required":true, "type":"select", "options":[]},
                {"displayName":"Activity Date", "name":"activity_date", "size":"3", "required":true, "type":"date"},
                {"displayName":"Number of Hours", "name":"hours", "size":"3", "required":true, "type":"number"}
            ]
        },
        "reimbursement_request":{}
    });

    const getActivites = () =>{
        try {    
            fetch(`${rootPath}/v1/api/datasource/volunteer-activities`, {
                method: "GET", headers: { "Accept": "application/json", "Content-Type":"application/json"}
            })
            .then((response) => response.json())
            .then((res)=> {
                if(res.results && res.results.length > 0) {
                    let activityLabel = res.results.map((item) => item.name);
                    
                    setDynamicFormInfo((d)=> {
                        let tmpForm = {...d};
                        tmpForm.volunteer_request.form_fields[4].options = activityLabel;
                        tmpForm.volunteer_hours_request.form_fields[4].options = activityLabel;
                        return { ...tmpForm };
                    })
                }
                else {
                    console.log(`Error Getting Volunteer Activites [EG03]: ${res.error}`);
                }
            }).catch((err) =>{
                console.log(`Error Getting Volunteer Activites [EG05]: ${err}`);
            });
            
        }
        catch(ex){
            console.log(`Error Getting Event Sub List [EG06] ${ex}`);
        }
    }

    useEffect(()=>{ 
        //if(isMounted.current){
            let form_name = searchParams.get("form");
            if(form_name && form_name in dynamicFormInfo){
                setFormType(form_name);
            }
            else {
                let formKeys = Object.keys(dynamicFormInfo);
                setFormType(formKeys[0]);
            }

            getActivites();
        //}  
        //else {
        //    isMounted.current = true;
        //}
    },[]);

    return (
        <div className="internal-page volunteer-form">
            <section className="form-container">
                <div className='background_img'><img src={background} /></div>

                {/* Form Tabs */}
                <div className='form-tabs-container'>
                    <div className={`tab-container ${(formType === "volunteer_request" ? "active":"")}`} onClick={()=> setFormType("volunteer_request")}>Volunteer Activity Request</div>
                    <div className={`tab-container ${(formType === "volunteer_hours_request" ? "active":"")}`} onClick={()=> setFormType("volunteer_hours_request")}>Log Volunteer Hours</div>
                    <div className={`tab-container ${(formType === "reimbursement_request" ? "active":"")}`} onClick={()=> setFormType("reimbursement_request")}>Reimbursement Request</div>
                </div>

                {/* Selected Tab */}
                <div className='form-content-container'>
                    <div className='img-container' />
                    <div className='form-data'>
                        {formType === "volunteer_request" &&
                            <DynamicForm title={dynamicFormInfo["volunteer_request"].title} formFields={dynamicFormInfo["volunteer_request"].form_fields} formType="volunteer_request" />
                        }

                        {formType === "volunteer_hours_request" &&
                            <DynamicForm title={dynamicFormInfo["volunteer_hours_request"].title} formFields={dynamicFormInfo["volunteer_hours_request"].form_fields} formType="volunteer_hours_request" />
                        }

                        {formType === "reimbursement_request" && <ReimbursementForm /> }
                    </div>
                </div>
            </section>
        </div>
    );
}

export default VolunteerForm;