import { useContext, useState, useEffect } from "react";
import userContext from "context/userContext";
import { UserContextType } from 'datatypes/types';
import AdminMenuBtn from "components/adminMenu";

function AdminHome(){
    const { user } = useContext(userContext.UserContext) as UserContextType;

    return (
       <div className="admin-page admin">
            <h1>Welcome <span>{user?.name}</span>,</h1>
            <h2>to the St. Elizabeth H.S.A. Parent Portal</h2>

            <div className="admin-body">
                <AdminMenuBtn />
                <span className="dashboard-btn-title">Use the admin menu button to view available admin tools.</span>
            </div>
        </div>
    );                    
}

export default AdminHome;