import { useContext, useRef, useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import tinycolor from "tinycolor2";
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { format, addDays, addMonths, subMonths } from "date-fns";

import { rootPath } from 'util';
import { EventV2, EventModalDisplay, Formations, StoreContextType } from 'datatypes/types';
import EventModal from 'components/eventModal';

import cover_photo from '../assets/grades.jpg';
import hsa_logo from "../assets/hsa-logo2.png";

import storeContext from 'context/storeContext';

const EVENT_PG_SZ = 3;

function GradeEventCtrl({ defaultClass="base", eventPage, activeDate, setActiveDate, pageEventList}) {
    const handleDateChange = (val) => { 
        let d = (val < 0 ? subMonths(activeDate,1) : addMonths(activeDate,1));
        setActiveDate(d);
    }

    const formateDate = (val) => {
        let ret = '';
        try {
            let d = (val < 0 ? subMonths(activeDate,1) : addMonths(activeDate,1));
            ret = format(d, 'MMM. yyyy');
        }
        catch(ex){
            console.log(`Error Formatting Date: ${ex}`);
        }
        return ret;
    }
    

    return (
        <div className={`grade-event-ctrl ${defaultClass}`}>
            {/* Mobile Month Nav */}
            <div className='mobile-month-ctrl-container'>
                <div className='month-ctrl month-picker' onClick={()=>handleDateChange(-1)}>
                    <span className="material-symbols-outlined">arrow_back</span>
                    <span>{formateDate(-1)}</span>
                </div>

                <div className='month-ctrl month-picker end' onClick={()=>handleDateChange(1)}>
                    <span>{formateDate(1)}</span>
                    <span className="material-symbols-outlined">arrow_forward</span>
                </div>
            </div>

            <div className='month-ctrl month-picker base' onClick={()=>handleDateChange(-1)}>
                <span className="material-symbols-outlined">arrow_back</span>
                <span>{formateDate(-1)}</span>
            </div>
            <div className='page-info'>
                <>
                    <div className='page-item min' style={{ opacity: (eventPage.current > 1 ? 1 : 0)}}>
                        <span className='page-num'>1</span>
                    </div>
                    <div className='page-divider' style={{ opacity: (eventPage.current > 1 ? 1 : 0)}}>
                        <span /><span /><span />
                    </div>
                </>
                
                <div className='page-item current'>
                    <span className={`material-symbols-outlined ${eventPage.current <= 1 ? "disabled" : ""}`} onClick={()=> pageEventList((eventPage.current - 1), EVENT_PG_SZ)}>chevron_left</span>
                    <span className='page-num'>{eventPage.current}</span>
                    <span className={`material-symbols-outlined ${eventPage.current >= eventPage.max ? "disabled" : ""}`} onClick={()=> pageEventList((eventPage.current + 1), EVENT_PG_SZ)}>chevron_right</span>
                </div>
               
                <>
                    <div className='page-divider' style={{ opacity: (eventPage.current < eventPage.max ? 1 : 0)}}>
                        <span /><span /><span />
                    </div>
                    <div className='page-item max' style={{ opacity: (eventPage.current < eventPage.max ? 1 : 0)}}>
                        <span className='page-num'>{eventPage.max}</span>
                    </div>
                </>
            </div>
            <div className='month-ctrl month-picker end base' onClick={()=>handleDateChange(1)}>
                <span>{formateDate(1)}</span>
                <span className="material-symbols-outlined">arrow_forward</span>
            </div>
        </div>
    )
}


function GradeInfo(){
    const params = useParams();
    const eventRef = useRef<HTMLDivElement>(null);
    const [animationParent] = useAutoAnimate({ duration: 400 });

    const [gradeImg, setGradeImg] = useState(hsa_logo);
    const [classInfo, setClassInfo ] = useState<Formations>();
    const [events, setEvents] = useState<Array<EventV2>>([]);
    const [showEvent, setShowEvent] = useState<EventModalDisplay>({ display: false, selectedId: null});

    const [eventPage, setEventPage] = useState({ size: 0, current: 0, max:0 });
    const [displayEvents, setDisplayEvents] = useState<Array<EventV2>>([]);

    const [activeDate, setActiveDate] = useState(new Date());

    // Loading
    const hasPagedRendered = useRef({ grade: false, events: false });
    const [loadingEvents, setLoadingEvents] = useState(true);
    const [loadingInfo, setLoadingInfo] = useState(false);

    const { getGradeImg } = useContext(storeContext.StoreContext) as StoreContextType;

    const isColorDark = (color) =>{
        try {
            if(!color) { return false; }
            color = (color.indexOf('#') === 0 ? color : `#${color}`);

            let tmpColor = tinycolor(color);
            return tmpColor.isDark();
        }
        catch(ex){
            console.log(`Error Checking Color Lightness: ${ex}`);
        }

        return false;
    }

    const pageEventList = (pageNum, pageSize) => {
        try {
            if(events.length > 0 && pageNum > 0) {
                let start = ((pageNum-1) * pageSize),
                    end = pageNum * pageSize, 
                    max = Math.ceil(events.length / pageSize);

                let tmpList = events.slice(start,end);

                if(tmpList.length > 0) {
                    setDisplayEvents(tmpList);
                    setEventPage({ size: pageSize, current: pageNum, max: max });

                    // Scroll to top
                    if(window.outerWidth < 770){ 
                        window.scrollTo({ top: eventRef.current?.offsetTop, left: 0, behavior: "smooth" });
                    }
                }
            }
        }
        catch(ex){
            console.log(`Error Setting Event Page List: ${ex}`);
        }
    }

    const getGradeInfo = () =>{
        try {
            if(params?.gradeLevel){
                setLoadingInfo(true);
                fetch(`${rootPath}/v2/api/formation/grade/${params.gradeLevel}`, {
                    method: "GET", headers: { "Accept": "application/json", "Content-Type":"application/json"}
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(res.results) {
                        setClassInfo(res.results);
                        document.title = `${res.results.title ?? ''} Page`;
                    }
                    else {
                        console.log(`Error Getting Grade Info [G00]: ${res.error}`);
                    }
                }).catch((err) =>{
                    console.log(`Error Getting Grade Info [G01]: ${err}`);
                })
                .finally(()=>{ setLoadingInfo(false); });
            }
        }
        catch(ex){
            console.log(`Error Getting Grade Info [G02] ${ex}`);
        }
    }

    const getEventsRange = () =>{
        try {
            setLoadingEvents(true);
            let start_dt = format(activeDate, 'yyyy-MM-01'),
                end_dt=format(addMonths(activeDate,1), 'yyyy-MM-01'),
                event_grade = params?.gradeLevel || "";

            fetch(`${rootPath}/v2/api/event?start_dt=${start_dt}&end_dt=${end_dt}&grade_level=${event_grade}&include_fields=id,images,title,slug,startDt&per_page=30`, {
                method: "GET", headers: { "Accept": "application/json", "Content-Type":"application/json"}
            })
            .then((response) => response.json())
            .then((res)=> {
                if(res.results) {
                    setEvents([...res.results]);
                }
                else{
                    console.log(`Error Getting Event List [M02]: ${res.error}`);
                    setLoadingEvents(false);
                }
            }).catch((err) =>{
                console.log(`Error Getting Event List [M03]: ${err}`);
                setLoadingEvents(false);
            });
        }
        catch(ex){
            console.log(`Error Getting Event Sub List [M04] ${ex}`);
        }
    }

    useEffect(()=> {
        setLoadingEvents(false); 
        if(events.length > 0){ pageEventList(1, EVENT_PG_SZ); }
        else {
            setDisplayEvents([]);
            setEventPage({ size: 0, current: 0, max: 0 });
        }
    },[events]);

    useEffect(()=>{         
        getGradeInfo();
            
        let curDate = new Date();            
        setActiveDate(curDate);

        // Get Grade Image
        let tmp_grade_img = getGradeImg(params.gradeLevel ?? "");
        if(tmp_grade_img){
            setGradeImg(tmp_grade_img);
        }
        

        //hasPagedRendered.current.grade = true;
    },[params]);

    useEffect(()=>{ 
        getEventsRange(); 

        //hasPagedRendered.current.events = true;
    },[activeDate]);

    return (
        <div className={`internal-page grade ${showEvent.display ? 'no-animate':''}`}>
            <div className='internal-landing'>
                <div className="cover-image">
                    <img src={cover_photo} alt="our grades event photo" />
                </div>
                <h1>Our Grades</h1>
            </div>
            
            <section className="grade-container">
                <div className="grade-content">
                    <h2>Explore all your {classInfo?.title} <span style={{ color: `#${classInfo?.colorCode ?? "ccc"}` }}>events</span> and <span style={{ color: `#${classInfo?.colorCode ?? "ccc"}` }}>activities</span></h2>

                    {(classInfo?.links && classInfo.links.length > 0) ?
                        <div className='link-container'>
                            {classInfo.links.map((lk, i)=>
                                <a href={lk.value} target="_blank" key={i}>{lk.key}</a>
                            )}
                        </div>
                        :<></>
                    }

                    <div className='content-container'>
                        <div className='event-container' ref={eventRef}>
                            {loadingEvents ?
                                <div className='event-display-container'>
                                    { [0,1,2].map((i) => <div className='empty-event empty-animate' key={i}/>) }
                                </div> :
                                
                                <div className='event-display-container' ref={animationParent}>
                                    {displayEvents.map((event,i)=>
                                        <div className={`event-item ${new Date(event?.startDt) < addDays(new Date(),1) ? "past":""}`} key={event.id} onClick={()=> setShowEvent({ display: true, selectedId: event.id})}>
                                            {event?.grade_list && event?.grade_list?.length > 0 && <div className="event-ribbon" style={{ backgroundColor: `${classInfo?.colorCode ?? "#ccc"}`}} />}
                                            <div className={`img-container ${event?.title ? '' : 'default-img'}`}>
                                                <div className='event-type'>{new Date(event?.startDt) < addDays(new Date(),1) ? "Previous Event" : "Upcoming Event"}</div>
                                                {(event?.images && event?.images?.length > 0) ?
                                                    <img src={event?.images[0]} alt={`${event?.title} cover photo`} /> :
                                                    <img src={hsa_logo} alt={`${event?.title} cover photo`} />
                                                }
                                                
                                            </div>

                                            <div className='event-info'>
                                                <div className='info-title'>{event?.title}</div>
                                                <div className='info-date'>
                                                    <span className="material-symbols-outlined">event</span>
                                                    <span className='date-content'>{format(new Date(event?.startDt),"MMM dd, yyyy")}</span>
                                                        
                                                    <span className="material-symbols-outlined">schedule</span>
                                                    <span className='date-content'>{format(new Date(event?.startDt),"h:mm aaaa")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {(events.length <= 0 && displayEvents.length<= 0) &&
                                        <div className='no-events-item'>
                                            <span>Sorry we currently don't have any events for this date range.</span>
                                            <span>Please view all of our events in the <Link to="/events">HSA Events Calendar</Link>.</span>
                                        </div>
                                    }
                                </div>
                            }
                        </div>

                        {!loadingInfo &&
                            <div className={`info-container ${(isColorDark(classInfo?.colorCode) ? 'dark' : "")}`} style={{ backgroundColor: `#${classInfo?.colorCode}` }}>
                                <h3>{classInfo?.title}</h3>
                                {classInfo?.leadership?.map((leader,i)=>
                                    <div className='class-leader' key={i}>
                                        <div className='leader-icon'>
                                            <span className="material-symbols-outlined">contact_mail</span>
                                        </div>

                                        <div className='leader-contact'>
                                            <span className='leader-role'>{leader.position}</span>
                                            <span className='leader-name'>{leader.name}</span>
                                            <div className='leader-email'>
                                                <span className="material-symbols-outlined">mail</span>
                                                <span>{leader.email}</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                
                                <div className='leader-img'><img src={(gradeImg ? gradeImg : hsa_logo)} alt={`${classInfo?.title} background`}/></div>
                            </div>
                        }
                        {loadingInfo && <div className='empty-info-container empty-animate' />}
                    </div>
                </div>

                <GradeEventCtrl eventPage={eventPage} activeDate={activeDate} setActiveDate={setActiveDate} pageEventList={pageEventList} />
            </section>

            <EventModal show={showEvent.display} setShow={setShowEvent} eventId={showEvent.selectedId}/>
        </div>
    );
}

export default GradeInfo;