import { useState, useEffect } from 'react';

function InvoiceTable({ invoiceList, setInvoiceList, invoiceTotal, setInvoiceTotal}){

    const addInvoiceItem = () => {
        setInvoiceList((d) => {
            let tmpList = [...d];
            tmpList.push({ qty: 1, item:"", price:"" });

            return [ ...tmpList ];
        });
    }

    const deleteInvoiceItem = (idx) => {
        setInvoiceList((d) => {
            let tmpList = [...d]; tmpList.splice(idx,1);

            return [ ...tmpList ];
        });
    }

    const updateInputField = (e, idx) =>{
        try {
            let name = e.target.name, 
                value = e.target.value;
            
            setInvoiceList((d) => {
                let tmpList = [...d];
                tmpList[idx][name] = value;
                return [ ...tmpList ];
            });
        }
        catch(ex){
            console.log(`Error updating input field: ${ex}`);
        }
    }

    useEffect(()=>{
        let tmpTotal = 0;

        invoiceList.forEach((item) => {
            if(!isNaN(item.qty) && !isNaN(item.price)) {
                tmpTotal += (Number(item.qty) * Number(item.price));
            }
        });

        setInvoiceTotal(tmpTotal);
    },[invoiceList]);

    return (
        <div className='invoice-table-container'>
            <table>
                <thead>
                    <tr className="table-header">
                        <th className='btn-cell'></th>
                        <th className="sm">Qty</th>
                        <th className="lrg">Item</th>
                        <th className="sm">Price</th>
                    </tr>
                </thead>
                <tbody>
                    {invoiceList.map((item,i) =>
                        <tr key={i}>
                            <td className='btn-cell'>
                                <span className="delete-row material-symbols-outlined" onClick={()=> deleteInvoiceItem(i)}>layers_clear</span>
                            </td>
                            <td className="sm">
                                <div className='cell-container'><input type="text" name="qty" value={item.qty} onChange={(e)=> updateInputField(e, i)}/></div>
                            </td>
                            <td className="lrg">
                                <div className='cell-container'><input type="text" name="item" value={item.item} onChange={(e)=> updateInputField(e, i)}/></div>
                            </td>
                            <td className="sm">
                                <div className='cell-container'><span>$</span> <input type="number" name="price" step="0.01" value={item.price} onChange={(e)=> updateInputField(e, i)}/></div>
                            </td>
                        </tr>
                    )}
                    <tr className="add-row">
                        <td colSpan={4}>
                            <div className="add-btn-link" onClick={addInvoiceItem}>Add Line Item <span className="material-symbols-outlined">add</span></div>
                        </td>
                    </tr>

                    <tr className="total-row">
                        <td className="total-td" colSpan={4}>
                            <div className='cell-container'>
                                <span>Total:</span>
                                <span>{invoiceTotal}</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default InvoiceTable;