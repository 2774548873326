import { useContext } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { appSessKey } from 'util';
import AdminMenuBtn from './adminMenu';

// Contexts
import userContext from "context/userContext";
import { UserContextType } from 'datatypes/types';

/* Images */
import logo_base from '../assets/hsa-logo2.png';

function AdminLayout(){
    const { user, setUser } = useContext(userContext.UserContext) as UserContextType;

    const logoutUser = () => {
        try {
            localStorage.removeItem(appSessKey);
            setUser(null);
        }
        catch(ex){
            console.log(`Error Checking User: ${ex}`);
        }
    }

    return (
        <div className='app-body'>
            <ToastContainer />
            <nav className="navbar navbar-expand-lg admin-nav">
                <Link className='navbar-brand' to="/">
                    <div className='custom-logo-container'>
                        <img src={logo_base} alt="HSA Logo" />
                    </div>
                </Link>
                <div className='admin-link-container'>
                    {!user ? <></> :
                        <>
                            <AdminMenuBtn />
                            <div className='admin-nav-links' onClick={logoutUser}>
                                <span>Logout</span>
                                <span className="material-symbols-outlined">logout</span>
                            </div>
                        </>
                    }
                </div>
            </nav>
            <div className={`access-body`}>
                <Outlet />
            </div>
        </div>
    );
}

export default AdminLayout;