import { useContext, useState, FormEvent } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { appSessKey, rootPath, parseToken } from 'util';
import Loader from 'components/loader';
import { toast } from 'react-toastify';

import userContext from "context/userContext";
import { UserContextType } from 'datatypes/types';

function Login(){
    let location = useLocation();

    document.title = 'St. Elizabeth HSA Login';

    const [loginCredentials, setLoginCredentials] = useState({ userId: "", password:"" });
    const [loadingEvents, setLoadingEvents] = useState(false);

    const { user, setUser } = useContext(userContext.UserContext) as UserContextType;

    const setCredentials = (e) => {
        try {
            let name = e.target.name,
                value = e.target.value;

            setLoginCredentials((d) => {
                return { ...d, [name]: value };
            });
        }
        catch(ex){
            console.log(`Error Setting Credentials: ${ex}`);
        }
    }

    const submitForm = async(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault(); loginUser();
    }

    const loginUser = () => {
        try {
            let sessKey = appSessKey;

            if(loginCredentials?.userId?.length <= 0 || loginCredentials?.password?.length <= 0){
                alert("Please add valid username & password");
            }
            else {
                setLoadingEvents(true);
                let postData = JSON.stringify({ ...loginCredentials });
                fetch(`${rootPath}/v1/api/auth/user_login`, {
                    method: "POST", headers: { "Accept": "application/json", "Content-Type":"application/json"},
                    body: postData
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(res.results) {
                        // Add To local storage
                        localStorage.setItem(sessKey, res.results);
                        
                        // Set App State
                        let { localUser } = parseToken(res.results);
                        setUser(localUser);
                    }
                    else {
                        console.log(`Error With Login [P00]: ${res.error}`);
                        toast.error(`${res.error}`, { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                            draggable: true, progress: undefined, theme: "light" });
                    }
                }).catch((err) =>{
                    console.log(`Error With Login [P01]: ${err}`);

                    toast.error(`${err}`, { position: "top-right",
                        autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                        draggable: true, progress: undefined, theme: "light" });
                }).finally(()=>{ setLoadingEvents(false); });
            }
        }
        catch(ex){
            console.log(`Error User Login: ${ex}`);
        }
    }

    if (user) { 
        if(location?.state?.redirect){
            return <Navigate to={`${location.state.redirect}`} replace />;
        }
        else {
            return <Navigate to="/access" replace />;
        } 
    }

    return (
        <div className="admin-page login">
            {loadingEvents && <Loader />}
            <div className="login-modal modal-back">
                <div className="login-top-container">
                    <div className="main-content header">
                        <h1>Welcome</h1>
                        <span className='name-header'>St. Elizabeth Catholic School</span>
                        <h2>HSA Site Login</h2>
                    </div>

                    <form className="main-content login-form" onSubmit={submitForm}>
                        <div className="input-container">
                            <div className="input-title">
                                <span>Username</span>
                                <span className="material-symbols-outlined">person</span>
                            </div>
                            <input type="text" className="field-input" name="userId" placeholder='enter your username' value={loginCredentials.userId} onChange={setCredentials} />
                        </div>

                        <div className="input-container">
                            <div className="input-title">
                                <span>Password</span>
                                <span className="material-symbols-outlined">lock</span>
                            </div>
                            <input type="password" className="field-input" name="password" placeholder='enter your password' value={loginCredentials.password} onChange={setCredentials} />
                        </div>

                        <div className='input-btn-container'>
                            <div className='input-btn' onClick={loginUser}>Login</div>
                            <button type="submit" className='hidden-btn' />
                        </div>
                    </form>
                </div>
                {/*<div className='login-bottom-container'>
                    <div className='go-back-text'>
                        <span>Want to return to our website?</span>
                        <Link to="/">Go Back</Link>
                    </div>
                </div>*/}
            </div>
        </div>
    );
}

export default Login;