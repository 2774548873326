import { useContext, useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from 'react-alice-carousel';

import { rootPath, validateFormField } from 'util';
import { EventV2, EventModalDisplay, StoreContextType } from 'datatypes/types';
import { format } from 'date-fns';

import home_img1 from "../assets/home1.jpg";
import home_img2 from "../assets/home2.jpg";
import home_img3 from "../assets/home3.jpg";
import event_img1 from "../assets/event1.jpg";

import parents from "../assets/parents.jpg";

import RainingIcons from 'components/rainIcon';
import EventModal from 'components/eventModal';

import storeContext from 'context/storeContext';

function Home(){
    document.title = 'St. Elizabeth HSA';
    const MAX_UPCOMING = 11, EVENT_PG_SZ = 4;
    const containerRef = useRef<HTMLDivElement>(null);
    const gradeCarouselRef = useRef<AliceCarousel>(null);
    const welcomeSectionRef = useRef<HTMLDivElement>(null);
    
    const [upcomingEvents, setUpcomingEvents] = useState<Array<EventV2>>([]);
    const [displayEvents, setDisplayEvents] = useState<Array<EventV2>>([]);
    const [eventPage, setEventPage] = useState({ size: 0, current: 0, max:0 });

    const [showEvent, setShowEvent] = useState<EventModalDisplay>({ display: false, selectedId: null});
    const [loadingUpcomingEvents, setLoadingUpcomingEvents] = useState(false);

    const { grades, checkGrades, homeImages, checkHomeImages, loader } = useContext(storeContext.StoreContext) as StoreContextType;
    
    const buildCarouselItems = () => {
        let retItems: any[] = [];
        try {
            if(loader?.grades){
                retItems = [0,1,2,3].map((_blank,i) => (
                    <div className='loading-item-container empty-animate' key={i}/>
                ));
            }
            else {
                let gradeFillers: number[] = [];
                for(let i=(grades.length - 3); i < 0; i++) {
                    gradeFillers.push(1);
                }

                retItems = [...grades.map((grade,i) => (
                    <Link to={`/grades/${grade.slug}`} className='grade-item-container' key={i}>
                        <div className='img-container'>
                            {grade?.image?.src ?
                                <img src={grade?.image?.src} alt={` ${grade.title} link to full page`}/>:
                                <div />
                            }
                        </div>
                        <p>{grade.title}</p>
                    </Link>
                    )),
                    ...gradeFillers.map((_blank,i) => (
                        <div className='empty-item-container' key={i}/>
                    ))
                ];

                
            }
        }
        catch (ex) {
            console.log(`Error building carousel list: ${ex}`);
        }

        return retItems;
    }

    const slideCarousel = (dir) => {
        try {
            if (dir < 0 && gradeCarouselRef?.current) {
                gradeCarouselRef.current.slidePrev();
            }
            else if (dir > 0 && gradeCarouselRef?.current) {
                gradeCarouselRef.current.slideNext();
            }
        }
        catch (ex) {
            console.log(`Error sliding carousel: ${ex}`)
        }
    }

    const formatDate = (date, type) => {
        let ret = "";
        try {
            if(validateFormField(date, "date", true)){
                date = new Date(date);
                switch(type) {
                    case "day":
                        ret = date.getDate();
                        break;
                    case "month":
                        let monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                        ret = monthList[date.getMonth()];
                        break;
                    case "time":
                        let h = date.getHours(), m = date.getMinutes();
                        ret = `${h > 12 ? (h - 12) : h}:${m < 10 ? '0' + m : m} ${h < 12 ? "am" : "pm"}`
                        break;
                }
            }
        }
        catch (ex) {
            console.log(`[Error] formatting data: ${ex}`);
        }

        return ret;
    }

    const pageEventList = (pageNum, pageSize) => {
        try {
            if(upcomingEvents.length > 0 && pageNum > 0) {
                let start = ((pageNum-1) * pageSize),
                    end = pageNum * pageSize, 
                    max = Math.ceil(upcomingEvents.length / pageSize);

                let tmpList = upcomingEvents.slice(start,end);

                if(tmpList.length > 0) {
                    setDisplayEvents(tmpList);
                    setEventPage({ size: pageSize, current: pageNum, max: max });
                }
            }
        }
        catch(ex){
            console.log(`Error Setting Event Page List: ${ex}`);
        }
    }

    const pageScroll = (ref) => {
        try {
            let pageLoc = ref.current.offsetTop - 71;
            if (!pageLoc || pageLoc < 0) {
                pageLoc = 0;
            }

            window.scrollTo({ top: pageLoc, left: 0, behavior: "smooth" });
        }
        catch (ex) {
            console.log(`Error moving to section ${ex}`);
        }
    }

    const getUpcomingEventsList = () =>{
        try {
            let nextDate = format(new Date(), 'yyyy-MM-dd');
            setLoadingUpcomingEvents(true);
            fetch(`${rootPath}/v2/api/event??per_page=${MAX_UPCOMING}&start_dt=${nextDate}&include_fields=id,title,startDt,endDt`, {
                method: "GET", headers: { "Accept": "application/json", "Content-Type":"application/json"}
            })
            .then((response) => response.json())
            .then((res)=> {
                if(res.results) {
                    setUpcomingEvents(res.results);
                }
                else {
                    console.log(`Error Getting Event List [M02]: ${res.error}`);
                }
            }).catch((err) =>{
                console.log(`Error Getting Event List [M03]: ${err}`);
            })
            .finally(()=> { setLoadingUpcomingEvents(false); });
        }
        catch(ex){
            console.log(`Error Getting Event Sub List [M04] ${ex}`);
        }
    }

    useEffect(()=> { 
        checkGrades(); 
        getUpcomingEventsList();
        checkHomeImages();
    },[]);

    useEffect(()=> {
        setLoadingUpcomingEvents(false); 
        if(upcomingEvents.length > 0){ pageEventList(1, EVENT_PG_SZ); }
    },[upcomingEvents]);

    return (
        <div className="home-container">
            <div className="landing-container" ref={containerRef}>
                <div className="landing-title">
                    <span>St. Elizabeth Catholic School</span>
                    <h1>Home & School Association</h1>
                    <p>Together we support our school and parish through</p>
                    <p><b>Faith</b>, <b>Tradition</b>, <b>Scholarship and Service</b></p>
                </div>

                <div className="landing-img-container">
                    {homeImages?.length <= 0 ?
                        <>
                            <div className="home-img">
                                <div className={`empty sz-2 ${loader?.homeImages ? "empty-animate-light" : ""}`} />
                            </div>

                            <div className="home-img">
                                <div className={`empty sz-1 ${loader?.homeImages ? "empty-animate-light" : ""}`} />
                                <div className={`empty sz-1 ${loader?.homeImages ? "empty-animate-light" : ""}`} />
                            </div>
                        </> :
                        <>
                            <div className="home-img">
                                <img src={`${homeImages[0]}/m/1024x0`} className="sz-2" alt="St. Elizabeth School Children" />
                            </div>

                            <div className="home-img">
                                <img src={`${homeImages[1]}/m/1024x0`} className="sz-1" alt="St. Elizabeth School Children" />
                                <img src={`${homeImages[2]}/m/1024x0`} className="sz-1" alt="St. Elizabeth School Children" />
                            </div>
                        </>
                    }
                </div>

                {/* Object Animation */}
                <RainingIcons />
            </div>

            <section className='welcome-section' ref={welcomeSectionRef}>
                <div className='section-drop' onClick={() => pageScroll(welcomeSectionRef)}>
                    <span className="material-symbols-outlined">arrow_downward</span>
                </div>
                <div className='welcome-container'>
                    <h1>Welcome Parents</h1>
                    <p className='welcome-text'>The Home & School Association (HSA) is a volunteer, parent-run organization that serves as a communication liaison between home and school life.  We strive to facilitate,  provide supportive structures for school events, and assist administration in reaching goals that support the mission of St. Elizabeth School.</p>
                    
                    <div className='gradelist-container'>
                        <div className="ctrl-item left" onClick={() => slideCarousel(-1)}><span className="material-symbols-outlined">chevron_left</span></div>

                        <div className='alice-carousel-container'>
                            <AliceCarousel items={buildCarouselItems()}
                                autoPlayInterval={7000} disableDotsControls disableButtonsControls mouseTracking infinite
                                responsive={{ 0: { items: 1 }, 600: { items: 2 }, 1024: { items: 3 } }} ref={gradeCarouselRef} />
                        </div>
                        <div className="ctrl-item left" onClick={() => slideCarousel(1)}><span className="material-symbols-outlined">chevron_right</span></div>  
                    </div>

                    <div className='back-img-container'>
                        <img src={parents} alt="welcome to our parents" />
                    </div>
                </div>
            </section>

            <section className='quicklinks-section'>
                <div className='section-title'>
                    <h2>HSA Events</h2>
                    <Link to="/events" className='more-btn-link'>See All HSA Events</Link>
                </div>

                <div className='quicklink-container'>
                    <div className='quicklink-grid sz-45'>
                        <div className='quicklink-img-container'>
                            <img src={event_img1} alt="upcoming events cover image" />
                        </div>
                        <div className='img-content'>
                            <h1>United In Faith And Community</h1>
                            <Link to="/events" className='more-btn-link'>See More HSA Events</Link>
                            <RainingIcons version="events" colorClass="white"/>
                        </div>
                    </div>
                    <div className='quicklink-grid sz-55 list-col'>
                        <div className='events-list'>
                            {displayEvents.map((event, i) =>
                                <div className='event-item' key={i}>
                                    <div className='item-date'>
                                        <span className='day'>{formatDate(event.startDt, "day")}</span>
                                        <div className='month'>{formatDate(event.startDt, "month")}</div>
                                    </div>
                                    <div className='item-info'>
                                        <span className='time'>{formatDate(event.startDt, "time")}</span>
                                        <span className='title'>{event.title}</span>
                                    </div>
                                    <span className="item-see-more material-symbols-outlined" onClick={()=> setShowEvent({ display: true, selectedId:event.id})}>expand_circle_right</span>
                                </div>
                            )}
                            {eventPage.current >= eventPage.max && 
                                <Link to="/events" className='event-item see-all'><div className='all-btn'>See More HSA Events</div></Link>
                            }

                            {upcomingEvents.length > EVENT_PG_SZ &&
                                <div className='page-info'>
                                    <>
                                        <div className='page-item min' style={{ opacity: (eventPage.current > 1 ? 0.2 : 0)}}>
                                            <span className='page-num'>1</span>
                                        </div>
                                        <div className='page-divider' style={{ opacity: (eventPage.current > 1 ? 0.2 : 0)}}>
                                            <span /><span /><span />
                                        </div>
                                    </>
                                    
                                    <div className='page-item current'>
                                        <span className={`material-symbols-outlined ${eventPage.current <= 1 ? "disabled" : ""}`} onClick={()=> pageEventList((eventPage.current - 1), EVENT_PG_SZ)}>chevron_left</span>
                                        <span className='page-num'>{eventPage.current}</span>
                                        <span className={`material-symbols-outlined ${eventPage.current >= eventPage.max ? "disabled" : ""}`} onClick={()=> pageEventList((eventPage.current + 1), EVENT_PG_SZ)}>chevron_right</span>
                                    </div>
                                
                                    <>
                                        <div className='page-divider' style={{ opacity: (eventPage.current < eventPage.max ? 0.2 : 0)}}>
                                            <span /><span /><span />
                                        </div>
                                        <div className='page-item max' style={{ opacity: (eventPage.current < eventPage.max ? 0.2 : 0)}}>
                                            <span className='page-num'>{eventPage.max}</span>
                                        </div>
                                    </>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <EventModal show={showEvent.display} setShow={setShowEvent} eventId={showEvent.selectedId}/>

                <div className="quicklinks-disclaimer">
                    Only HSA events are listed on this calendar. To access the complete St. Elizabeth School calendar, please visit <a href='https://stelizabethschoolmd.org/calendar' target="_blank">St. Elizabeth's School Calendar</a>
                </div>
            </section>
        </div>
    );
}

export default Home;