import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import cover_photo from '../assets/volunteer2.jpg';
import content_photo from '../assets/forms3.jpg';

function ReimbursementLetter(){
    document.title = 'Reimbursement Letter';
    const stepScrollRef = useRef<HTMLDivElement>(null);
    const stepsSectionRef = useRef<HTMLDivElement>(null);
    const [ctrlStatus, setCtrlStatus] = useState({ "prev": true, "next": false});

    {/*<Link to="/volunteers/forms?form=reimbursement_request">HSA Reimbursement and Check Request Form</Link>*/}

    const steps = [
        { "content": <div className='step-info'>
            Please use the online google form for all reimbursements
            <span>(scanned receipts are required for submission)</span>
            <a href="https://forms.gle/p6k1ktsEVgnsE6u16" target="_blank" onClick={()=> { return confirm('You will now be navigated to an external site via a new tab. Please switch tabs to return to our website.');}}>HSA Reimbursement and Check Request Form</a>.
            Also located on the St. Elizabeth website under PARENTS {'>'} Resources.
         </div>},
         {"content":<div className='step-info'>All requests should go through the HSA Treasurer for the approval process.  Please do not submit any reimbursement requests directly to the Finance Director or Mrs. Penny</div>},
         {"content":<div className='step-info'>HSA Committee Chairs cannot sign contracts with vendors without approval.  Please work with your HSA President & Vice President to secure approval from Mrs. Penny and the Parish Finance Director before signing any contracts.</div>},
         {"content":<div className='step-info'>If you need a check made out to a vendor, you will need to get prior approval. Please contact your HSA team and the HSA Treasurer. Once approved, you will submit the above google form and a check will be prepared for you. Please allow at least one to two weeks for processing.</div>},
    ];

    const controlSlider = (ref, dir) => {
        try {
            let offsetWidth = ref.current.offsetWidth,
                scrollWidth = ref.current.scrollWidth;

            if(scrollWidth > offsetWidth){
                let scrollSz = (offsetWidth <= 770 ? (offsetWidth - 12) : ((scrollWidth - offsetWidth) / 2)),
                    newScrollLeft = ref.current.scrollLeft;

                if(dir === "prev"){
                    newScrollLeft = ref.current.scrollLeft - scrollSz;
                }
                else if(dir === "next"){
                    newScrollLeft = ref.current.scrollLeft + scrollSz;
                }

                ref.current.scrollLeft = newScrollLeft;
                setCtrlStatus({ "prev": disableCtrl(ref, newScrollLeft, "prev"), "next":disableCtrl(ref, newScrollLeft, "next") });
            }
        }
        catch(ex){
            console.log(`Error Controlling Slider: ${ex}`);
        }
    }

    const disableCtrl = (ref, newScrollLeft, dir) => {
        try {
            if(dir === "prev"){
                return newScrollLeft <= 0;
            }
            else if(dir === "next"){
                return (ref.current.offsetWidth + newScrollLeft) >= ref.current.scrollWidth
            }
        }
        catch(ex){
            console.log(`Disabling Controlling Slider: ${ex}`);
        }

        return false;
    }

    const pageScroll = (ref) => {
        try {
            let pageLoc = ref.current.offsetTop;
            if(!pageLoc || pageLoc < 0){ 
                pageLoc = 0;
            }

            window.scrollTo({ top: pageLoc, left: 0, behavior: "smooth" });
        }
        catch(ex){
            console.log(`Error moving to section ${ex}`);
        }
    }

    return (
        <div className="internal-page reimbursement">
            <div className='internal-landing'>
                <div className="cover-image">
                    <img src={cover_photo} alt="Reimbursement Letter photo" />
                </div>
                <h1 className='small-title'>Reimbursement Letter</h1>
            </div>

            <section className="reimbursement-section">
                <div className='base-text-container'>
                    <div className='text-image-container'>
                        <img src={content_photo} alt="reimbursement text cover image" />
                    </div>
                    <h1>HSA Reimbursement Procedures</h1>
                    <p>
                        <b>All purchases</b> must be approved by HSA & Administration prior to payment. 
                        In order to receive reimbursement, please do not pay for or purchase anything for your committee 
                        until it is approved by the HSA President. If you need to purchase an item/items for your committee 
                        please contact an HSA officer. He/She will contact the Principal for approval and get back to you.
                    </p>
                    <div className='jump-link' onClick={()=> pageScroll(stepsSectionRef)}>
                        <span className="material-symbols-outlined">stat_minus_2</span>
                        <span>Jump to reimbursement steps</span>
                        <span className="material-symbols-outlined">stat_minus_2</span>
                    </div>
                </div>
            </section>

            <section className="steps-section" ref={stepsSectionRef}>
                <div className='steps-header'>
                    <h1>Steps To Reimbursement</h1>
                    <div className='step-ctrl'>
                        <div className={`ctrl-arrow back ${ctrlStatus.prev ? "disable":""}`} onClick={()=> controlSlider(stepScrollRef, "prev")}>
                            <span className="material-symbols-outlined">chevron_left</span>
                        </div>
                        
                        <div className={`ctrl-arrow forward ${ctrlStatus.next ? "disable":""}`} onClick={()=> controlSlider(stepScrollRef, "next")}>
                            <span className="material-symbols-outlined">chevron_right</span>
                        </div>
                    </div>
                </div>
                <div className='steps-outer-container' ref={stepScrollRef}>
                    <div className='steps-container'>
                        {steps.map((step,i) => 
                            <div className='step-card' key={i}>
                                <div className='card-number'>{(i+1)}</div>
                                <div className='card-info'>{step.content}</div>
                            </div>
                        )}
                    </div>
                </div>

                <div className='steps-notes'>
                    <p><span>NOTE:</span> The HSA cannot reimburse amounts greater than $250.00 per check. If an approved personal reimbursement amount exceeds $250.00 please contact the HSA Treasurer.</p>
                    <p className='thank-you-note'>Thank you for your hard work and cooperation</p>
                </div>
            </section>
        </div>
    );
}

export default ReimbursementLetter;