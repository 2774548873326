import { useContext, useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { SubNav, UserContextType, StoreContextType} from 'datatypes/types';

import userContext from "context/userContext";
import storeContext from 'context/storeContext';

/* Images */
import logo_base from '../assets/hsa-logo2.png';
import grades_img from '../assets/classroom.jpg';
import volunteer_img from '../assets/volunteer.jpg';

/* Variables */
const baseMenuInfo = {
    "grades":{
        "title":"Our Grades", "basepath":"/grades/", "image":grades_img,
        "sub_nav":[]
    },
    "volunteers":{
        "title":"Our Volunteers", "basepath":"/volunteers", "image":volunteer_img,
        "sub_nav":[
            { "id":"/reimbursement-letter", "title":"Reimbursement Letter", "sub_path":"", "sub_text":"Volunteer reimbursement first steps", "icon":"assignment", "external":false},
            { "id":"/", "title":"Volunteer Form", "sub_path":"", "sub_text":"", "icon":"diversity_3", "external_link":"https://www.saintsvolunteer.com", "external":true},
        ]
    }
};


function MobileNav({ localSubMenu, isOpen, setIsOpen }) {
    const [subNavDisplay, setSubNavDisplay] = useState<Object>({});
    const { user } = useContext(userContext.UserContext) as UserContextType;

    const getSubNav = (name) => {
        if(localSubMenu && name in localSubMenu ){
            return localSubMenu[name].sub_nav;
        }
        return [];
    }

    const toggleSubNav = (name) => {
        if(name in subNavDisplay){
            setSubNavDisplay((d) => { return {...d, [name]: !d[name] }})
        }
    }

    useEffect(()=>{
        let subKeys = Object.keys(localSubMenu), subNavDisplayTmp = {};
        subKeys.forEach((key) => { subNavDisplayTmp[key] = false; });

        setSubNavDisplay(subNavDisplayTmp);
    },[localSubMenu])

    return (
        <div className={`m-sidenav-container ${(isOpen ? "active": "")}`}>
            <div className="m-nav-close" onClick={() => setIsOpen(false)}><span className="close-nav" /></div>
            <div className="m-sidenav-section">
                <div className='m-sidenav-sub-section'>
                    {/*!user ?
                        <NavLink to="/access/login" className='m-login-link'>
                            <div className='login-container'>
                                <span className="material-symbols-outlined">person</span>
                                <span className="text">Login</span>
                            </div> 
                        </NavLink>:
                        <NavLink to="/access" className='m-user-link'>
                            <span className="material-symbols-outlined">account_circle</span>
                            <span>{user.name}</span>
                        </NavLink>
                    */}
                </div>
                <NavLink className="m-sidenav-link" to="/" onClick={() => setIsOpen(false)}>Home</NavLink>
                <NavLink className="m-sidenav-link" to="/aboutus" onClick={() => setIsOpen(false)}>About Us</NavLink>
                <NavLink className="m-sidenav-link" to="/committees" onClick={() => setIsOpen(false)}>Committees</NavLink>
                <div className='m-sidenav-link m-subnav-container'>
                    <div className='m-header'>
                        <NavLink to="/grades" onClick={() => setIsOpen(false)}>Grades</NavLink>                       
                        <span className="material-symbols-outlined" onClick={()=> toggleSubNav("grades")}>{subNavDisplay["grades"] ? "remove" : "add"}</span>
                    </div>
                    <div className={`m-nav-subnav ${subNavDisplay["grades"] ? 'open' : ''}`}>
                        {getSubNav("grades").map((item,i) =>
                            <NavLink to={`/grades/${item.id}`} className='m-subnav-item' key={i} onClick={() => setIsOpen(false)}>{item.title}</NavLink>
                        )}
                    </div>
                </div>
                <NavLink className="m-sidenav-link" to="/volunteers" onClick={() => setIsOpen(false)}>Volunteers</NavLink>
                <NavLink className="m-sidenav-link" to="/events" onClick={() => setIsOpen(false)}>HSA Events</NavLink>
                <NavLink className="m-sidenav-link mini-link" to="/contactus" onClick={() => setIsOpen(false)}>Contact Us</NavLink>
            </div>
        </div>
    );
}

function Header({ navChange }){
    const [showSub, setShowSub] = useState(false);
    const [subNavInfo, setSubNavInfo] = useState<SubNav>(null);
    const [subMenuInfo, setSubMenuInfo] = useState<Object>(baseMenuInfo);
    const [subNavOpen, setSubNavOpen] = useState(false);

    const subNavRef = useRef<HTMLDivElement>(null);
    const { user } = useContext(userContext.UserContext) as UserContextType;
    const { grades, checkGrades } = useContext(storeContext.StoreContext) as StoreContextType;

    const toggleSubNav = (e, name, status, override=false) =>{
        const subNavBounds = subNavRef.current?.getBoundingClientRect(), subOffset = 1;
        
        if(override ||status || !subNavBounds || 
            (e.clientX + subOffset) < subNavBounds.left || 
            (e.clientX + subOffset) > subNavBounds.right || 
            (e.clientY + subOffset) < subNavBounds.top || 
            (e.clientY + subOffset) > subNavBounds.bottom 
        ){
            if(status && (name in subMenuInfo)) { 
                setSubNavInfo(subMenuInfo[name]);
            }
            else { 
                setSubNavInfo(null);
            }
            setShowSub(status);
        }
    }

    const setSidebarDisplay = (status) => {
        document.body.classList.toggle('noscroll', status);
        setSubNavOpen(status);
    }

    useEffect(()=> { checkGrades(); },[]);
    useEffect(()=> { 
        if(grades.length > 0){
            setSubMenuInfo((d)=> {
                let tmpGrades = { ...d["grades"] };
                tmpGrades.sub_nav = grades.map((item) => {
                    return { id: item.slug, title: item.title, sub_text:"Visit Our Grade", icon: item.icon };
                });

                return { ...d, "grades":tmpGrades };
            });
        }
    },[grades]);

    return (
        <>
            <MobileNav localSubMenu={subMenuInfo} isOpen={subNavOpen} setIsOpen={setSidebarDisplay}/>
            <nav className="navbar navbar-expand-lg">
                <div className='top-nav'>
                    <p>St. Elizabeth Catholic School - Rockville, MD.</p>
                </div>
                <div className={`main-nav ${(navChange ? "lock":"")}`}>
                    <Link className='navbar-brand' to="/">
                        <div className='custom-logo-container'>
                            <img src={logo_base} alt="HSA Logo" />
                        </div>
                    </Link>

                    {/* Mobile Nav Toggler */}
                    <button className="navbar-toggler" type="button" aria-label="Toggle navigation" onClick={() => setSidebarDisplay(true)}>
                        <div className='toggler-container'>
                            <span className="material-symbols-outlined">menu_open</span>
                            <span className='toggler-text'>Menu</span>
                        </div>
                    </button>

                    <div className='navbar-collapse'>
                        <div className="navbar-nav nav-top">                            
                            <NavLink className="nav-link sub-link" to="/contactus">Contact Us</NavLink>
                            
                            <a href="https://stelizabethschoolmd.org/" target="_blank" className='nav-ribbon' onClick={()=> { return confirm('You will now be navigated to an external site via a new tab. Please switch tabs to return to our website.');}}>
                                <div className='content'>
                                    <p>Visit Our</p>
                                    <p>School</p>
                                </div>
                            </a>
                        </div>
                        <div className="navbar-nav nav-bottom">
                            <NavLink className="nav-link" to="/">Home</NavLink>
                            <NavLink className="nav-link" to="/aboutus">About Us</NavLink>
                            <NavLink className="nav-link" to="/committees">Committees</NavLink>
                            <NavLink className="sub-nav-link nav-link" to="/grades" onMouseEnter={(e)=> toggleSubNav(e,"grades", true)} onMouseLeave={(e)=> toggleSubNav(e,"parent", false)}>
                                Grades
                                <span className="sub-nav-caret material-symbols-outlined">arrow_drop_down</span>
                            </NavLink>                           
                            <NavLink className="sub-nav-link nav-link" to="/volunteers">Volunteers</NavLink>                            
                            <NavLink className="nav-link" to="/events">HSA Events</NavLink>

                            {/*!user ?
                                <NavLink to="/access/login" className='login-link'>
                                   <div className='login-container'>
                                        <span className="material-symbols-outlined">person</span>
                                        <span className="text">Login</span>
                                    </div> 
                                </NavLink>:
                                <NavLink to="/access" className='user-link'>
                                    <span className="material-symbols-outlined">account_circle</span>
                                    <span>{user.name}</span>
                                </NavLink>
                            */}
                        </div>
                    </div>
                </div>

                {/* Sub Nav */}
                {showSub && (
                    <div className={`subNav ${(navChange ? "lock":"")}`} onMouseLeave={(e)=> toggleSubNav(e,"", false)} ref={subNavRef}>
                        {(subNavInfo === null) ? 
                            <div className='no-data' /> :
                            <div className='subNav-content'>
                                <div className='link-container'>
                                    <div className='subNav-title'>{subNavInfo.title}</div>
                                    <div className='subNav-items'>
                                        {subNavInfo?.sub_nav?.length == 0 && 
                                            <div className='loading-message'><h1>More Information On The Way...</h1></div>
                                        }

                                        {subNavInfo.sub_nav.map((item,i) => (
                                            <div className='subNav-link-container' key={i}>
                                                {item.external ? 
                                                    <a href={item.external_link} target='_blank' className='sub-nav-item' key={i} onClick={(e)=> toggleSubNav(e,"", false, true)}>
                                                        <span className="material-symbols-outlined">{item?.icon || "radio_button_unchecked"}</span>
                                                        <div className='item-text'>
                                                            <p className='sub-nav-title'>{item?.title}</p>
                                                            <p className='sub-nav-subtext'>{item?.sub_text}</p>
                                                        </div>
                                                    </a> :
                                                    <NavLink to={`${subNavInfo.basepath}${item.id}`} className='sub-nav-item' key={i} onClick={(e)=> toggleSubNav(e,"", false, true)}>
                                                        <span className="material-symbols-outlined">{item?.icon || "radio_button_unchecked"}</span>
                                                        <div className='item-text'>
                                                            <p className='sub-nav-title'>{item?.title}</p>
                                                            <p className='sub-nav-subtext'>{item?.sub_text}</p>
                                                        </div>
                                                    </NavLink>
                                                }
                                            </div>                                            
                                        ))}
                                    </div>
                                </div>
                                <div className='img-container'>
                                    <img src={subNavInfo?.image} alt={`${subNavInfo?.title} sub navigation`} />
                                </div>
                            </div>
                        }
                    </div>
                )}
            </nav>
        </>
    );
}

export default Header;