import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Footer from './footer';
import Header from './header';

/* UI Variables */
let centralNavState = false;

function Layout(){
    const [navChange, setNavChange] = useState(centralNavState);

    const listenToScroll = () => {
        try {
            if(typeof window !== undefined){
                const height = 82;

                if(window.scrollY <= height && centralNavState) { 
                    centralNavState = false;
                    setNavChange(false);                    
                }
                else if(window.scrollY > height && !centralNavState){
                    centralNavState = true;
                    setNavChange(true);
                }
            }
        }
        catch(ex){
            console.log("[Error] listening to Scroll: ", ex);
        }
    }

    useEffect(() => { 
        document.addEventListener('scroll', listenToScroll); 
        return () => {
            document.removeEventListener("scroll", listenToScroll)
        }
    },[]);

    return (
        <div className='app-body'>
            <ToastContainer />
            <Header navChange={navChange}/>
            <div className={`page-body ${navChange ? "lock" : ""}`}>
                <Outlet />
            </div>
            <Footer />
        </div>
    );
}

export default Layout;