import { User } from "datatypes/types";

export const rootPath = ( window.location.href.indexOf("localhost") > -1  ? "http://localhost:8080" : "");
export const appSessKey = "st_elizabeth|hsa|usr";
export const URL_REGEX = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/gm
export const MAX_GALLERY_PULL = 3;

export const randomQuote = () => {
    let ret = "";
    try {
        let quoteList = ["Learning is a lifelong process" ,"There is no end to education", "Research is creating a new knowledge",
        "I am still learning","Curiosity is the wick in the candle of learning", "Develop a passion for learning", "He who laughs most, learns best"];

        let randomNum = Math.floor(Math.random() * ((quoteList.length - 1) - 0 + 1) + 0);
        ret = quoteList[randomNum];
    }
    catch(ex){
        console.log(`Error Getting Random Quote ${ex}`);
    }

    return ret;
}

export function parseToken(token){
    var localUser: User | null = null, isExpired: Boolean | null = true; 
    try {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace('-', '+').replace('_', '/');
        let jsStr = window.atob(base64);

        localUser = JSON.parse(jsStr);
        if(localUser && localUser.expDt){
            let expirationDate = new Date(localUser.expDt);
            isExpired = (localUser && expirationDate.getTime() < Date.now());
        }
    }
    catch(ex){
        console.log("[Error] parsing token: ",ex);
    }

    return { localUser, isExpired }
}

export function loadFullGallery(_id, type, image_count, setGallery, srt_desc=true){
    try {
        if(_id && type && image_count > 0){
            let total_pages = Math.ceil(image_count / MAX_GALLERY_PULL);
            for(let i =0; i < total_pages; i++){
                fetch(`${rootPath}/v2/api/photo/${type}/${_id}?page=${(i+1)}&page_size=${MAX_GALLERY_PULL}`, {
                    method: "GET", headers: { "Accept": "application/json", "Content-Type":"application/json"}
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(res.results) {
                        setGallery((d)=>{
                            let tmp = [...d];
                            let tmp_list = tmp.concat(res.results).sort((a,b) => {
                                return (srt_desc ? 
                                    b.create_date - a.create_date : 
                                    a.create_date - b.create_date
                                );
                            });

                            return tmp_list;
                        });
                    }
                    else {
                        console.log(`Error Getting Gallery Page: ${i} [E02]: ${res.error}`);
                    }
                }).catch((err) =>{
                    console.log(`Error Getting Formation Page: ${i} [E03]: ${err}`);
                }).finally(()=>{ });
            }
        }        
    }
    catch(ex){
        console.log(`Loading Full Gallery: ${ex}`);
    }
}

/* Form Validation */
export function validateForm(fields, data){
    let ret:Array<string> = [];
    try {
        fields.forEach((field, i) =>{
            let validStatus = validateFormField(data[field.name], field.type, field.required, field?.options ?? []);
            if(!validStatus){
                ret.push(field.name);
            }
        });
    }
    catch(ex){
        console.log(`Validating Form: ${ex}`);
        ret.push("error");
    }
    return ret;
}

export function validateFormField(value, type, required:boolean=true, options:Array<string>=[]){
    let ret = false;
    try {
        switch(type){
            case "input":
            case "textarea":
            case "color_picker":
                ret = validateInput(value, required);
                break;
            case "select":
                ret = validateSelect(value, required, options);
                break;
            case "multi-select":
                ret = validateMultiSelect(value, required, options);
                break;
            case "email":
                ret = validateEmail(value, required);
                break;
            case "number":
                ret = validateNumber(value, required);
                break;
            case "date":
                ret = validateDate(value, required);
                break;
            case "file-upload":
            case "invoice-table":
            case "image_upload":
            case "sub_form_list":
                ret = validateList(value, required);
                break;
            case "link_pill":
                ret = validateKeyValue(value, required);
            default:
                break;
        }
    }
    catch(ex){
        console.log(`Validating Form Field: ${ex}`);
    }
    return ret;
}

function validateList(value, required=true){
    let ret = false;
    try {
        ret = !required || value.length > 0;
    }
    catch(ex){
        console.log(`Validating List: ${ex}`);
    }
    return ret;
}

function validateKeyValue(value, required=false){
    let ret = false;
    try {
        if(!required){
            return true;
        }
        
        if(value){
            ret = true;
            for(let i=0; i < value.length; i++){
                let item = value[i];

                if(item?.key?.length <= 1 || item?.value?.length < 3){
                    ret = false;
                    break;
                }
            }
        }
    }
    catch(ex){
        console.log(`Validating List: ${ex}`);
    }
    return ret;
}

function validateDate(value, required=true){
    let ret = false;
    try {
        ret = !required || !isNaN((new Date(value)).getTime());
    }
    catch(ex){
        console.log(`Validating Date: ${ex}`);
    }
    return ret;
}

function validateNumber(value, required=true){
    let ret = false;
    try {
        ret = !required || !isNaN(value);
    }
    catch(ex){
        console.log(`Validating Number: ${ex}`);
    }
    return ret;
}

function validateInput(value, required=true){
    let ret = false;
    try {
        ret = !required || (value?.length > 0);
    }
    catch(ex){
        console.log(`Validating Input: ${ex}`);
    }
    return ret;
}

function validateSelect(value:string, required:boolean=true, options:Array<string>=[]){
    let ret = false;
    try {
        ret = !required || (value?.length > 0 && options.length > 0 && (options.indexOf(value) >= 0));
    }
    catch(ex){
        console.log(`Validating Select: ${ex}`);
    }
    return ret;
}

function validateMultiSelect(value:string, required:boolean=true, options:Array<string>=[]){
    let ret = false;
    try {
        ret = !required || (value?.length > 0);
    }
    catch(ex){
        console.log(`Validating Multi Select: ${ex}`);
    }
    return ret;
}

function validateEmail(value, required=true){
    let ret = false;
    try {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        ret = !required || (value?.length > 0 && value.match(validRegex));
    }
    catch(ex){
        console.log(`Validating Email: ${ex}`);
    }
    return ret;
}