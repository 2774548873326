import { Link } from "react-router-dom";

function NoMatch(){
    return (
        <div className="no-match-page">
            <div className="text-container">
                <h1>404</h1>
                <p>Sorry this page was not found.</p>

                <Link to="/" className="return-link">
                    <span>Please return to our home page</span>
                </Link>
            </div>
        </div>
    );
}

export default NoMatch;