import "./styles/app.less";
import { Routes, Route, Navigate, useLocation  } from "react-router-dom";
import { useEffect, useContext } from 'react';

import AdminLayout from "components/adminLayout";
import Layout from './components/layout';

import AboutUs from "pages/aboutus";
import ContactUs from "pages/contactus";
import Events from "pages/events";
import Grades from "pages/grades";
import GradeInfo from "pages/grade";
import Home from "pages/home";
import Login from "pages/login";
import NoMatch from "pages/nomatch";
import Committees from "pages/committees";
import ReimbursementLetter from "pages/reimbursementLetter";
import Volunteers from "pages/volunteers";
import VolunteerForm from "pages/volunteerForms";

// Admin Pages
import AdminHome from "pages/admin/admin";
import CalendarTool from "pages/admin/calendarTool";
import CommitteeTool from "pages/admin/committeeTool";
import FormManagement from "pages/admin/formManagement";
import GradesTool from "pages/admin/gradesTool";

import { AdminMenuType, User, UserContextType} from "datatypes/types";
import { appSessKey, parseToken } from "util";

// Contexts
import userContext from "context/userContext";

const paths = [
    { path: "aboutus", element: AboutUs },
    { path: "contactus", element: ContactUs },
    { path: "committees", element: Committees },
    { path: "grades", element: Grades },
    { path: "grades/:gradeLevel", element: GradeInfo },
    { path: "volunteers", element: Volunteers },
    { path: "volunteers/reimbursement-letter", element: ReimbursementLetter },
    { path: "volunteers/forms", element: VolunteerForm },
    { path: "events", element: Events },
    { path: "events/:event_item", element: Events },
    { path: "*", element: NoMatch }
];

export const adminPaths: AdminMenuType[] = [
    { 
        title:"Events Tool", icon: "calendar_apps_script", fullPath:"/access/events",
        path: "events", element: CalendarTool, scopes:["events"], menuDisplay: true 
    },
    { 
        title:"Committees", icon: "diversity_3", fullPath:"/access/committees", 
        path: "committees", element: CommitteeTool, scopes:["formations"], menuDisplay: true 
    },    
    { 
        title:"Grades", icon: "school", fullPath:"/access/grades",
        path: "grades", element: GradesTool, scopes:["formations"], menuDisplay: true  
    },
    {   
        title:"Form", icon: "forms_apps_script", fullPath:"/access/forms",
        path: "forms", element: FormManagement, scopes:["forms"], menuDisplay: true  
    },
    { 
        title:"Login", icon: "", fullPath:"/access/login",
        path: "login", element: Login, scopes:[], menuDisplay: false  
    },
];

function ScopedRoute({ redirect="/", children, user, routeScopes }){
    let userScopes = user?.scopes ? user.scopes : [];
    let intersection = userScopes.filter(x => routeScopes.includes(x));

    if(!(routeScopes?.length == 0 || intersection.length > 0)){
        return <Navigate to="/access" state={{ redirect: redirect }} />;
    }

    return children;
}

function SiteRoutes(){
    const { user, setUser } = useContext(userContext.UserContext) as UserContextType;
    const { pathname } = useLocation();

    const checkUser = (appSessKey) => {
        try {
            const sessionInfo = localStorage.getItem(appSessKey);
            if(sessionInfo){
                let { isExpired, localUser } = parseToken(sessionInfo); 

                if(isExpired) {
                    localStorage.removeItem(appSessKey);
                }
                else {
                    setUser(localUser);
                }
            }
        }
        catch(ex){
            console.log(`Error Checking User: ${ex}`);
        }
    }

    useEffect(()=> {  window.scrollTo(0, 0);  },[pathname]);
    useEffect(()=> { checkUser(appSessKey); }, []);

    return(
        <Routes>
            {user ?
                <>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                        {paths.map((rt, i) => 
                            <Route path={rt.path} element={<rt.element />}  key={i} /> 
                        )}
                    </Route>
                    <Route path="/access" element={<AdminLayout />}>
                        <Route index element={<AdminHome />} />
                        {adminPaths.map((rt, i) => 
                            <Route path={rt.path} element={
                                <ScopedRoute user={user} redirect={"/"} routeScopes={rt.scopes}>
                                    <rt.element />
                                </ScopedRoute>
                            }  key={i} /> 
                        )}
                    </Route>
                </>:
                <>
                    <Route path="/" element={<AdminLayout />}>
                        <Route index element={<Login />} />
                        {paths.map((rt, i) => 
                            <Route path={rt.path} element={<Login />} key={i} /> 
                        )}
                        {adminPaths.map((rt, i) => 
                            <Route path={`access/${rt.path}`} element={<Login />} key={i} /> 
                        )}
                    </Route>
                </>
            }
        </Routes>
    )
}

export default SiteRoutes;