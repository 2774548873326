import { useEffect, useState } from 'react';

import { Formations } from 'datatypes/types';
import { rootPath } from 'util';

import FormationForm from 'components/formationForm';
import Loader from 'components/loader';

type FormationEditDisplay = {
    display: Boolean; selectedId: string|null; slug: string|null|undefined;
};

const formFields = [
    {"displayName":"Committee Title", "name":"title", "size":"10", "required":true, "type":"input", "initial":""},
    {"displayName":"Committee Leaders", "name":"leadership", "size":"10", "required":false, "type":"sub_form_list", "initial":[], 
        "sub_form_fields":[
            {"displayName":"Name", "name":"name", "size":"3", "required":true, "initial":""},
            {"displayName":"Position", "name":"position", "size":"3", "required":true, "initial":""},
            {"displayName":"Email", "name":"email", "size":"3", "required":false, "initial":""}
        ]
    },
    {"displayName":"Committee Links", "name":"links", "size":"10", "required":false, "type":"link_pill", "initial":[]},   
    {"displayName":"Committee Images", "name":"images", "size":"10", "required":false, "type":"image_upload", "initial":[], "noSubmit":true},
    /* Required No Show Fields */
    {"name":"icon", "initial":"", "noShow":true, "type":"input", "required":false },
    {"name":"colorCode", "initial":"", "noShow":true, "type":"input","required":false }
];

function CommitteeTool(){
    const type = "committee";

    const [formations, setFormations] = useState<Array<Formations>>([]);
    const [displayFormations, setDisplayFormations] = useState<Array<Formations>>([]);
    const [loading, setLoading] = useState(false);
    const [showFormation, setShowFormation] = useState<FormationEditDisplay>({ display: false, selectedId: null, slug: null });
    const [query, setQuery] = useState("");

    const getFormationsList = () =>{
        try {
            setLoading(true);
            fetch(`${rootPath}/v2/api/formation/${type}?include_fields=id,icon,title,slug,leadership,links`, {
                method: "GET", headers: { "Accept": "application/json", "Content-Type":"application/json"}
            })
            .then((response) => response.json())
            .then((res)=> {
                if(res.results) {
                    setFormations(res.results);
                }
                else {
                    console.log(`Error Getting Formations [E02]: ${res.error}`);
                }
            }).catch((err) =>{
                console.log(`Error Getting Formations [E03]: ${err}`);
            }).finally(()=>{ setLoading(false); });
        }
        catch(ex){
            console.log(`Error Getting Formations List [M04] ${ex}`);
        }
    }

    const queryFilterList = () => {
        let filterFormations = formations.filter((formation)=>{ 
            return formation?.title?.toLowerCase().includes(query);
        });

        setDisplayFormations(filterFormations);
    }

    const refreshList = () => {
        setShowFormation({ display: false, selectedId: null, slug: null});
        getFormationsList();
    }

    useEffect(()=> {
        if(formations?.length <= 0){
            setDisplayFormations([]);
        }
        else {
            queryFilterList();
        }
    },[query]);

    useEffect(()=> { queryFilterList(); },[formations]);

    useEffect(()=> { 
        document.title = "Admin Committee Tool";
        getFormationsList(); 
    }, []);

    return (
        <div className="admin-page events formations committee">
            <h1>Committee Editor Tool</h1>
            <div className='formation-container'>
                <div className='formation-ctrl-container'>
                    <div className='formation-list-ctrl'>
                        <div className='formation-search'>
                            <span className="material-symbols-outlined">manage_search</span>
                            <input type="text" disabled={displayFormations?.length <= 0} name="query" value={query} placeholder="Search For Committees" onChange={(e)=> { setQuery(e?.target?.value)}} />
                        </div>

                        <div className='formation-add' onClick={()=>{ setShowFormation({ display: true, selectedId: null, slug: null }) }}>
                            <span className="material-symbols-outlined">playlist_add</span>
                        </div>
                    </div>
                    <div className='formation-list-container'>
                        {loading ? <Loader /> :
                            <>
                                {displayFormations.map((formation,i)=>
                                    <div className='formation-item' key={i}>
                                        <div className='icon-container'>
                                            <span className="material-symbols-outlined">
                                                {formation?.icon ? formation.icon : 'groups_2'}
                                            </span>
                                        </div>
                                        <div className='title'>{formation?.title}</div>
                                        <div className='item-count-container'>
                                            <div className='item-count'>
                                                <span className='count'>{formation?.leadership?.length}</span>
                                                <span className="material-symbols-outlined">group</span>
                                            </div>
                                            <div className='item-count'>
                                                <span className='count'>{formation?.links?.length}</span>
                                                <span className="material-symbols-outlined">link</span>
                                            </div>
                                        </div>
                                        <span className="select-formation material-symbols-outlined" onClick={()=> setShowFormation({ display: true, selectedId: formation._id, slug: formation?.slug})}>edit_note</span>
                                    </div>
                                )}
                            </>
                        }
                    </div>
                </div>
                <div className='formation-editor-container'>
                    {(showFormation?.display === true) ? 
                        <div className='formation-editor'>
                            <div className='title-panel'>
                                <div className='back-link' onClick={()=> setShowFormation({ display: false, selectedId: null, slug: null})}>
                                    <div className="material-symbols-outlined">chevron_left</div>
                                    <span>Back</span>
                                </div>
                                <div className='title'>Committee Editor</div>
                            </div>
                            <div className='editor-pane'>
                                <FormationForm type="committee" slug={showFormation?.slug} successCb={refreshList} formFields={formFields}/>
                            </div>
                        </div> :
                        <div className='formation-editor empty' />
                    }
                </div>
            </div>            
        </div>
    );
}

export default CommitteeTool;