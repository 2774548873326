import { useState, useEffect, useRef } from 'react';
import { validateForm, validateFormField, rootPath, loadFullGallery } from 'util';
import { toast } from 'react-toastify';
import { ColorPicker } from 'primereact/colorpicker';

import { AlbumImg } from 'datatypes/types';

import Loader from './loader';
import SimpleKeyValueSelect from './simpleKeyValueSelect';
import SubFormKeyValue from './subFormKeyValue';
import BaseGalleryUpload from './baseGalleryUpload';

type SubmitForm = {
    _id?: string; type: string; title: string; 
    icon: string; colorCode: string; slug?: string; 
    leadership?: any[]; links?: any[]; images: AlbumImg[];
    image_count?:number;
}

function FormationForm({ type, slug, formFields, successCb }){
    const [formData, setFormData] = useState<SubmitForm>();
    const [formFieldValidation, setFormFieldValidation] = useState({});
    const [formValidation, setFormValidation] = useState(false);
    const [formImages, setFormImages] = useState<Array<AlbumImg>>([]);
    const [loading, setLoading] = useState(false);

    const [imgDelta, setImgDelta] = useState(0);
    const [completedIAs, setCompletedIAs] = useState<Array<Boolean>>([]);

    const hasPageRendered = useRef({ "type_slug": false, "form_data": false });

    const setLocalData = (e) =>{
        let name = e.target.name,
        value = e.target.value;
        
        // Set Form Data
        setFormData((d) => { return { ...d, [name]: value } as SubmitForm; });

        // Set Form Field Validation
        let fieldIdx = formFields.map(e => e.name).indexOf(name);
        if(fieldIdx >= 0){
            checkFieldValidation(formFields[fieldIdx], value);
        }
    }

    const setMultiSelect = (list, name) => {
        setLocalData({ target: { name: name, value: list }});
    }

    const checkFieldValidation = (field, fieldData=null) => {
        try {
            if(formData){
                fieldData = fieldData == null ? formData[field.name] : fieldData;

                if(Object.keys(formFieldValidation)?.length > 0){
                    setFormFieldValidation((d) =>{
                        return { ...d, [field.name]: validateFormField(fieldData, field.type, field.required, field.options)}
                    });
                }
            }
        }
        catch(ex){
            console.log(`Error Validating Field: ${ex}`);
        }
    }

    const buildFormField = (form_data) => {
        try {
            if(!formData){ return <></>; }
            
            switch(form_data?.type){
                case "input":
                    return <input type="text" name={`${form_data.name}`} value={formData[form_data.name]} onChange={setLocalData} onBlur={()=> checkFieldValidation(form_data)} />;
                case "select":
                    return <select name={`${form_data.name}`} value={formData[form_data.name]} onChange={setLocalData} onBlur={()=> checkFieldValidation(form_data)}>
                        <option hidden></option>
                        {form_data.options.map((item,i) => <option key={i} value={item}>{item}</option> )}
                    </select>;
                case "link_pill":
                    return <SimpleKeyValueSelect items={formData[form_data.name]} setItems={(list)=> { setMultiSelect(list, form_data.name)}}/>    
                case "sub_form_list":
                    return <SubFormKeyValue fields={form_data.sub_form_fields} items={formData[form_data.name]} setItems={(list)=> { setMultiSelect(list, form_data.name)}}/>;    
                case "image_upload":
                    return <BaseGalleryUpload item_id={formData?._id ?? ""} type={type} isSingle={form_data?.is_single === true} items={formImages} setItems={setFormImages}/>;    
                case "color_picker":
                    return <ColorPicker value={formData[form_data.name]} onChange={(e)=> { setMultiSelect(e.value, form_data.name)}}/>    
                default:
                    return <></>;
            }
        }
        catch(ex){
            console.log(`Error Building Form Field: ${ex}`);
            return <></>;
        }
    }

    const resetForm = () => {
        try {
            let initFormData= {} as SubmitForm, initValidation = {};
            formFields.forEach((item) => { initFormData[item.name] = item.initial; initValidation[item.name] = true; });
            setFormData(initFormData); setFormImages([]);
            setFormFieldValidation({ ...initValidation });
        }
        catch(ex){
            console.log(`Error resetting form: ${ex}`);
        }
    }

    const buildFormData = (data, formImages, formData) => {
        let ret = {} as SubmitForm, initValidation = {};
        try {
            ret["type"] = type;
            if("_id" in data){ ret['_id'] = data._id; }

            // Add Form Images
            data["images"] = formImages;

            for(let i=0; i < formFields.length; i++){
                let item = formFields[i];

                if(formData == true && item?.noSubmit == true){ continue; }

                ret[item.name] = (item.name in data ? data[item.name] : item.initial);                   
                initValidation[item.name] = true;
            }
            
            setFormFieldValidation({ ...initValidation });
        }
        catch(ex){
            console.log(`Building Form Data: ${ex}`);
            ret['error'] = "NA";
        }

        return ret;
    }

    const insertImgs = (imgList: AlbumImg[], albumId: String) => {
        try {
            setLoading(true);
                
            for(let i=0; i < imgList.length; i++){
                let postData = JSON.stringify({...imgList[i], item_id: albumId});

                fetch(`${rootPath}/v2/api/photo`, {
                    method: "POST", body: postData,
                    headers: { "Accept": "application/json", "Content-Type":"application/json"}
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(!res.results) {
                        console.log(`Error With Inserting Image [DF00]: ${res.error}`);
                        toast.error("Sorry, There was an issue uploading one of your images [Please Contact Site Admin]", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                            draggable: true, progress: undefined, theme: "light", });
                    }
                }).catch((err) =>{
                    console.log(`Error With Submitting Image [DF01]: ${err}`);
                    toast.error("Sorry, There was an issue uploading one of your images [Please Contact Site Admin]", { position: "top-right",
                        autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                        draggable: true, progress: undefined, theme: "light", });
                }).finally(()=> { 
                    setCompletedIAs((d) =>{
                        let tmp = [...d]; tmp.push(true);
                        return tmp;
                    });
                });
            }  
                    
        }
        catch(ex){
            console.log(`Inserting Img: ${ex}`);
        }
    }

    const removeImgs = (imgList: AlbumImg[]) => {
        try {
            setLoading(true);
                
            for(let i=0; i < imgList.length; i++){
                fetch(`${rootPath}/v2/api/photo/${imgList[i]._id}`, {
                    method: "DELETE",
                    headers: { "Accept": "application/json", "Content-Type":"application/json"}
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(!res.results) {
                        console.log(`Error With Deleting Image [DF00]: ${res.error}`);
                        toast.error("Sorry, There was an issue deleting one of your images [Please Contact Site Admin]", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                            draggable: true, progress: undefined, theme: "light", });
                    }
                }).catch((err) =>{
                    console.log(`Error With Deleting Image [DF01]: ${err}`);
                    toast.error("Sorry, There was an issue deleting one of your images [Please Contact Site Admin]", { position: "top-right",
                        autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                        draggable: true, progress: undefined, theme: "light", });
                }).finally(()=> { 
                    setCompletedIAs((d) =>{
                        let tmp = [...d]; tmp.push(true);
                        return tmp;
                    });
                });
            }          
        }
        catch(ex){
            console.log(`Inserting Img: ${ex}`);
        }
    }

    const submitImgs = (albumId) => {
        try {
            if(formData && albumId){
                let imgList: AlbumImg[] = formData.images ?? [];

                // Insert Images
                let newImgs = imgList.filter((img) => { 
                    return (!img?._id || img?._id?.length <= 0);
                });                
                
                // Remove Images
                let deleteImgs = imgList.filter((img) => { 
                    return img?.delete === true;
                });

                let deltaSz = newImgs.length + deleteImgs.length;

                setCompletedIAs([]);
                setImgDelta(deltaSz);

                if(deltaSz <= 0){ successCb(); }

                if(newImgs?.length > 0){ insertImgs(newImgs, albumId); }                
                if(deleteImgs?.length > 0){ removeImgs(deleteImgs); }           
            }
            else {
                successCb();
            }            
        }
        catch(ex){
            console.log(`Submitting Images: ${ex}`);
        }
    }

    const submitForm = () => {
        if(formValidation){
            let processedForm = buildFormData(formData, formImages, true);

            if(!("error" in processedForm)) {
                let request_type = ("_id" in processedForm && processedForm?._id ? 'PUT' : 'POST');
                let postData = JSON.stringify(processedForm);

                setLoading(true);
                fetch(`${rootPath}/v2/api/formation`, {
                    method: request_type, body: postData,
                    headers: { "Accept": "application/json", "Content-Type":"application/json"}
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(res.results) {
                        toast.success("Successfully Created Event", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                            draggable: true, progress: undefined, theme: "light" });
                        
                        submitImgs(res.results);
                    }
                    else {
                        console.log(`Error With Submitting Form [DF00]: ${res.error}`);
                        toast.error("Sorry, There was an issue submitting your form [Please Contact Site Admin]", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                            draggable: true, progress: undefined, theme: "light", });
                    }
                }).catch((err) =>{
                    console.log(`Error With Submitting Form [DF01]: ${err}`);
                }).finally(()=> { setLoading(false); });
            }
        }
    }

    const getFormation = () =>{
        try {
            if(type && slug){
                setLoading(true);
                fetch(`${rootPath}/v2/api/formation/${type}/${slug}`, {
                    method: "GET", headers: { "Accept": "application/json", "Content-Type":"application/json"}
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(res.results) {
                        let id_form_data = buildFormData(res.results, formImages, false);
                        let tmp_image_count = ("image_count" in res.results ? res.results.image_count : 0);

                        setFormData((d) => {
                            let tmp = { ...id_form_data, "image_count": tmp_image_count };
                            if(d && "images" in d) { tmp["images"] = d.images };

                            return tmp;
                        });
                    }
                    else {
                        console.log(`Error Getting Formation List [E02]: ${res.error}`);
                    }
                }).catch((err) =>{
                    console.log(`Error Getting Formation List [E03]: ${err}`);
                }).finally(()=>{ setLoading(false); });
            }
            else {
                resetForm();
            }
        }
        catch(ex){
            console.log(`Error Getting Event Sub List [M04] ${ex}`);
        }
    }

    const deleteFormation = (_id) => {
        try {
            if(confirm(`Are you sure you want to delete this ${type}?`)){
                setLoading(true);
                fetch(`${rootPath}/v2/api/formation/${type}/${_id}`, {
                    method: "DELETE",
                    headers: { "Accept": "application/json", "Content-Type":"application/json"}
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(res.results) {
                        toast.success(`Successfully Deleted ${type}`, { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                            draggable: true, progress: undefined, theme: "light", });

                        successCb();
                    }
                    else {
                        console.log(`Error With Deleting Formation [DF00]: ${res.error}`);
                        toast.error(`Sorry, There was an issue deleting this ${type} [Please Contact Site Admin]`, { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                            draggable: true, progress: undefined, theme: "light", });
                    }
                }).catch((err) =>{
                    console.log(`Error With Deleting Formation [DF01]: ${err}`);
                }).finally(()=> { setLoading(false); });
            }
        }
        catch(ex){
            console.log(`Error resetting form: ${ex}`);
        }   
    }

    useEffect(()=>{ 
        hasPageRendered.current.form_data = true;
        getFormation();
    },[type, slug]);

    useEffect(()=> {
        if(formData){
            setFormValidation(validateForm(formFields, formData).length === 0);

            if(hasPageRendered.current.form_data){
                loadFullGallery(formData._id, type, formData?.image_count ?? 0, setFormImages);
                hasPageRendered.current.form_data = false;
            }
        }
    },[formData]);

    useEffect(()=>{
        if(imgDelta > 0 && completedIAs.length >= imgDelta){
            setLoading(false);
            successCb();
        }
    },[completedIAs]);

    useEffect(()=> { 
        if(hasPageRendered.current.form_data){
            setFormImages([]); 
        }
    },[slug])

    return (
        <div className="hsa-form-container dynamic">
            {Object.keys(formFieldValidation)?.length > 0 &&
                <>
                    <div className="fields-container">
                        {formFields.filter((f)=>{ return f?.noShow != true;}).map((field, i)=>                            
                            <div className={`field-container sz-${field.size} ${(formFieldValidation[field.name] ? "" : "invalid")}`} key={i}>
                                <div className='field-label'>{field.displayName} <span>{field.required ? "*" :""}</span></div>
                                <div className={`field-input-container ${field.overflow === true ? 'multi-select' : ''}`}>{buildFormField(field)}</div>
                            </div>                                
                        )}
                    </div>

                    <div className='btn-container sticky-container'>
                        {formData?._id ? 
                            <div className='form-btn cancel' onClick={() => { deleteFormation(formData?._id) }}>Delete</div> :
                            <div className='form-btn cancel' onClick={resetForm}>Clear</div>
                        }
                        
                        <div className={`form-btn ${formValidation ? "" : "disabled"}`} onClick={submitForm}>Submit</div>
                    </div>
                </>
            }
            {loading && <Loader />}
        </div>
    );
}

export default FormationForm;