import { useState, useEffect, useRef } from 'react';
import 'rodal/lib/rodal.css';
import Rodal from 'rodal';
import { toast } from 'react-toastify';

import { CSSTransition } from 'react-transition-group';

import { rootPath } from 'util';
import { EventV2 } from 'datatypes/types';
import EventGallery from './eventGallery';
import EventFeedback from './eventFeedback';
import Loader from './loader';

import background from '../assets/calendar3.jpg';

function EventModal({ show, setShow, eventId }){
    const modalWidth = window.outerWidth ? (window.outerWidth >= 770 ? (window.outerWidth * .7) : (window.outerWidth * .9)) : 500, 
        modalHeight = ((window.innerHeight - 100) * 0.95);

    const [eventInfo, setEventInfo] = useState<EventV2 | null>();
    const [showFeedback, setShowFeedback] = useState(false);
    const [loading, setLoading] = useState(false);

    const feedbackRef = useRef(null);
    const galleryRef = useRef(null);

    const getEvent = () =>{
        try {   
            if(eventId){
                setLoading(true);
                fetch(`${rootPath}/v2/api/event/${eventId}`, {
                    method: "GET", headers: { "Accept": "application/json", "Content-Type":"application/json"}
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(res.results) {
                        setEventInfo(res.results);
                    }
                    else {
                        console.log(`Error Getting Event [EG01]: ${res.error}`);
                        setEventInfo(null);
                        toast.error("Sorry, We are unable to retrieve this event", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                            draggable: true, progress: undefined, theme: "light", });
                    }
                }).catch((err) =>{
                    console.log(`Error Getting Event [EG02]: ${err}`);
                })
                .finally(()=>{ setLoading(false); });
            }
        }
        catch(ex){
            console.log(`Error Getting Event Sub List [EG03] ${ex}`);
        }
    }

    useEffect(()=>{ getEvent(); },[eventId]);
    useEffect(()=> { 
        document.body.classList.toggle('noscroll', (show == true));        
    }, [show])

    return (
        <>
            <Rodal visible={show} onClose={()=> setShow(false)} animation={"slideUp"} width={modalWidth} height={modalHeight} customStyles={{ marginTop: '110px'}}>
                <div className='background_img'><img src={background} /></div>
                {(!loading && !eventInfo) ? 
                    <div className='empty-event'><span>No Event Information</span></div>:
                    <>
                        <CSSTransition nodeRef={galleryRef} in={!showFeedback} timeout={500} classNames="slide-from-left" unmountOnExit>
                            <div className='event-transition-container' ref={galleryRef}>
                                <EventGallery eventInfo={eventInfo} setShowFeedback={setShowFeedback} />
                            </div>
                        </CSSTransition>
                        
                        <CSSTransition nodeRef={feedbackRef} in={showFeedback} timeout={500} classNames="slide-from-right" unmountOnExit>
                            <div className='event-transition-container' ref={feedbackRef}>
                                <EventFeedback eventInfo={eventInfo} setShowFeedback={setShowFeedback}/>
                            </div>
                        </CSSTransition>
                    </>
                }
                {loading && <Loader />}
            </Rodal>
        </>
    );
}

export default EventModal;