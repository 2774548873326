import { useRef } from 'react';

import background from '../assets/homeschool.jpg';
import cover_photo from '../assets/volunteer2.jpg';
import logo from '../assets/hsa-logo2.png';

// Files
import virtus_training from '../assets/documents/Virtus_Procedures_for_Employees_Volunteers.pdf';

function Volunteers(){
    document.title = 'Volunteer Page';
    const stepsSectionRef = useRef<HTMLDivElement>(null);

    const pageScroll = (ref) => {
        try {
            let pageLoc = ref.current.offsetTop;
            if(!pageLoc || pageLoc < 0){ 
                pageLoc = 0;
            }

            window.scrollTo({ top: pageLoc, left: 0, behavior: "smooth" });
        }
        catch(ex){
            console.log(`Error moving to section ${ex}`);
        }
    }

    return (
        <div className="internal-page parents_grades">
            <div className='internal-landing'>
                <div className="cover-image">
                    <img src={cover_photo} alt="volunteers event photo" />
                </div>
                <h1>Volunteers</h1>
            </div>

            <section className='welcome-parents-section add-space'>
                <div className='background_img'><img src={background} /></div>
                <div className='coordinator-info'>
                    <div className='info-img-container'>
                        <img src={logo} alt="hsa logo" />
                    </div>
                    <div className='into-text'>
                        <h2>Volunteer Coordinator</h2>
                        <span>Michelle Neary</span>
                        <a href={`mailTo:stesvolunteercoordinator@gmail.com`}>stesvolunteercoordinator@gmail.com</a>
                        <span>Please email the HSA Volunteer Coordinator for any volunteer related questions.</span>
                    </div>
                </div>

                <p>The HSA provides wonderful volunteer opportunities all school year long!</p>
                <div className='resource-link-container'>
                    <a href="https://www.stelizabethschoolmd.org/volunteering" target="_blank" onClick={() => { return confirm('You will now be navigated to an external site via a new tab.  Please switch tabs to return to our website.'); }}>
                        <span>St. Elizabeth Volunteer</span>
                        <span className="icon material-symbols-outlined">language</span>
                    </a>
                </div>
            </section>
        </div>
    );
}

export default Volunteers;