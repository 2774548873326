function SimpleKeyValueSelect({ items, setItems }){
    const setLocalItem = (idx, e) => {
        let name = e.target.name,
            value = e.target.value;
        
        let tmpList = [...items];
        tmpList[idx][name] = value;
        setItems(tmpList);
    }

    const addItem = () => {
        let tmpList = [...items];
        tmpList.push({"key":"", "value":""});

        setItems(tmpList);
    }

    const removeItem = (idx) => {
        let tmpList = [...items];
        tmpList.splice(idx,1);
        
        setItems(tmpList);
    }

    return (
        <div className="multiselect">
            <div className="multi-btn-container">
                <div className='add-btn' onClick={addItem}>
                    <span className="material-symbols-outlined">add</span>
                    <span>Add</span>
                </div>
            </div>
            <div className='hsa-form-container'>
                {(items) && items.map((item,i) => 
                    <div className="fields-container" key={i}>
                        <div className={`field-container sz-4`}>
                            <div className='field-label'>Text <span>*</span></div>
                            <div className={`field-input-container`}>
                                <input type="text" name='key' value={item.key} onChange={(e)=> { setLocalItem(i, e)}} />
                            </div>
                        </div>

                        <div className={`field-container sz-5`}>
                            <div className='field-label'>Link <span>*</span></div>
                            <div className={`field-input-container`}>
                                <input type="text" name='value' value={item.value} onChange={(e)=> { setLocalItem(i, e)}} />
                            </div>
                        </div>

                        <div className={`field-container sz-1`}>
                            <div className="field-icon"><span onClick={()=> { removeItem(i) }} className="material-symbols-outlined">backspace</span></div>
                        </div>
                    </div>
                )}                
            </div>
        </div>
    );
}

export default SimpleKeyValueSelect;