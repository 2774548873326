import { useState, useEffect } from 'react';
import { validateForm, validateFormField, rootPath } from 'util';
import { toast } from 'react-toastify';

import Loader from './loader';

function DynamicForm({ title, formFields, formType }){
    const [formData, setFormData] = useState({});
    const [formFieldValidation, setFormFieldValidation] = useState({});
    const [formValidation, setFormValidation] = useState(false);
    const [loading, setLoading] = useState(false);

    const setLocalData = (e) =>{
        let name = e.target.name,
        value = e.target.value;

        // Set Form Data
        setFormData((d) => { return { ...d, [name]: value }; });

        // Set Form Field Validation
        let fieldIdx = formFields.map(e => e.name).indexOf(name);
        if(fieldIdx >= 0){
            checkFieldValidation(formFields[fieldIdx], value);
        }
    }

    const checkFieldValidation = (field, fieldData=null) => {
        try {
            fieldData = fieldData == null ? formData[field.name] : fieldData;

            if(Object.keys(formFieldValidation)?.length > 0){
                setFormFieldValidation((d) =>{
                    return { ...d, [field.name]: validateFormField(fieldData, field.type, field.required, field.options)}
                });
            }
        }
        catch(ex){
            console.log(`Error Validating Field: ${ex}`);
        }
    }

    const buildFormField = (form_data) => {
        try {
            switch(form_data?.type){
                case "input":
                    return <input type="text" name={`${form_data.name}`} value={formData[form_data.name]} onChange={setLocalData} onBlur={()=> checkFieldValidation(form_data)} />;
                case "email":
                    return <input type="text" name={`${form_data.name}`} value={formData[form_data.name]} onChange={setLocalData} onBlur={()=> checkFieldValidation(form_data)} />;
                case "select":
                    return <select name={`${form_data.name}`} value={formData[form_data.name]} onChange={setLocalData} onBlur={()=> checkFieldValidation(form_data)}>
                        <option hidden></option>
                        {form_data.options.map((item,i) => <option key={i} value={item}>{item}</option> )}
                    </select>;
                case "textarea":
                    return <textarea name={`${form_data.name}`} value={formData[form_data.name]} onChange={setLocalData} onBlur={()=> checkFieldValidation(form_data)} />;
                case "date":
                    return <input type="date" name={`${form_data.name}`} value={formData[form_data.name]} onChange={setLocalData} onBlur={()=> checkFieldValidation(form_data)} />;
                case "number":
                    return <input type="number" name={`${form_data.name}`} value={formData[form_data.name]} onChange={setLocalData} onBlur={()=> checkFieldValidation(form_data)} />;                            
                default:
                    return <></>;
            }
        }
        catch(ex){
            console.log(`Error Building Form Field: ${ex}`);
            return <></>;
        }
    }

    const resetForm = () => {
        try {
            let initFormData = {}, initValidation = {};
            formFields.forEach((item) => { initFormData[item.name] = ""; initValidation[item.name] = true; });
            setFormData({ ...initFormData });
            setFormFieldValidation({ ...initValidation });
        }
        catch(ex){
            console.log(`Error resetting form: ${ex}`);
        }
    }

    const submitForm = () => {
        if(formValidation){
            let postData = JSON.stringify({ form_type: formType, form_data: formData });
            setLoading(true);
            fetch(`${rootPath}/v1/api/submit-form`, {
                method: "POST", body: postData,
                headers: { "Accept": "application/json", "Content-Type":"application/json"}
            })
            .then((response) => response.json())
            .then((res)=> {
                if(res.status) {
                    toast.success("Form Submitted Successfully", { position: "top-right",
                        autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                        draggable: true, progress: undefined, theme: "light", });
                 }
                else {
                    console.log(`Error With Submitting Form [DF00]: ${res.error}`);
                    toast.error("Sorry, There was an issue submitting your form [Please Contact Site Admin]", { position: "top-right",
                        autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                        draggable: true, progress: undefined, theme: "light", });
                }
            }).catch((err) =>{
                console.log(`Error With Submitting Form [DF01]: ${err}`);
            }).finally(()=> { setLoading(false); });
        }
    }

    useEffect(()=>{ resetForm(); },[])

    useEffect(()=> {
        setFormValidation(validateForm(formFields, formData).length === 0);
    },[formData])

    return (
        <div className="hsa-form-container dynamic">
            <h1>{title}</h1>

            {Object.keys(formFieldValidation)?.length > 0 &&
                <>
                    <div className="fields-container">
                        {formFields.map((field, i)=>
                            <div className={`field-container sz-${field.size} ${(formFieldValidation[field.name] ? "" : "invalid")}`} key={i}>
                                <div className='field-label'>{field.displayName} <span>{field.required ? "*" :""}</span></div>
                                <div className='field-input-container'>{buildFormField(field)}</div>
                            </div>
                        )}
                    </div>

                    <div className='btn-container'>
                        <div className='form-btn cancel' onClick={resetForm}>Clear</div>
                        <div className={`form-btn ${formValidation ? "" : "disabled"}`} onClick={submitForm}>Submit</div>
                    </div>
                </>
            }
            {loading && <Loader />}
        </div>
    );
}

export default DynamicForm;