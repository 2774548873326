import { useRef, useState } from 'react';
import Resizer from "react-image-file-resizer";

import { AlbumImg } from 'datatypes/types';

type BaseGalleryType = {
    item_id: string, type: string, isSingle?:boolean,
    setItems: (list: AlbumImg[]) => void, items: AlbumImg[]
}

const MAX_WIDTH = 512, MAX_IMG_SIZE = (50 * 1024);

function BaseGalleryUpload({ item_id, type, items, setItems, isSingle=false }: BaseGalleryType){
    const accept_list = "image/*";
    const fileInputRef = useRef<HTMLInputElement>(null);
    const itemListRef = useRef<HTMLDivElement>(null);
    const [ctrlStatus, setCtrlStatus] = useState({ "prev": true, "next": false});

    const handleFileInputChange = (e) => {
        try {
            let files = e.target.files, tmpImgList:any[] = [...items];

            if(isSingle){
                let newList: any[] = [];
                tmpImgList.forEach((il: any)=> {
                    if(il?._id){ 
                        newList.unshift({ ...il, delete: true});
                    }
                });

                tmpImgList = newList;
            }
            
            for(let i=0; i < files?.length; i++){
                getBase64(files[i], function(basefile){
                    tmpImgList.unshift({ item_id: item_id, type: type, src: basefile, title: files[i]?.name });
                    if(tmpImgList.length >= files?.length){
                        setItems(tmpImgList);
                    }
                });
            }
        }
        catch(ex){
            console.log(`Selecting Uploaded Files: ${ex}`);
        }
    }

    const getBase64 = (file, cb) => {
        let baseURL: string | ArrayBuffer | null = null;
        try {
            let reader = new FileReader();
            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                
                resizeImg(baseURL, file, function(rsUrl){ cb(rsUrl); });
            };
        }
        catch(ex){
            console.log(`Formating Image: ${ex}`);
        }
    }

    const resizeImg = (base64Str, file, cb) => {
        try {
            let img = document.createElement('img');

            img.onload = () => { 
                let canvas = document.createElement('canvas');
                let ctx = canvas.getContext("2d");
                ctx?.drawImage(img, 0, 0);

                let width = img.width, height = img.height;

                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;

                    Resizer.imageFileResizer(
                        file, width, height,
                        "JPEG", 75, 0,
                        (uri) => { 
                            canvas?.remove(); img?.remove();
                            cb(uri); 
                        },
                        "base64", (width/2), (height/2)
                    );
                }
                else if(file.size > MAX_IMG_SIZE){
                    height *= (0.9 * width) / width; 
                    width = (0.9 * width);

                    Resizer.imageFileResizer(
                        file, width, height,
                        "JPEG", 50, 0,
                        (uri) => { 
                            canvas?.remove(); img?.remove();
                            cb(uri); 
                        },
                        "base64", (width/2), (height/2)
                    );
                }
                else {
                    canvas?.remove(); img?.remove();
                    cb(base64Str);
                }
            }
            
            img.src = base64Str;            
        }
        catch(ex){
            console.log(`Resizing Img: ${ex}`);
        }
    }

    const removeImgToggle = (img, idx) => {
        try {
            if(idx < items.length){
                let tmpList = [...items];

                if(!img?._id){                    
                    tmpList.splice(idx,1);

                    if(isSingle && tmpList.length > 0){
                        tmpList[0]["delete"] = false;
                    }
                }
                else if(img?.delete == true){
                    tmpList[idx]["delete"] = false;

                    if(isSingle && idx > 0){
                        tmpList.shift();
                    }
                }
                else {
                    tmpList[idx]["delete"] = true;
                }

                setItems(tmpList);
            }
        }
        catch(ex){
            console.log(`Removing Img: ${idx}`);
        }
    }

    const controlSlider = (ref,dir) => {
        try {
            let offsetWidth = ref.current.offsetWidth,
                scrollWidth = ref.current.scrollWidth;
            if(scrollWidth > offsetWidth){
                let scrollSz = (2 * 108),
                    newScrollLeft = ref.current.scrollLeft;
                
                if(dir === "prev"){
                    newScrollLeft = ref.current.scrollLeft - scrollSz;
                    newScrollLeft = (newScrollLeft < 0 ? 0 : newScrollLeft);
                }
                else if(dir === "next"){
                    newScrollLeft = ref.current.scrollLeft + scrollSz;
                }

                ref.current.scrollLeft = newScrollLeft;
                setCtrlStatus({ "prev": disableCtrl(ref, newScrollLeft, "prev"), "next":disableCtrl(ref, newScrollLeft, "next") });
            }
        }
        catch(ex){
            console.log(`Error sliding carousel: ${ex}`)
        }
    }

    const disableCtrl = (ref, newScrollLeft, dir) => {
        try {
            if(dir === "prev"){
                return newScrollLeft <= 0;
            }
            else if(dir === "next"){
                return (ref.current.offsetWidth + newScrollLeft) >= ref.current.scrollWidth
            }
        }
        catch(ex){
            console.log(`Disabling Controlling Slider: ${ex}`);
        }

        return false;
    }

    return (
        <div className="gallery-upload-container">
            <>
                <div className="selection-btn" onClick={()=> { fileInputRef?.current?.click(); }}>
                    <span className='text'>Choose File</span>
                    <span className="icon material-symbols-outlined">photo_library</span>
                </div>
                <input type="file" accept={accept_list} multiple={!isSingle}
                    onChange={handleFileInputChange} ref={fileInputRef} hidden
                />
            </>
            <div className='gallery-full-container'>
                <div className='gallery-upload-imgs-container' ref={itemListRef}>
                    {items.map((img,i) =>
                        <div className={`img-container ${(!img?._id ? "new" : "")} ${(img?.delete === true ? "remove" : "")}`} key={i}>
                            <img src={img.src} />
                            <div className='img-editor' onClick={()=>{ removeImgToggle(img, i)}}>
                                <span className="material-symbols-outlined">{img?.delete === true ? "undo" : "delete"}</span>
                            </div>
                        </div>
                    )}
                </div>

                <div className={`img-list-ctrl ${items.length <= 1 ? 'disabled' : ''}`}>
                    <span className={`material-symbols-outlined prev ${ctrlStatus.prev ? "disable":""}`} onClick={()=> controlSlider(itemListRef, "prev")}>chevron_left</span>
                    <span className={`material-symbols-outlined next ${ctrlStatus.next ? "disable":""}`} onClick={()=> controlSlider(itemListRef, "next")}>chevron_right</span>
                </div>
            </div>
        </div>
    );
}

export default BaseGalleryUpload;