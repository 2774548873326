import { useEffect, useState } from 'react';

import { Formations } from 'datatypes/types';
import { rootPath } from 'util';

import FormationForm from 'components/formationForm';
import Loader from 'components/loader';

type FormationEditDisplay = {
    display: Boolean; selectedId: string|null; slug: string|null|undefined;
};

const formFields = [
    {"displayName":"Grade Title", "name":"title", "size":"10", "required":true, "type":"input", "initial":""},
    {"displayName":"Default Image", "name":"images", "size":"10", "required":false, "type":"image_upload", "is_single":true, "initial":[], "noSubmit":true},
    {"displayName":"Icon", "name":"icon", "size":"5", "required":true, "type":"select", "initial":"", "options":[
        "school", "history_edu", "person_play","dictionary"
    ]},
    {"displayName":"Color Picker", "name":"colorCode", "size":"5", "required":true, "type":"color_picker", "initial":"#000"},
    {"displayName":"Grade Leaders", "name":"leadership", "size":"10", "required":false, "type":"sub_form_list", "initial":[], 
        "sub_form_fields":[
            {"displayName":"Name", "name":"name", "size":"3", "required":true, "initial":""},
            {"displayName":"Position", "name":"position", "size":"3", "required":true, "initial":""},
            {"displayName":"Email", "name":"email", "size":"3", "required":false, "initial":""}
        ]
    },
    {"displayName":"Grade Links", "name":"links", "size":"10", "required":false, "type":"link_pill", "initial":[]}
];

function GradesTool(){
    const type = "grade";

    const [formations, setFormations] = useState<Array<Formations>>([]);
    const [displayFormations, setDisplayFormations] = useState<Array<Formations>>([]);
    const [loading, setLoading] = useState(false);
    const [showFormation, setShowFormation] = useState<FormationEditDisplay>({ display: false, selectedId: null, slug: null });
    const [query, setQuery] = useState("");
    const [draggingItem, setDraggingItem] = useState(null);

    const getFormationsList = () =>{
        try {
            setLoading(true);
            fetch(`${rootPath}/v2/api/formation/${type}?include_fields=_id,icon,title,slug,order`, {
                method: "GET", headers: { "Accept": "application/json", "Content-Type":"application/json"}
            })
            .then((response) => response.json())
            .then((res)=> {
                if(res.results) {
                    setFormations(res.results);
                }
                else {
                    console.log(`Error Getting Formations [E02]: ${res.error}`);
                }
            }).catch((err) =>{
                console.log(`Error Getting Formations [E03]: ${err}`);
            }).finally(()=>{ setLoading(false); });
        }
        catch(ex){
            console.log(`Error Getting Formations List [M04] ${ex}`);
        }
    }

    const queryFilterList = () => {
        let filterFormations = formations.filter((formation)=>{ 
            return formation?.title?.toLowerCase().includes(query);
        }).sort((a,b) => {
            let a_order = a?.order ?? 0;
            let b_order = b?.order ?? 0;

            return a_order - b_order;
        });

        setDisplayFormations(filterFormations);
    }

    const refreshList = () => {
        setShowFormation({ display: false, selectedId: null, slug: null});
        getFormationsList();
    }

    /* Drag & Drop Actions */
    const handleDragStart = (e, item) => { 
        setDraggingItem(item); 
        e.dataTransfer.setData('text/plain', ''); 
    } 

    const handleDragEnd = () => { 
        setDraggingItem(null); 
    }

    const handleDragOver = (e) => { 
        e.preventDefault(); 
    };

    const handleDrop = (e, targetItem) => { 
        if (!draggingItem) return;

        const currentIndex = displayFormations.indexOf(draggingItem); 
        const targetIndex = displayFormations.indexOf(targetItem);

        updateOrder(currentIndex, targetIndex);
    }

    const updateOrder = (originalLoc: number, newLoc: number) => {
        try {
            if(originalLoc != newLoc){
                let updateItemList: any[] = [];

                updateItemList.push({ _id: displayFormations[originalLoc]._id, order: newLoc });

                if(originalLoc < newLoc) {                   
                    for(let i=newLoc; i > originalLoc; i--){
                        updateItemList.push({ _id: displayFormations[i]._id, order: (i-1) });
                    }
                }
                else {
                    for(let i=newLoc; i < originalLoc; i++){
                        updateItemList.push({ _id: displayFormations[i]._id, order: (i+1) });
                    }
                }

                reorderFormationsList(updateItemList);
            }
        }
        catch(ex){
            console.log(`updating order: ${ex}`);
        }
    }

    const reorderFormationsList = (reorderList) =>{
        try {
            setLoading(true);
            const postData = JSON.stringify({ reorderList: reorderList });

            fetch(`${rootPath}/v2/api/formation/reorder`, {
                method: "POST", body: postData,
                headers: { "Accept": "application/json", "Content-Type":"application/json"}                
            })
            .then((response) => response.json())
            .then((res)=> {
                if(!res.results) {
                    console.log(`Error Reordering Formations [E02]: ${res.error}`);
                }
            }).catch((err) =>{
                console.log(`Error Reordering Formations [E03]: ${err}`);
            }).finally(()=>{ setLoading(false); getFormationsList(); });
        }
        catch(ex){
            console.log(`Error Reordering Formations List [M04] ${ex}`);
        }
    }

    useEffect(()=> {
        if(formations?.length <= 0){
            setDisplayFormations([]);
        }
        else {
            queryFilterList();
        }
    },[query]);

    useEffect(()=> { queryFilterList(); },[formations]);

    useEffect(()=> { 
        document.title = "Admin Grade Tool";
        getFormationsList(); 
    }, []);

    return (
        <div className="admin-page events formations grade">
            <h1>Grade Editor Tool</h1>
            <div className='formation-container'>
                <div className='formation-ctrl-container'>
                    <div className='formation-list-ctrl'>
                        <div className='formation-search'>
                            <span className="material-symbols-outlined">manage_search</span>
                            <input type="text" disabled={displayFormations?.length <= 0} name="query" value={query} placeholder="Search For Grades" onChange={(e)=> { setQuery(e?.target?.value)}} />
                        </div>

                        <div className='formation-add' onClick={()=>{ setShowFormation({ display: true, selectedId: null, slug: null }) }}>
                            <span className="material-symbols-outlined">playlist_add</span>
                        </div>
                    </div>
                    <div className='formation-list-container'>
                        <div className='reorder-message'>
                            <span>Use</span> <span className="material-symbols-outlined">swap_vert</span> 
                            <span>To Reorder Grades</span>
                        </div>
                        {loading ? <Loader /> :
                            <>
                                {displayFormations.map((formation,i)=>
                                    <div className={`formation-item ${(formation === draggingItem ? 'dragging':'')}`} key={i}
                                        draggable="true"
                                        onDragStart={(e) => handleDragStart(e, formation)} 
                                        onDragEnd={handleDragEnd} 
                                        onDragOver={handleDragOver} 
                                        onDrop={(e) => handleDrop(e, formation)}
                                    >
                                        <div className='icon-container'>
                                            <span className="material-symbols-outlined">
                                                {formation?.icon ? formation.icon : 'groups_2'}
                                            </span>
                                        </div>
                                        <div className='title'>{formation?.title}</div>
                                        <span className="sort-formation material-symbols-outlined">swap_vert</span>
                                        <span className="select-formation material-symbols-outlined" onClick={()=> setShowFormation({ display: true, selectedId: formation._id, slug: formation?.slug})}>edit_note</span>
                                    </div>
                                )}
                            </>
                        }
                    </div>
                </div>
                <div className='formation-editor-container'>
                    {(showFormation?.display === true) ? 
                        <div className='formation-editor'>
                            <div className='title-panel'>
                                <div className='back-link' onClick={()=> setShowFormation({ display: false, selectedId: null, slug: null})}>
                                    <div className="material-symbols-outlined">chevron_left</div>
                                    <span>Back</span>
                                </div>
                                <div className='title'>Grade Editor</div>
                            </div>
                            <div className='editor-pane'>
                                <FormationForm type="grade" slug={showFormation?.slug} successCb={refreshList} formFields={formFields}/>
                            </div>
                        </div> :
                        <div className='formation-editor empty' />
                    }
                </div>
            </div>            
        </div>
    );
}

export default GradesTool;