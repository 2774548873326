import { useEffect, useState } from 'react';
import { rootPath } from 'util';
import { BoardMembers } from 'datatypes/types';

import cover_photo from '../assets/hsa_parents.jpg';

function AboutUs(){
    const [boardList, setBoardList] = useState<Array<BoardMembers>>([]);
    
    const getBoardList = () =>{
        try {
            fetch(`${rootPath}/v1/api/page/list/board-members`, {
                method: "GET", headers: { "Accept": "application/json", "Content-Type":"application/json"}
            })
            .then((response) => response.json())
            .then((res)=> {
                if(res.results) {
                    setBoardList(res.results);
                }
                else {
                    console.log(`Error Getting Board List [E00]: ${res.error}`);
                }
            }).catch((err) =>{
                console.log(`Error Getting Board List [E01]: ${err}`);
            });
        }
        catch(ex){
            console.log(`Error Getting Board List [E02] ${ex}`);
        }
    }

    useEffect(()=> { 
        document.title = "About Us";
        getBoardList(); 
    },[]);

    return (
        <div className="internal-page aboutus">
            <div className='internal-landing'>
                <div className="cover-image">
                    <img src={cover_photo} alt="About Us photo" />
                </div>
                <h1 className='small-title'>About Us</h1>
            </div>

            <div className='aboutus-container'>
                <h2>Misson</h2>
                <p>The St. Elizabeth Home and School Association (HSA) serves as a communication liaison between home and school life. We promote open communication, goodwill and cooperation among parents, faculty and administration. The HSA manages fundraising efforts and community building events that foster parent engagement and support our school’s vision in creating a community rich in Faith, Tradition, Scholarship, and Service.</p>
            </div>

            <div className='aboutus-container'>
                <h2>Our HSA Board</h2>
                <div className='board-container'>
                    {boardList.map((board, i) =>
                        <div className='board-item' key={i}>
                            <div className='img-container'>
                                {board.image && <img className='base-img' src={board.image} />}
                                {board.image && <img className='back-img' src={board.image} />}
                            </div>
                            <div className='board-info'>
                                <div className='title'>{board.title}</div>
                                <div className='name'>{board.name}</div>
                                <a href={`mailto:${board.email}`} className='email'>{board.email}</a>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className='aboutus-container back1'>
                <h2>HSA Executive Board</h2>
                <p>The HSA is a parent volunteer organization led by a (8) officer executive board that supports Co-Chairs in managing school activities, volunteering for events and fundraisers.  Additionally, in collaboration with our School Admissions Director, we support integrating new parents into the St. Elizabeth Community.</p>
                <p>The HSA board officers: (2) Co-Presidents, (2) Vice Presidents, (1) Secretary (1) Treasurer (1) Home Room Parents Coordinator and (1) Volunteer Coordinator</p>
            </div>

            <div className='aboutus-container back2'>
                <h2>HSA Co-Chairpersons </h2>
                <p>The HSA oversees approximately 30 school events. Each event is managed typically by two Co-Chairpersons.  Event Co-Chairs will serve a two-year term with the option to continue on annually after fulfilling their initial commitment.</p>
                <p>The HSA Board hosts an orientation meeting for Co-Chairs in September.  This meeting is to inform Co-Chairs of their roles and responsibilities as well as explain operating procedures and budget expectations.</p> 
                <p>Each Co-Chair is responsible for maintaining event documents containing the event history and logistics. Co-Chairs are asked to keep their information current on the HSA Google Drive.</p>
            </div>

            <div className='aboutus-container'>
                <h2>HSA Teams</h2>
                <p>There are two teams that comprise the HSA: the Blue Team and the White Team.  The teams are determined for ease of event management purposes.</p> 
                <p>Each team is led by a Co-President and Vice President who support their respective team of Co-Chairs in managing events.  The team Vice Presidents will reach out to event Co-Chairs prior to the start of each event to begin communication and manage logistics.</p>
            </div>
        </div>
    );
}

export default AboutUs;