import { Link, NavLink } from 'react-router-dom';
import { useContext } from 'react';

import userContext from "context/userContext";
import { UserContextType } from 'datatypes/types';
import logo_base from '../assets/hsa-logo2.png';

function Footer(){
    const { user } = useContext(userContext.UserContext) as UserContextType;

    return (
        <>
            <div className='footer'>
                <Link className='navbar-logo' to="/">
                    <div className='custom-logo-container'>
                        <img src={logo_base} alt="HSA Logo" />
                    </div>
                    <div className='logo-text'>
                        <p>Home & School</p>
                        <p>Association</p>
                    </div>
                </Link>

                <div className='nav-link-list'>
                    <NavLink className="nav-link" to="/">Home</NavLink>
                    <NavLink className="nav-link" to="/aboutus">About Us</NavLink>
                    <NavLink className="nav-link" to="/committees">Committees</NavLink>
                    <NavLink className="nav-link" to="/grades">Grades</NavLink>
                    <NavLink className="nav-link" to="/volunteers">Volunteers</NavLink>
                    <NavLink className="nav-link" to="/events">HSA Events</NavLink>
                </div>
                <div className='nav-link-list'>
                    {!user ?
                        <NavLink to="/access/login" className='login-link'>
                           <div className='login-container'>
                                <span className="material-symbols-outlined">person</span>
                                <span className="text">Login</span>
                            </div> 
                        </NavLink>:
                        <NavLink to="/access" className='user-link'>
                            <span className="material-symbols-outlined">account_circle</span>
                            <span>{user.name}</span>
                        </NavLink>
                    }
                </div>
            </div>
        </>
    );
}

export default Footer;